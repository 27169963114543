import React from 'react';
import { useTranslation } from 'react-i18next';
import Bordered from 'src/components/Branding/Border';
import activityImg from './Assets/campaignActivity.png';
import './CampaignActivity.scss';

const CampaignActivity = ({ data }: any) => {
  const { t } = useTranslation();

  return (
    <div className="activityContainer">
      <div className="flex space-between text-uppercase text-sm">
        <span className="text-secondary">{t('Campaign Activity')}</span>
      </div>
      <Bordered className="activityContent">
        <img src={activityImg} className="container-full" />
      </Bordered>
    </div>
  );
};

export default CampaignActivity;
