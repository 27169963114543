import React from 'react';
import { capitalize } from 'lodash';
import Tag from 'src/components/Branding/Tag';
import { PropsBase } from 'src/components/types';

import './Status.scss';

interface StatusProps extends PropsBase {
  id: string;
  title: string;
}

/**
 * Represents campaign status tag.
 */
const Status = ({ id, title }: StatusProps) => (
  <Tag className={`${id}-status`} key={title} >
    <span dangerouslySetInnerHTML={{__html: capitalize(title)}} />
  </Tag>
);

export default Status;
