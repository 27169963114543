/**
 * Helper methods for access token management in local storage.
 */

export const AUTH_TOKEN_KEY = 'authToken';

export const getStoredAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY);

export const storeAuthToken = (token: string) => localStorage.setItem(AUTH_TOKEN_KEY, token);

export const removeStoredAuthToken = () => localStorage.removeItem(AUTH_TOKEN_KEY);
