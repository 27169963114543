import useQuery, { QueryOptions } from './query';
import useMutation from './mutation';

/* eslint-disable react-hooks/rules-of-hooks */
export default {
  get: (...args: [string, never?, QueryOptions?]) => useQuery(...args),
  post: (...args: [string]) => useMutation('post', ...args),
  put: (...args: [string]) => useMutation('put', ...args),
  patch: (...args: [string]) => useMutation('patch', ...args),
  delete: (...args: [string]) => useMutation('delete', ...args),
};
