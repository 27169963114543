import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { storeI18nToken, getStoredI18nToken } from 'src/shared/utils/i18n';
import Menu, { MenuItem } from 'src/components/Branding/Menu';
import Box from 'src/components/Branding/Box';
import Select, { Option } from 'src/components/Branding/Select';
import authService from 'src/shared/services/auth.service';
import useOnOutsideClick from 'src/shared/hooks/onOutsideClick';
import { SUBSCRIBE_CHANGE_LANGUAGE, LanguageSubscription } from 'src/store/lang/types';
import { RootState } from 'src/store';
import { SELECT_CUSTOMER } from 'src/store/customers/types';
import { changeLanguage } from 'src/store/lang/actions';

import LanguageSVG from './Assets/language.svg';
import RoleSVG from './Assets/role.svg';
import NotificationSVG from './Assets/notifications.svg';
import BmwGroup from './Assets/bmw-group-logo.png';
import Notification from './Notification';
import User from './User';
import DemoPrototype from './DemoPrototype';

import './Header.scss';

const DefaultHeader = Layout.Header;

interface HeaderProps {
  selectCustomer?: Function;
  changeLanguage?: Function;
  subscribe?: Function;
}

const Header = ({ selectCustomer, changeLanguage, subscribe }: HeaderProps) => {
  const [langDisabled, setLangDisabled] = useState(false);
  const [roleDisabled, setRoleDisabled] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const $selectRef = useRef(null);
  const $roleRef = useRef(null);
  const [{ data }, fetchData] = authService.me();
  const [{ data: me }, makeRequest] = authService.updateMe();
  const { t } = useTranslation();
  const { role, name, second_name } = data || {};
  const [selectedRole, setSelectedRole] = useState<string>('');
  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  useEffect(() => {
    setSelectedRole(role);
  }, [role]);

  useOnOutsideClick($selectRef, selectOpen, () => {
    setSelectOpen(false);
  });

  useOnOutsideClick($roleRef, roleOpen, () => {
    setRoleOpen(false);
  });

  const activeLinkWorkaround = useCallback((match: any) => Boolean(match), []);
  const setLang = useCallback((e, { i18n }) => {
    setLangDisabled(true);
    storeI18nToken(i18n);
    changeLanguage!(i18n).then(() => {
      setLangDisabled(false);
    });
  }, []);

  const setRole = useCallback((e, payload) => {
    setRoleDisabled(true);
    makeRequest({
      role: payload.role,
      name,
      second_name,
    }).then((response: any) => {
      setSelectedRole(response.role);
      setRoleDisabled(false);

      // reload the page when role changed
      // redirect to /customers if role is admin_aftersales
      // redirect to /campaigns otherwise
      if (response.role === 'admin_aftersales') {
        window.location.replace('/customers');
      } else {
        window.location.replace('/campaigns');
      }
    });
  }, [name, second_name]);

  const langOptions = useMemo(
    () => [
      { display: 'English', value: 'ENG', i18n: 'en' },
      { display: '日本語', value: 'JPN', i18n: 'jp' },
    ],
    [],
  );

  const roleOptions = [
    { display: t('admin_aftersales'), role: 'admin_aftersales', key: 1 },
    { display: t('marketing_manager'), role: 'marketing_manager', key: 2 },
    { display: t('sales_manager'), role: 'sales_manager', key: 3 },
    { display: t('sales_consultant'), role: 'sales_consultant', key: 4 },
  ];

  const resetCustomer = useCallback(() => {
    selectCustomer!();
  }, []);

  const selectedRoleValue = t(selectedRole);
  return (
    <DefaultHeader className="header w-100">
      <div className="w-fit pr-0 title-container">
        <NavLink to="#" onClick={resetCustomer} className="header__company-name text text-xxs">
          <img src={BmwGroup} />
        </NavLink>
      </div>
      <div className="flex cell">
        <div className="header__container flex flex-row vertical-center">
          <Menu className="header__menu" theme="dark" mode="horizontal">
            <MenuItem itemKey="1" disabled>
              <NavLink to="/dashboard" exact activeClassName="active-link" isActive={activeLinkWorkaround}>
                {t('Dashboard')}
              </NavLink>
            </MenuItem>
            <MenuItem itemKey="2">
              <NavLink to="/customers" activeClassName="active-link" isActive={activeLinkWorkaround}>
                {t('Customers')}
              </NavLink>
            </MenuItem>
            <MenuItem itemKey="3" className="disabled-link">
              <NavLink to="/campaigns" activeClassName="active-link" isActive={activeLinkWorkaround}>
                {t('Campaigns')}
              </NavLink>
            </MenuItem>
          </Menu>
        </div>
        <div className="flex cell flex-row end header_right">
          <div className="flex flex-row vertical-center">
            <DemoPrototype />
          </div>
          <Box className="flex flex-row vertical-center lang-box">
            <div
              id="roleSelectContainer"
              ref={$roleRef}
              onClick={() => setRoleOpen(prev => !prev)}
              className="flex flex-row space-between"
            >
              <img src={RoleSVG} />
              <Select
                open={roleOpen}
                disabled={roleDisabled}
                value={selectedRoleValue}
                size="middle"
                optionLabelProp="display"
                suffixIcon={<CaretDownOutlined />}
                dropdownMatchSelectWidth={117}
                onSelect={setRole}
                getPopupContainer={() => document.getElementById('roleSelectContainer') || document.body}
              >
                {roleOptions &&
                  roleOptions.map((q: any) => (
                    <Option key={q.key} value={q.display} label={q.display} role={q.role}>
                      <span>{q.display}</span>
                    </Option>
                  ))}
              </Select>
            </div>
          </Box>
          <Box className="flex flex-row vertical-center lang-box">
            <div
              id="langSelectContainer"
              ref={$selectRef}
              onClick={() => setSelectOpen(!selectOpen)}
              className="flex flex-row space-between"
            >
              <img src={LanguageSVG} />
              <Select
                open={selectOpen}
                disabled={langDisabled}
                defaultValue={langOptions.find((q) => q.i18n === getStoredI18nToken())?.value}
                size="middle"
                optionLabelProp="display"
                suffixIcon={<CaretDownOutlined />}
                dropdownMatchSelectWidth={117}
                onSelect={setLang}
                getPopupContainer={() => document.getElementById('langSelectContainer') || document.body}
              >
                {langOptions &&
                  langOptions.map((q: any) => (
                    <Option key={q.value} value={q.value} label={q.display} i18n={q.i18n}>
                      <span>{q.display}</span>
                    </Option>
                  ))}
              </Select>
            </div>
          </Box>
          <Box className="flex flex-row vertical-center center pos-rel">
            <Notification hasAny={true} />
            <img src={NotificationSVG} />
          </Box>
          <Box className="flex flex-row vertical-center center">{data && <User {...data} />}</Box>
        </div>
      </div>
    </DefaultHeader>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  selectCustomer: () => dispatch({ type: SELECT_CUSTOMER, payload: null }),
  changeLanguage: (lang: string) => dispatch(changeLanguage(lang)),
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
