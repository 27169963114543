import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';

import { PropsBase } from 'src/components/types';
import GreyCircle from 'src/components/Branding/GreyCircle';
import Button from 'src/components/Branding/Button';
import Modal from 'src/components/Branding/Modal';

import Warning from './Assets/warning.svg';

import './Warning.scss';

type WarningModal = ModalProps & PropsBase;

const WarningModal = ({ title, visible, children, onOk, ...rest }: WarningModal) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} centered footer={null} className="warning-modal" {...rest}>
      <GreyCircle className="warning-icon">
        <img src={Warning} />
      </GreyCircle>

      <p className="text text-center text-xxs modal-title">{title && title}</p>
      <div className="modal-content">{children}</div>
      <Button className="btn btn-primary text text-sm text-uppercase" onClick={onOk}>
        {t('OK')}
      </Button>
    </Modal>
  );
};

export default WarningModal;
