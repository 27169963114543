import React from 'react';
import { useTranslation } from 'react-i18next';

import Bordered from 'src/components/Branding/Border';
import CustomersContainer from '../../CustomersContainer';

import './Details.scss';

interface DetailsProps {
  current: number;
  total: number;
}

const Details = ({ current, total }: DetailsProps) => {
  const { t } = useTranslation();

  return (
    <Bordered position="bottom">
      <CustomersContainer className="h-fit text-center customers__section__details-container">
        <span className="text text-xs text-secondary">{t('Showing of', { current, total })}</span>
      </CustomersContainer>
    </Bordered>
  );
};

export default Details;
