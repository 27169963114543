import { Menu } from 'antd';
import React from 'react';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { PropsBase } from 'src/components/types';

type MenuItemCustomProps = MenuItemProps &
  PropsBase & {
    itemKey: string;
  };

const MenuItem = ({ children, itemKey, ...rest }: MenuItemCustomProps) => (
  <Menu.Item key={itemKey} {...rest}>
    {children}
  </Menu.Item>
);

export default MenuItem;
