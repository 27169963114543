import React from 'react';

import './SampleStep.scss';

interface StepProps {
  number: number;
  text: string;
  onClick: () => void;
  active: boolean;
  numberStyle?: string;
}

const SampleStep = ({ number, text, onClick, active, numberStyle }: StepProps) => {
  return (
    <div className={active ? 'active stepContainer flex' : 'stepContainer flex'} onClick={onClick}>
      <div className={`${numberStyle} number text-white`}>{number}</div>
      <span className="text-lg">{text}</span>
    </div>
  );
};
export default SampleStep;
