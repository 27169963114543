import React, { Fragment } from 'react';

import Header from './Header';
import Details from './Details';
import Customers from './Customers';
import { CustomersProps } from './Customers';

export type SectionsProps = {
  current: number;
  total: number;
} & CustomersProps;

const Sections = ({ current, total, ...rest }: SectionsProps) => (
  <Fragment>
    <Header />
    <Details current={current} total={total} />
    <Customers {...rest} />
  </Fragment>
);

export default Sections;
