import React from 'react';
import { useTranslation } from 'react-i18next';
import Bordered from 'src/components/Branding/Border';

import './PersonalInfo.scss';

const PersonalInfo = ({ data, dealer }: any) => {
  const { t } = useTranslation();

  return (
    <div className="personalInfoContainer">
      <div className="flex space-between text-uppercase text-sm">
        <span className="text-secondary">{t('Personal Information')}</span>
      </div>
      <Bordered className="personalInfoContent">
        <div className="left">
          <div>
            <span className="key text-secondary">{t('Dealer')}:</span>
            <span className="text-main">{dealer.dealer_title}</span>
          </div>
          <div>
            <span className="key text-secondary">{t('Address')}:</span>
            <span className="text-main">{data.cutomer_address}</span>
          </div>
          <div>
            <span className="key text-secondary">{t('Phones')}:</span>
            <span className="text-main">
              {data.cutomer_phone &&
                data.cutomer_phone.map((item: any) => {
                  return (
                    <>
                      {item.cutomer_phone_number} ({item.cutomer_phone_type})
                      <br />
                    </>
                  );
                })}
            </span>
          </div>
          <div>
            <span className="key text-secondary">{t('Email')}:</span>
            <span className="text-main">
              {data.cutomer_email &&
                data.cutomer_email.map((item: any) => {
                  return (
                    <>
                      {item.cutomer_email_address}
                      <br />
                    </>
                  );
                })}
            </span>
          </div>
        </div>
        <div className="right">
          <div>
            <span className="key text-secondary">{t('Gender')}:</span>
            <span className="text-main">{data.cutomer_gender}</span>
          </div>
          <div>
            <span className="key text-secondary">{t('Brand Associations')}:</span>
            <span className="text-main">
              {data.brand_associations &&
                data.brand_associations.map((item: any) => {
                  return (
                    <>
                      {item.brand}
                      <br />
                    </>
                  );
                })}
            </span>
          </div>
          <div>
            <span className="key text-secondary">{t('Assigned Consultant')}:</span>
            <span className="text-main">
              {data.assigned_consultant &&
                data.assigned_consultant.map((item: any) => {
                  return (
                    <>
                      {item.assigned_consultant_name} ({item.assigned_consultant_brand})
                      <br />
                    </>
                  );
                })}
            </span>
          </div>
        </div>
      </Bordered>
    </div>
  );
};

export default PersonalInfo;
