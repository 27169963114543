export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const OPEN_PARTS_MODAL = 'OPEN_PARTS_MODAL';
export const CLOSE_PARTS_MODAL = 'CLOSE_PARTS_MODAL';
export const SET_STARS = 'CUSTOMERS_SET_STARS';
export const TOGGLE_STAR = 'TOGGLE_STAR';
export const SELECTED_CUSTOMER = 'bm_selectedCustomer';
export const SELECT_CUSTOMERS_INDEX = 'SELECT_CUSTOMERS_INDEX';

export interface CustomersState {
  selectedCustomer: string | null;
  selectedCustomersIndex: number | null;
  isSelected: boolean;
  partsModalOpen: boolean;
  selectedBox?: string;
  selectedPart?: string;
  stars?: CustomerStar[];
}

export interface CustomerStar {
  number: string;
  star: boolean;
}

interface SelectCustomerAction {
  type: typeof SELECT_CUSTOMER;
  payload: string | null;
}

interface SelectCustomersIndexAction {
  type: typeof SELECT_CUSTOMERS_INDEX;
  payload: number | null;
}

interface OpenPartsModalAction {
  type: typeof OPEN_PARTS_MODAL;
  payload: {
    selectedBox?: string;
    selectedPart?: string;
  };
}

interface ClosePartsModalAction {
  type: typeof CLOSE_PARTS_MODAL;
}

interface SetStarsAction {
  type: typeof SET_STARS;
  payload: {
    stars: CustomerStar[];
  };
}

interface ToggleStarAction {
  type: typeof TOGGLE_STAR;
  payload: {
    number: string;
  };
}

export type CustomersActionTypes =
  | SelectCustomerAction
  | SelectCustomersIndexAction
  | OpenPartsModalAction
  | ClosePartsModalAction
  | SetStarsAction
  | ToggleStarAction;
