import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { PropsBase } from 'src/components/types';

interface NoteProps extends PropsBase {
  title: string;
}

const Note = ({ title }: NoteProps) => (
  <Tooltip title={title} placement="right">
    <QuestionCircleOutlined style={{ color: '#0000007F' }}></QuestionCircleOutlined>
  </Tooltip>
);

export default Note;
