import React, { useRef, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';

import Padding from 'src/components/Branding/Spaceing/Padding';
import Popup from 'src/components/Branding/Popup';
import { ContainerSM } from 'src/components/Branding/Container';
import Select, { Option } from 'src/components/Branding/Select';
import Button from 'src/components/Branding/Button';
import Filter from './Assets/filter.svg';
import Input from 'src/components/Branding/Input';

import './Filters.scss';

interface FIltersProps {
  selectedLanguage?: string;
}

const Filters = ({ selectedLanguage }: FIltersProps) => {
  const $filterIconRef = useRef(null);
  const closePopup: any = {};

  const { t } = useTranslation();

  return (
    <div className="filtersContainer">
      <Input disabled placeholder={t('Search')} prefix={<SearchOutlined />}></Input>
      {useMemo(
        () => (
          <Padding className="w-fit pos-rel" size="sm">
            <img src={Filter} ref={$filterIconRef} />
            <Popup owner={$filterIconRef} closePopup={closePopup}>
              <ContainerSM className="filter-popup" id="filter-popup-container">
                <Select
                  disabled
                  placeholder={t('Brand')}
                  bordered
                  suffixIcon={<CaretDownOutlined />}
                  getPopupContainer={() => document.getElementById('filter-popup-container') || document.body}
                >
                  <Option value="2">{t('Filters.Brands.0')}</Option>
                  <Option value="3">{t('Filters.Brands.1')}</Option>
                </Select>
                <Select
                  placeholder={t('Dealer')}
                  disabled
                  bordered
                  suffixIcon={<CaretDownOutlined />}
                  getPopupContainer={() => document.getElementById('filter-popup-container') || document.body}
                >
                  {[...Array(5)].map((q, index) => (
                    <Option key={index} value={index}>
                      {t(`Filters.Dealers.${index}`)}
                    </Option>
                  ))}
                </Select>
                {selectedLanguage === 'jp' && (
                  <Fragment>
                    <Select
                      placeholder={t('Outlet')}
                      disabled
                      bordered
                      suffixIcon={<CaretDownOutlined />}
                      getPopupContainer={() => document.getElementById('filter-popup-container') || document.body}
                    >
                      {[...Array(7)].map((q, index) => (
                        <Option key={index} value={index}>
                          {t(`Filters.Outlets.${index}`)}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      placeholder={t('Sales Person')}
                      disabled
                      bordered
                      suffixIcon={<CaretDownOutlined />}
                      getPopupContainer={() => document.getElementById('filter-popup-container') || document.body}
                    >
                      {[...Array(9)].map((q, index) => (
                        <Option key={index} value={index}>
                          {t(`Filters.Sales.${index}`)}
                        </Option>
                      ))}
                    </Select>
                  </Fragment>
                )}

                <Select
                  placeholder={t('Customer Segment')}
                  disabled
                  bordered
                  suffixIcon={<CaretDownOutlined />}
                  getPopupContainer={() => document.getElementById('filter-popup-container') || document.body}
                >
                  {[...Array(4)].map((q, index) => (
                    <Option key={index} value={index}>
                      {t(`Filters.Segments.${index}`)}
                    </Option>
                  ))}
                </Select>
                <div className="mt-xs">
                  <Button className="btn btn-blue mr-xs" onClick={() => closePopup.current!(false)}>
                    <span className="text text-md text-white">{t('Apply')}</span>
                  </Button>
                  <Button className="btn" onClick={() => closePopup.current!(false)} disabled>
                    <span className="text text-md text-blue">{t('Reset')}</span>
                  </Button>
                </div>
              </ContainerSM>
            </Popup>
          </Padding>
        ),
        [],
      )}
    </div>
  );
};

export default Filters;
