import { Menu as DefaultMenu } from 'antd';
import React from 'react';
import { PropsBase } from 'src/components/types';
export { default as MenuItem } from './MenuItem';

type MenuProps = PropsBase;

const Menu = ({ children, ...rest }: MenuProps) => <DefaultMenu {...rest}>{children}</DefaultMenu>;

export default Menu;
