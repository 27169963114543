import { useCallback } from 'react';
import { AxiosError } from 'axios';
import api from 'src/shared/utils/api';
import useMergeState from 'src/shared/hooks/mergeState';
import { HTTPMethod, WorkingAlias } from 'src/shared/types';

/**
 * Returns data, isLoading and error fields of api request
 * along with makeRequest which is api request function.
 * Data, isLoading and error fields are updated within makeRequest using
 * useMergeState which allows to update user component after
 * each makeRequest request. This make data, loading tracking
 * very straighforward for user components.
 */
const useMutation = (method: HTTPMethod, url: string) => {
  const [state, mergeState] = useMergeState({
    data: null,
    error: null,
    isWorking: false,
  });

  const makeRequest = useCallback(
    (variables = {}) =>
      new Promise((resolve, reject) => {
        mergeState({ error: null, isWorking: true });

        api[method](url, variables).then(
          (data: any) => {
            mergeState({ data, error: null, isWorking: false });
            resolve(data);
          },
          (error: AxiosError) => {
            mergeState({ error, data: null, isWorking: false });
            reject(error);
          },
        );
      }),
    [method, url, mergeState],
  );

  return [
    {
      ...state,
      [isWorkingAlias[method]]: state.isWorking,
    },
    makeRequest,
  ];
};

type WorkingAliasDef = Record<HTTPMethod, WorkingAlias>;

/**
 * Returned in useMutation. Represents current state of request.
 */
const isWorkingAlias: WorkingAliasDef = {
  get: 'isFetching',
  post: 'isCreating',
  put: 'isUpdating',
  patch: 'isUpdating',
  delete: 'isDeleting',
};

export default useMutation;
