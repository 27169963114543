import React from 'react';
import ProgressCardTitle, { ProgressCardTitleProps } from './Title';
import ProgressCardSpent, { ProgressCardSpentProps } from './Spent';
import ProbabilityStats, { ProbabilityStatsProps } from './Probabilities';
import { PropsBase } from 'src/components/types';
import Bordered from 'src/components/Branding/Border';
import { ContainerFULL } from 'src/components/Branding/Container';
import Card from 'src/components/Branding/Card';

export type ProgressCardProps = PropsBase & ProgressCardTitleProps & ProgressCardSpentProps & ProbabilityStatsProps;

const ProgressCard = ({ title, series, stats, matchmakingStats }: ProgressCardProps) => {
  return (
    <ContainerFULL>
      <ProgressCardTitle title={title} series={series} />

      <Card>
        <Bordered position="bottom">
          <ProgressCardSpent stats={stats} />
        </Bordered>
        <ProbabilityStats title={title} matchmakingStats={matchmakingStats} />
      </Card>
    </ContainerFULL>
  );
};

export default ProgressCard;
