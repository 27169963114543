import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import MarketingManagerCampaigns from 'src/pages/Campaigns/MarketingManager';
import SalesManagerCampaigns from 'src/pages/Campaigns/SalesManager';
import SalesConsultantCampaigns from 'src/pages/Campaigns/SalesConsultant';
import Customers from 'src/pages/Customers';
import MarketingManagerCampaign from 'src/pages/Campaign/MarketingManager';
import MarketingManagerDetails from 'src/pages/CampaignDetails/MarketingManager';
import SalesConsultantManagerDetails from 'src/pages/CampaignDetails/SalesConsultantManager';
import SalesManagerCampaign from 'src/pages/Campaign/SalesManager';
import { RootState } from 'src/store';
import { UserRoles } from 'src/shared/types';

interface GetRouterProps {
  role: UserRoles;
}

const GetRouter = ({ role }: GetRouterProps) => {
  switch (role) {
    case 'marketing_manager':
    case 'admin_aftersales':
      return (
        <Switch>
          <Route exact path="/customers" component={Customers} />
          <Route exact path="/campaigns" component={MarketingManagerCampaigns} />
          <Route exact path="/campaigns/details" component={MarketingManagerDetails} />
          <Route exact path="/campaigns/create" component={MarketingManagerCampaign} />
          <Route exact path="/campaigns/edit" component={MarketingManagerCampaign} />
          <Redirect from="/" to="/campaigns" />
        </Switch>
      );
    case 'sales_manager':
      return (
        <Switch>
          <Route exact path="/customers" component={Customers} />
          <Route exact path="/campaigns" component={SalesManagerCampaigns} />
          <Route exact path="/campaigns/details" component={SalesConsultantManagerDetails} />
          <Route exact path="/campaigns/update" component={SalesManagerCampaign} />
          <Redirect from="/" to="/campaigns" />
        </Switch>
      );
    case 'sales_consultant':
      return (
        <Switch>
          <Route exact path="/customers" component={Customers} />
          <Route exact path="/campaigns" component={SalesConsultantCampaigns} />
          <Route exact path="/campaigns/details" component={SalesConsultantManagerDetails} />
          <Redirect from="/" to="/campaigns" />
        </Switch>
      );
    default:
      return (
        <Switch>
          <Route exact path="/customers" component={Customers} />
          <Route exact path="/campaigns" component={MarketingManagerCampaigns} />
        </Switch>
      );
  }
};

interface ContentProps {
  role?: UserRoles;
}

const Content = ({ role }: ContentProps) => (
  <Switch>
    <GetRouter role={role!} />
  </Switch>
);

const mapStateToProps = (state: RootState) => ({
  role: state.auth.role,
});

export default connect(mapStateToProps, null)(Content);
