import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomersContainer from 'src/pages/Customers/CustomersContracts/CustomersContainer';
import RadioGroup, { RadioButton } from 'src/components/Branding/Radio';
import Bordered from 'src/components/Branding/Border';

const Header = () => {
  const { t } = useTranslation();

  return (
    <Bordered position="bottom">
      <CustomersContainer className="customers__section__header">
        <RadioGroup value="contracts">
          <RadioButton value="customers" disabled={true}>
            {t('Customers')}
          </RadioButton>
          <RadioButton value="contracts">{t('Contracts')}</RadioButton>
        </RadioGroup>
      </CustomersContainer>
    </Bordered>
  );
};

export default Header;
