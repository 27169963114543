import React from 'react';
import { useTranslation } from 'react-i18next';

import Bordered from 'src/components/Branding/Border';
import CampaignsContainer from 'src/shared/components/Campaigns/CampaignsContainer';
import Filters from 'src/shared/components/Campaigns/Filters';

const Header = () => {
  const { t } = useTranslation();

  return (
    <Bordered position="bottom">
      <CampaignsContainer className="campaigns__section__header">
        <p className="text-uppercase text-main text-bold text-xxs m-0">{t('Campaigns')}</p>
        <div className="flex">
          <Filters />
        </div>
      </CampaignsContainer>
    </Bordered>
  );
};
export default Header;
