import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsoleSqlOutlined, StarFilled, StarOutlined } from '@ant-design/icons';

import { ContainerSM } from 'src/components/Branding/Container';
import Padding from 'src/components/Branding/Spaceing/Padding';
import { fromNowString, dateFormatted } from 'src/shared/utils/date';
import { currencyShort, currencyShortMillion } from 'src/shared/utils/formatters';
import { CustomerStar } from 'src/store/customers/types';

import InfoRow from 'src/pages/Customers/CustomersContracts/Sections/Customers/Customer/InfoRow';
import Tag from 'src/components/Branding/Tag';

export interface ContractProps {
  selected: boolean;
  name: string;
  number: string;
  star: boolean;
  lastCall: Date;
  lastVisit: Date;
  totalContracts: number;
  contract: string;
  financeRemainingBalance: number;
  tradeIn: number;
  financeEndOfTerm: Date;
  onSelect: Function;
  stars?: CustomerStar[];
  toggleStar?: Function;
  selectItem?: any;
}

const Contract = ({
  selected,
  name,
  number,
  lastCall,
  lastVisit,
  contract,
  totalContracts,
  financeRemainingBalance,
  tradeIn,
  financeEndOfTerm,
  onSelect,
  stars,
  toggleStar,
  selectItem,
}: ContractProps) => {
  const { t } = useTranslation();

  const checked = useMemo(() => stars?.find((q: any) => q.number === number)?.star, [stars]);

  const onCardClick = () => {
    onSelect(number);
    selectItem();
  };

  return (
    <div className="customers__customer" onClick={onCardClick}>
      {selected && <div className="customers__customer--active-indicator"></div>}
      <ContainerSM>
        <div className="customers__customer-star">
          {checked ? (
            <StarFilled className="star" onClick={() => toggleStar!(number)} />
          ) : (
            <StarOutlined className="star" onClick={() => toggleStar!(number)} />
          )}
        </div>
        <div className="customers__customer__info">
          <Padding position="bottom" size="sm" className="customers__customer__info-main flex space-between">
            <div>
              <p className="text text-lg text-uppercase mb-xs text-bold">{name}</p>
              <span className="text text-md">{number}</span>
            </div>
            <Tag>{t('To Do')}</Tag>
          </Padding>

          <div className="customers__customer__info__rows">
            <InfoRow name={t('Last Call')} value={fromNowString(lastCall)} />
            <InfoRow name={t('Last Visit')} value={fromNowString(lastVisit)} />
            <InfoRow name={t('Total Contracts')} value={totalContracts} />
            <InfoRow name={t('Contract')} value={contract} />
            <InfoRow name={t('Finance Remaining Balance')} value={currencyShort(financeRemainingBalance)} />
            <InfoRow name={t('Trade-In')} value={currencyShort(tradeIn)} />
            <InfoRow name={t('Finance End Of Term')} value={financeEndOfTerm} />
          </div>
        </div>
      </ContainerSM>
    </div>
  );
};

export default Contract;
