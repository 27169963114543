import React from 'react';
import AuthRoute, { AuthRouteProps } from 'src/components/AuthRoute';

/**
 * Checks if user is anonymous.
 * If user is authenticated redirects to fallback url.
 * Allows page to be visited only for not authenticated users.
 * Example` Login page.
 */
const AnonymousRoute = ({ auth, children, ...rest }: AuthRouteProps) => (
  <AuthRoute auth={!auth} {...rest}>
    {children}
  </AuthRoute>
);

export default AnonymousRoute;
