import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import Loading from 'src/components/Branding/Loading';
import { getDate, addDaysFromNow } from 'src/shared/utils/date';
import userService from 'src/shared/services/user.service';
import { RootState } from 'src/store';
import { SELECT_CUSTOMER } from 'src/store/customers/types';
import { SUBSCRIBE_CHANGE_LANGUAGE, LanguageSubscription } from 'src/store/lang/types';
import withLoading from 'src/shared/hocs/withLoading';

import CustomersContracts from './CustomersContracts';
import Sections from './Sections';
import NoCustomerSelected from './NoCustomerSelected';
import { PropsBase } from 'src/components/types';

import './Customers.scss';

interface CustomersProps {
  selectedCustomer: string | null;
  selectedLang: string | null;
  isSelected?: boolean;
  subscribe?: Function;
  selectCustomer?: Function;
}

const Customers = ({ selectedCustomer, selectedLang, isSelected, subscribe, selectCustomer }: CustomersProps) => {
  const [{ data, isLoading }, fetchData] = userService.data();

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  const outlet = data?.dealer[0].outlet[0];

  const outletData = useMemo(() => outlet?.customer.find((q: any) => q.dm_customer_number === selectedCustomer), [
    selectedCustomer,
    outlet?.outlet_title,
  ]);

  useEffect(() => {
    if (data && outletData === undefined) {
      selectCustomer!();
    }
  }, [selectedCustomer]);

  const time = useMemo(() => {
    const months24ago = new Date();
    months24ago.setFullYear(months24ago.getFullYear() - 2);
    return months24ago.getTime();
  }, []);

  const calculateTotalRevenue = useCallback((q: any) => {
    let total = 0;
    for (const car of q.customer_car)
      for (const parts_family of car.parts_family)
        for (const matchmaking of parts_family.parts_matchmaking)
          for (const purchase of matchmaking.parts_family_subgroup_recent_purchases)
            if ((getDate(purchase.date)?.getTime() || 0) - time > 0) total += purchase.amount;

    return total;
  }, []);

  const customersData = useMemo(
    () => ({
      customers: outlet?.customer.map((q: any) => ({
        name: q.cutomer_name,
        number: q.dm_customer_number,
        star: Boolean(q.customer_star_yn),
        lastCall: addDaysFromNow(-q.days_since_last_call),
        lastVisit: addDaysFromNow(-q.days_since_last_visit),
        totalVehicles: q.customers_total_vehicles_cnt,
        totalRevenue: calculateTotalRevenue(q),
        visits: q.visits_cnt,
        nextShaken: getDate(q.next_shaken_date),
      })),
      current: outlet?.customer.length,
      total: outlet?.customer.length + 10,
    }),
    [outlet?.outlet_title],
  );

  if (!data) {
    return <Loading />;
  }

  const Component = withLoading(({ className }: PropsBase) => (
    <div className={(className ? `${className} ` : '').concat('customers')}>
      <CustomersContracts
        customers={customersData?.customers}
        current={customersData?.current}
        total={customersData?.total}
      />
      {isSelected && outletData && (
        <Sections selectedCustomer={selectedCustomer!} selectedLanguage={selectedLang!} data={outletData} />
      )}
      {!isSelected && <NoCustomerSelected />}
    </div>
  ));

  return <Component isLoading={isLoading} />;
};

const mapStateToProps = (state: RootState) => ({
  selectedCustomer: state.customers.selectedCustomer,
  isSelected: state.customers.isSelected,
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  selectCustomer: () => dispatch({ type: SELECT_CUSTOMER }),
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
