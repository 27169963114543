export const formatNumber = (number: number, separator = ',') => {
  const numb = Math.floor(number);
  if (numb === 0) {
    return '0,000';
  }

  return numb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const changeSeparator = (number: number, separator = ',') => {
  return number.toString().replace('.', ',');
};

export const currencyShort = (number: number, currency = 'JPY') => {
  if (!number) return '';
  if (number / 1000000 > 0) return currencyShortMillion(number, currency);
  return `${getCurrency(currency)}${Math.floor(number / 1000)}k+`;
};

export const currencyShortMillion = (number: number, currency = 'JPY') =>
  number && `${getCurrency(currency)}${changeSeparator(round(number / 1000000, 1))}M+`;

export const getCurrency = (currency = 'JPY') => {
  switch (currency) {
    case 'USD':
      return '$'; // US Dollar
    case 'EUR':
      return '€'; // Euro
    case 'CRC':
      return '₡'; // Costa Rican Colón
    case 'GBP':
      return '£'; // British Pound Sterling
    case 'ILS':
      return '₪'; // Israeli New Sheqel
    case 'INR':
      return '₹'; // Indian Rupee
    case 'JPY':
      return '¥'; // Japanese Yen
    case 'KRW':
      return '₩'; // South Korean Won
    case 'NGN':
      return '₦'; // Nigerian Naira
    case 'PHP':
      return '₱'; // Philippine Peso
    case 'PLN':
      return 'zł'; // Polish Zloty
    case 'PYG':
      return '₲'; // Paraguayan Guarani
    case 'THB':
      return '฿'; // Thai Baht
    case 'UAH':
      return '₴'; // Ukrainian Hryvnia
    case 'VND':
      return '₫'; // Vietnamese Dong
  }
};

export const round = (value: number, precision: any = null) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};
