import React, { useEffect, useState } from 'react';

import { PropsBase } from 'src/components/types';
import { ContainerMD } from 'src/components/Branding/Container';
import Bordered from 'src/components/Branding/Border';

import Header from './Header';
import Financing from './Financing';
import Overview from './Overview';
import Results from './Results';
import PersonalInfo from './PersonalInfo';
import Vehicles from './Vehicles';
import Aftersales from './Aftersales';
import Inventory from './Inventory';
import ActivityHistory from './ActivityHistory';

import './Sections.scss';

interface SectionsProps extends PropsBase {
  selectedLanguage?: any;
  isSelected?: any;
  dealer?: any;
  data: any;
}

const Sections = ({ dealer, data, selectedLanguage }: SectionsProps) => {
  const [activePage, setActivePage] = useState<string>();
  const [financingPageData, setFinancingPageData] = useState<Array<any>>([]);

  useEffect(() => {
    data &&
      data.customer_car &&
      data.customer_car.map((q: any) => q.payment_type === 'Contract' && financingPageData.push(q));
    setFinancingPageData([...financingPageData]);
  }, [data.customer_car, selectedLanguage]);

  useEffect(() => {
    if (!activePage && !localStorage.getItem('activePage')) {
      setActivePage('overview');
      localStorage.setItem('activePage', 'overview');
    } else {
      setActivePage(`${localStorage.getItem('activePage')}`);
    }
  }, []);

  const radioButtonClick = (value: any) => {
    setActivePage(value);
    localStorage.setItem('activePage', value);
  };

  return (
    <div className="sections-layout">
      <Bordered position="bottom" className="zIndexHigh">
        <ContainerMD className="sections-header">
          <Header
            radioButtonClick={radioButtonClick}
            activePage={activePage}
            name={data.customer_name}
            number={data.dm_customer_number}
          />
        </ContainerMD>
      </Bordered>
      <ContainerMD className="sections-layout__sections">
        {activePage === 'overview' ? (
          <>
            <Overview data={data} />
            <Results data={data} />
            <PersonalInfo dealer={dealer} data={data} />
            <Vehicles data={data} />
            <div className="aftersalesInventory">
              <Aftersales data={data} />
              <Inventory data={data} />
            </div>
            <ActivityHistory data={data} />
          </>
        ) : (
          <Financing financingPageData={financingPageData} selectedLanguage={selectedLanguage} />
        )}
      </ContainerMD>
    </div>
  );
};

export default Sections;
