import React, { useMemo } from 'react';
import { SpacingProps } from './types';

const Padding = ({ position, size, children, className = '' }: SpacingProps) => {
  const classNameActual = useMemo(() => {
    let classNameActual = 'p';

    if (position) {
      classNameActual = classNameActual.concat(position[0]);
    }

    classNameActual = classNameActual.concat('-');
    classNameActual = classNameActual.concat(size ?? 'sm');
    classNameActual = classNameActual.concat(` w-inherit h-inherit ${className}`);

    return classNameActual;
  }, [position, size, className]);

  return <div className={classNameActual}>{children}</div>;
};

export default Padding;
