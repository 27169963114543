import React, { useMemo, useCallback } from 'react';

import { PropsBase } from 'src/components/types';
import { SpentStat } from 'src/pages/Customers/Sections/Aftersales/VehicleDetails/ProgressCard/Spent';
import { ContainerMD } from 'src/components/Branding/Container';
import Bordered from 'src/components/Branding/Border';
import { getDate } from 'src/shared/utils/date';

import Header from './Header';
import Aftersales from './Aftersales';

import './Sections.scss';

interface SectionsProps extends PropsBase {
  data: any;
  selectedCustomer: string;
  selectedLanguage?: string;
}

const Sections = ({ data, selectedCustomer, selectedLanguage }: SectionsProps) => {
  const getStats = useCallback(({ parts_family_spent, parts_family_spent_avg, parts_family_spent_max }: any) => {
    const res: SpentStat[] = [];
    for (let index = 0; index < parts_family_spent.length; index++) {
      res.push({
        year: parts_family_spent[index].year,
        amount: parts_family_spent[index].amount,
        average: parts_family_spent_avg[index].amount,
        max: parts_family_spent_max[index].amount,
      });
    }

    return res;
  }, []);

  const models = useMemo(() => data.customer_car.map((q: any) => q.car_model_name), [
    selectedLanguage,
    selectedCustomer,
  ]);

  const time = useMemo(() => {
    const months24ago = new Date();
    months24ago.setFullYear(months24ago.getFullYear() - 2);
    return months24ago.getTime();
  }, []);

  const spendIn24Months = useCallback(
    (purchases: any[]) =>
      purchases
        .filter((q) => (getDate(q.date)?.getTime() || 0) - time > 0)
        .reduce((total, curr) => total + curr.amount, 0),
    [],
  );

  const getLastPurchase = useCallback((purchases: any[]) => {
    if (!purchases || !purchases.length) {
      return null;
    }

    let lastPurchase = purchases[0];
    for (const purchase of purchases) {
      if (getDate(purchase.date)!.getTime() - getDate(lastPurchase.date)!.getTime() > 0) {
        lastPurchase = purchase;
      }
    }

    return getDate(lastPurchase!.date);
  }, []);

  const mapped = useMemo(
    () => ({
      stats: data.customer_car.map((q: any) => ({
        model: q.car_model_name,
        teleservices: q.teleservices,
        progressStats: q.parts_family.map((w: any) => ({
          title: w.parts_family_title,
          series: q.car_model_name,
          stats: getStats(w),
          matchmakingStats: w.parts_matchmaking.map((matchmaking: any) => ({
            name: matchmaking.parts_family_subgroup_title,
            probability: matchmaking.parts_family_subgroup_probability_of_buying,
            lastPurchase: getLastPurchase(matchmaking.parts_family_subgroup_recent_purchases),
            purchaseCount: matchmaking.parts_family_subgroup_recent_purchases.length,
            spendIn24Months: spendIn24Months(matchmaking.parts_family_subgroup_recent_purchases),
            avgSpendIn24Months: matchmaking.parts_family_subgroup_spent_avg,
            maxSpendIn24Months: matchmaking.parts_family_subgroup_spent_max,
            sales: matchmaking.parts_family_subgroup_recent_purchases.map((pp: any) => ({
              saleDate: getDate(pp.date),
              amount: pp.amount,
            })),
            averageDaysTillNextPurchase: matchmaking.parts_family_subgroup_avg_days_till_next_purchase,
            purchaseBars: matchmaking.parts_family_subgroup_bar_exp_days_till_next_purchase,
            avgDistancePerDay: q.average_km_traveled_per_day,
            averageDistanceTillNextPurchase: matchmaking.parts_family_subgroup_avg_km_till_next_purchase,
            distanceBars: matchmaking.parts_family_subgroup_bar_exp_km_till_next_purchase,
          })),
        })),
      })),
    }),
    [selectedLanguage, selectedCustomer, data],
  );

  return (
    <div className="sections-layout">
      <Bordered position="bottom" className="zIndexHigh">
        <ContainerMD className="sections-header">
          <Header name={data.cutomer_name} number={data.dm_customer_number} />
        </ContainerMD>
      </Bordered>
      <ContainerMD className="sections-layout__sections">
        <Aftersales models={models} stats={mapped.stats} />
      </ContainerMD>
    </div>
  );
};

export default Sections;
