import React from 'react';
import { useTranslation } from 'react-i18next';

import RadioGroup, { RadioButton } from 'src/components/Branding/Radio';
import Bordered from 'src/components/Branding/Border';
import CustomersContainer from '../../CustomersContainer';

import './Header.scss';

const Header = () => {
  const { t } = useTranslation();

  return (
    <Bordered position="bottom">
      <CustomersContainer className="customers__section__header">
        <RadioGroup value="customers">
          <RadioButton value="customers">{t('Customers')}</RadioButton>
          <RadioButton value="contracts" disabled={true}>
            {t('Contracts')}
          </RadioButton>
        </RadioGroup>
      </CustomersContainer>
    </Bordered>
  );
};
export default Header;
