import store from '..';
import { CHANGE_LANGUAGE, SUBSCRIBE_CHANGE_LANGUAGE, LanguageSubscription } from './types';

export const subscribe = (subscription: LanguageSubscription) => ({
  type: SUBSCRIBE_CHANGE_LANGUAGE,
  payload: subscription,
});

/**
 * Changes language of app.
 */
export const changeLanguage = (lang: string) => {
  return (dispatch: Function) => {
    const subscribers = store.getState().lang.subscribers;
    return Promise.all(subscribers.map((q: LanguageSubscription) => q.onStart && q.onStart())).then(() => {
      subscribers.forEach((q: LanguageSubscription) => {
        q.onEnd && q.onEnd();
      });
      dispatch({
        type: CHANGE_LANGUAGE,
        payload: lang,
      });
    });
  };
};
