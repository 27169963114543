import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrency, formatNumber } from 'src/shared/utils/formatters';
import Progress from '..';

import './Detailed.scss';

interface ProgressDetailedProps {
  amount: number;
  max: number;
  average: number;
}

const ProgressDetailed = ({ amount, max, average }: ProgressDetailedProps) => {
  const { t } = useTranslation();

  return (
    <div className="progress-detailed">
      <div className="progress-detailed__title w-100">
        <span className="text text-bold text-sm">
          {getCurrency()}
          {formatNumber(amount)}
        </span>
        <span className="text text-secondary text-xs">
          {t('Avg')}. {getCurrency()}
          {formatNumber(average)}
        </span>
      </div>
      <Progress percent={(amount / max) * 100} averagePercent={(average / max) * 100} />
    </div>
  );
};

export default ProgressDetailed;
