export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SUBSCRIBE_CHANGE_LANGUAGE = 'SUBSCRIBE_CHANGE_LANGUAGE';

export interface LanguageSubscription {
  onStart?: Function;
  onEnd?: Function;
}

export interface LangState {
  selectedLanguage: string | null;
  isSelected: boolean;
  subscribers: LanguageSubscription[];
}

interface ChangeLanguageAction {
  type: typeof CHANGE_LANGUAGE;
  payload: string;
}

interface SubscribeChangeLanguageAction {
  type: typeof SUBSCRIBE_CHANGE_LANGUAGE;
  payload: LanguageSubscription;
}

export type LangActionTypes = ChangeLanguageAction | SubscribeChangeLanguageAction;
