import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerMD, ContainerXS } from 'src/components/Branding/Container';
import Bordered from 'src/components/Branding/Border';
import { formatNumber, getCurrency } from 'src/shared/utils/formatters';
import SinceLastPurchaseCard from './SinceLastPurchaseCard';
import { MatchmakingStat } from '../../../VehicleDetails/ProgressCard/Probabilities/ProbabilityStats';

import Chart1 from './Assets/chart-1.png';
import Chart2 from './Assets/chart-2.png';
import Clock from './Assets/clock.svg';
import ClockDisabled from './Assets/clock-disabled.svg';
import Car from './Assets/car.png';
import CarDisabled from './Assets/car-disabled.png';
import { dateFormatted, getMonthNamesShort, formatYear, dateDiffs } from 'src/shared/utils/date';

import './PartsModalDetails.scss';

interface PartsModalDetailsProps {
  stat: MatchmakingStat;
}

const PartsModalDetails = ({ stat }: PartsModalDetailsProps) => {
  const { t } = useTranslation();
  const hasPurchases = stat.sales.length > 0;

  return (
    <Bordered position="top">
      <ContainerMD className="parts-modal-details">
        {hasPurchases && (
          <Fragment>
            <div className="row">
              <SinceLastPurchaseCard
                info={t('Days', { number: dateDiffs(new Date(), stat.lastPurchase!) })}
                avgInfo={t('Days', { number: stat.averageDaysTillNextPurchase })}
                title={t('Days since the last purchuse')}
                activeIcon={<img className="parts-modal-details__icon" src={Clock} />}
                disabledIcon={<img className="parts-modal-details__icon" src={ClockDisabled} />}
                countActive={stat.purchaseBars}
              />
              <SinceLastPurchaseCard
                info={t('km', {
                  number: formatNumber(dateDiffs(new Date(), stat.lastPurchase!) * stat.avgDistancePerDay),
                })}
                avgInfo={t('km', { number: formatNumber(stat.averageDistanceTillNextPurchase) })}
                title={t('Km since the last purchuse')}
                activeIcon={<img className="parts-modal-details__icon" src={Car} />}
                disabledIcon={<img className="parts-modal-details__icon" src={CarDisabled} />}
                countActive={stat.distanceBars}
              />
            </div>

            <Bordered rounded>
              <ContainerXS className="flex flex-row space-between">
                <span className="text text-sm text-uppercase text-secondary">{t('Sale Date')}</span>
                <span className="text text-sm text-uppercase text-secondary">{t('Amount')}</span>
              </ContainerXS>
              {stat.sales &&
                stat.sales.map((q, index) => (
                  <Bordered key={index} position="top">
                    <ContainerXS className="flex flex-row space-between">
                      <span className="text text-md">{dateFormatted(q.saleDate)}</span>
                      <span className="text text-md">{`${getCurrency()}${formatNumber(q.amount)}`}</span>
                    </ContainerXS>
                  </Bordered>
                ))}
            </Bordered>
          </Fragment>
        )}
        {!hasPurchases && (
          <Bordered rounded>
            <ContainerXS className="flex flex-row center">
              <span className="text text-sm text-uppercase text-secondary">{t('No purchase history')}</span>
            </ContainerXS>
          </Bordered>
        )}

        <div className="row">
          <Bordered>
            <ContainerMD>
              <img src={Chart1} className="chart" />
              <ContainerXS className="pd-sm flex flex-row space-between">
                {getMonthNamesShort().map((q) => (
                  <span key={q} className="text text-secondary text-xs">
                    {q}
                  </span>
                ))}
              </ContainerXS>
              <ContainerXS className="pb-0 text text-center text-sm">
                <span>{t('Seasonality')}</span>
              </ContainerXS>
            </ContainerMD>
          </Bordered>
          <Bordered>
            <ContainerMD>
              <img src={Chart2} className="chart" />
              <ContainerXS className="pd-sm flex flex-row space-between">
                {[...Array(5)].map((q, index) => (
                  <span key={q} className="text text-secondary text-xs">
                    {formatYear(new Date().getUTCFullYear() - (4 - index))}
                  </span>
                ))}
              </ContainerXS>
              <ContainerXS className="pb-0 text text-center text-sm">
                <span>{t('Trend')}</span>
              </ContainerXS>
            </ContainerMD>
          </Bordered>
        </div>
      </ContainerMD>
    </Bordered>
  );
};

export default PartsModalDetails;
