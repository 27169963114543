import React from 'react';

import { ContainerMD } from 'src/components/Branding/Container';
import CampaignDetails from './CampaignDetails';
import TargetSegments from './TargetSegments';
import Status from './Status';
import Results from '../Sections/Results';
import CampaignActivity from './CampaignActivity';

import './Overview.scss';

const Overview = ({ data, dealer, selectedLang }: any) => {
  return (
    <ContainerMD className="sections-layout__sections content-overflow">
      <CampaignDetails data={data} />
      <TargetSegments data={data} selectedLang={selectedLang} />
      <div className="statusResults">
        <Status data={dealer && dealer.outlet[0]} />
        <Results data={dealer && dealer.outlet[0]} />
      </div>
      <CampaignActivity />
    </ContainerMD>
  );
};

export default Overview;
