import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Button from 'src/components/Branding/Button';
import Select, { Option } from 'src/components/Branding/Select';
import { LOGOUT, SetCredentialsPayload, SET_CREDENTIALS } from 'src/store/auth/types';
import { removeStoredAuthToken } from 'src/shared/utils/authToken';

import UserIcon from 'src/assets/images/user-icon.svg';
import './User.scss';

interface UserProps {
  setCredentials: Function;
  name: string;
  second_name: string;
  role: string;
  logout: Function;
}

const User = ({ setCredentials, name, second_name, role, logout }: UserProps) => {
  const { t } = useTranslation();
  const { language } = i18next;

  useEffect(() => {
    setCredentials({
      role,
      name,
      secondName: second_name,
    });
  }, [name, second_name, role]);

  const logoutHandler = () => {
    logout();
    removeStoredAuthToken();
  };

  const UserMemo = useMemo(
    () => (
      <div id="userDropdownContainer" className="rounded">
        <img src={UserIcon} className="user-icon" />
        <Select
          value={''}
          className="user-dropdown"
          dropdownMatchSelectWidth={242}
          getPopupContainer={() => document.getElementById('userDropdownContainer') || document.body}
        >
          <Option value={1} title="">
            <div className="user-info">
              <div className="user-circle flex flex-row center">
                <span className="text text-md text-white text-bold">
                  {`${name ? name[0] : ''}${second_name ? second_name[0] : ''}`}
                </span>
              </div>
              <p className="text text-md text-black text-bold text-center text-uppercase user-info__name">
                {name} {second_name}
              </p>
              <p className="text text-sm text-black text-center m-0">{t(role)}</p>
            </div>
          </Option>
          <Option value={2} title="" disabled>
            <Button type="link" className="p-0 w-100 border-none account-settings" disabled>
              <a className="text text-black text-hover text-md text-center">{t('Account Setting')}</a>
            </Button>
          </Option>
          <Option value={3} title="">
            <Button type="link" className="p-0 w-100 border-none log-out" onClick={logoutHandler}>
              <a className="text text-black text-hover text-md text-center">{t('Log Out')}</a>
            </Button>
          </Option>
        </Select>
      </div>
    ),
    [name, second_name, role, language],
  );

  return UserMemo;
};

const mapDispatchToProps = (dispatch: Function) => ({
  setCredentials: (payload: SetCredentialsPayload) => dispatch({ type: SET_CREDENTIALS, payload }),
  logout: () => dispatch({ type: LOGOUT }),
});

export default connect(null, mapDispatchToProps)(User);
