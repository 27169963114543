import React from 'react';
import { Layout as DefaultLayout } from 'antd';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';

import './Layout.scss';

const Layout = () => (
  <DefaultLayout className="layout">
    <Header />
    <Content />
    <Footer />
  </DefaultLayout>
);

export default Layout;
