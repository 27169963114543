import {
  ContractsState,
  ContractsActionTypes,
  SELECT_CONTRACT,
  SELECT_CONTRACTS_INDEX,
  SELECTED_CONTRACT,
  SET_STARS,
  TOGGLE_STAR,
  ContractStar,
} from './types';
import { createCookie, deleteCookie } from 'src/shared/utils/cookies';

const initialState: ContractsState = {
  selectedContract: null,
  selectedContractsIndex: null,
  isSelected: false,
};

export function contractsReducer(state: ContractsState = initialState, action: ContractsActionTypes): ContractsState {
  switch (action.type) {
    case SELECT_CONTRACT:
      if (Boolean(action.payload)) {
        const expires = new Date();
        expires.setDate(expires.getDate() + 3);
        createCookie(SELECTED_CONTRACT, action.payload!, expires);
      } else {
        deleteCookie(SELECTED_CONTRACT);
      }

      return {
        ...state,
        selectedContract: action.payload,
        isSelected: Boolean(action.payload),
      };
    case SELECT_CONTRACTS_INDEX:
      const newState = state as any;
      return {
        ...newState,
        selectedContractsIndex: action.payload,
      };
    case SET_STARS:
      return {
        ...state,
        stars: [...action.payload.stars],
      };
    case TOGGLE_STAR:
      const old = state.stars;
      if (!old) {
        return state;
      }

      const current = old.find((q: ContractStar) => q.number === action.payload.number);
      if (current) {
        current!.star = !current!.star;
      }

      return {
        ...state,
        stars: [...old],
      };
    default:
      return state;
  }
}
