import React, { Fragment } from 'react';
import { Progress as DefaultProgress, Tooltip } from 'antd';

import { PropsBase } from 'src/components/types';
import AvgIndicator from './AvgIndicator';

interface ProgressProps extends PropsBase {
  averagePercent?: number;
  percent: number;
}

const Progress = ({ averagePercent, percent, className }: ProgressProps) => {
  return (
    <Fragment>
      {Boolean(averagePercent) && <AvgIndicator percent={averagePercent!} />}
      <Tooltip title={`${Math.round(percent)}%`}>
        <DefaultProgress className={className} percent={percent} type={'line'} showInfo={false} />
      </Tooltip>
    </Fragment>
  );
};

export default Progress;
