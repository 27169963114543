import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import RemoveTable from 'src/components/Campaigns/RemoveTable';

const RemoveDuplicates = ({ nextStep, selectedLang, data, selectCustomers, selectedCustomers }: any) => {
  const { t } = useTranslation();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);
  const [newData, setnewData] = useState<any>();
  const [fTime, setFTime] = useState(true);

  useEffect(() => {
    data && setnewData(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const columns = useMemo(
    () => [
      { title: t('Customer ID'), dataIndex: 'customerId', key: 'customerId' },
      { title: t('Customer Name'), dataIndex: 'customerName', key: 'customerName' },
      { title: t('Dealer'), dataIndex: 'dealer', key: 'dealer' },
      { title: t('Outlet'), dataIndex: 'outlet', key: 'outlet' },
      { title: t('Potential Duplicates'), dataIndex: 'potentialDuplicates', key: 'potentialDuplicates' },
    ],
    [selectedLang, selectedRowKeys],
  );

  const dataSource: any = useMemo(() => {
    if (newData) {
      const filteredCustomers = newData[1].outlet[0].customer.filter((e: any) => e.potential_duplicate.length > 0);
      newData[1].outlet[0].customer = [...filteredCustomers];
      return (
        newData &&
        newData[1].outlet[0].customer.map((e: any, i: any) => ({
          key: i,
          customerId: e.dm_customer_number,
          customerName: e.customer_name,
          dealer: newData[1].dealer_title,
          outlet: newData[1].outlet[0].outlet_title,
          potentialDuplicates: e.potential_duplicate.length + 1,
          autoSelected: !!e.contact_customer_yn,
          description: e.potential_duplicate.map((q: any) => ({
            customerId: q.potential_duplicate_customer_id,
            customerName: data[1].outlet[0].customer.map(
              (i: any) => i.dm_customer_number == q.potential_duplicate_customer_id && i.customer_name,
            ),
            dealer: newData[1].dealer_title,
            outlet: newData[1].outlet[0].outlet_title,
          })),
        }))
      );
    }
  }, [selectedLang, newData, selectedRowKeys]);

  useEffect(() => {
    const selectedKeys: any = [];
    selectedCustomers &&
      dataSource &&
      selectedCustomers.forEach((q: any) => {
        const index = dataSource && q && dataSource.findIndex((w: any) => w.customerId === q.customerId);
        selectedKeys.push(index);
      });
    setSelectedRowKeys(selectedKeys);
  }, [selectedCustomers, selectedLang, newData]);

  useEffect(() => {
    if (fTime && dataSource && !selectedCustomers) {
      const selectedKeys = dataSource.filter((q: any) => q.autoSelected).map((q: any) => q.key);
      selectedKeys.push(...selectedKeys);
      setFTime(false);
      const res = new Set(selectedKeys);
      setSelectedRowKeys(Array.from(res) as any);
    }
  }, [dataSource]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onSelect: (record: any, selected: any) => {
        const selectedRowKeysUpdated = [...selectedRowKeys];
        if (selected) {
          selectedRowKeysUpdated.push(dataSource && dataSource.findIndex((q: any) => q.key === record.key));
          let newCustomer: any = {};
          data[1].outlet[0].customer.map((e: any, i: any) => {
            if (e.dm_customer_number == record.customerId) {
              newCustomer = { key: i, customerId: record.customerId };
            }
          });
          selectedCustomers && selectedCustomers.push(newCustomer);
        } else {
          const currentItem = dataSource && dataSource.find((q: any) => q.customerId === record.customerId);
          const index = selectedRowKeysUpdated.findIndex((e: any) => e == currentItem.key);
          selectedRowKeysUpdated.splice(index, 1);
          const currentCustomerIndex =
            selectedCustomers && selectedCustomers.findIndex((q: any) => q.customerId === record.customerId);
          selectedCustomers && selectedCustomers.splice(currentCustomerIndex, 1);
        }
        selectCustomers!(selectedCustomers);
        setSelectedRowKeys(selectedRowKeysUpdated);
      },
      onSelectAll: (selected: boolean, selectedRowsComplete: any) => {
        if (selected) {
          const selectedKeys = selectedRowsComplete.map((q: any) => q && q.key);
          setSelectedRowKeys(selectedKeys);
          let newCustomers: any = [];
          data[1].outlet[0].customer.map((e: any, i: any) => {
            selectedRowsComplete.map((item: any) => {
              if (item && e.dm_customer_number == item.customerId) {
                newCustomers.push({ key: i, customerId: item.customerId });
              }
            });
          });
          newCustomers = newCustomers.filter((item: any) => {
            let hasItem = true;
            selectedCustomers.map((e: any) => {
              if (e.customerId == item.customerId) {
                hasItem = false;
              }
            });
            return hasItem;
          });
          selectedCustomers = selectedCustomers && selectedCustomers.concat(newCustomers);
          selectCustomers(selectedCustomers);

          let newExpandedCustomers: any = [];
          data[1].outlet[0].customer.map((e: any, i: any) => {
            dataSource.map(
              (q: any) =>
                q.description &&
                q.description.map((item: any) => {
                  if (item && e.dm_customer_number == item.customerId) {
                    newExpandedCustomers.push({ key: i, customerId: item.customerId });
                  }
                }),
            );
          });

          newExpandedCustomers = newExpandedCustomers.filter((item: any) => {
            let hasItem = true;
            selectedCustomers.map((e: any) => {
              if (e.customerId == item.customerId) {
                hasItem = false;
              }
            });
            return hasItem;
          });
          selectedCustomers = selectedCustomers && selectedCustomers.concat(newExpandedCustomers);
          selectCustomers(selectedCustomers);
        } else {
          selectedCustomers = selectedCustomers.filter((item: any) => {
            let hasItem = true;
            dataSource.map((e: any) => {
              if (e.customerId == item.customerId) {
                hasItem = false;
              }
            });
            return hasItem;
          });
          selectCustomers!(selectedCustomers);
          setSelectedRowKeys([]);

          selectedCustomers = selectedCustomers.filter((item: any) => {
            let hasItem = true;
            dataSource.map(
              (q: any) =>
                q.description &&
                q.description.map((e: any) => {
                  if (e.customerId == item.customerId) {
                    hasItem = false;
                  }
                }),
            );
            return hasItem;
          });
          selectCustomers!(selectedCustomers);
        }
      },
    }),
    [selectedRowKeys],
  );

  return (
    <RemoveTable
      nextStep={nextStep}
      data={dataSource}
      columns={columns}
      rowSelection={rowSelection}
      selectCustomers={selectCustomers}
      selectedCustomers={selectedCustomers}
      allCustomers={data && data[1].outlet[0].customer}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveDuplicates);
