import moment from 'moment';
import i18next from 'i18next';

export const addDaysFromNow = (count: number) => {
  return moment().add(count, 'days').toDate();
};

export const getDate = (date: any): any => {
  if (typeof date === 'undefined') {
    return undefined;
  }

  return moment([date.year, date.month - 1, date.day]).toDate();
};

export const getMonthNamesShort = () => {
  return moment.monthsShort();
};

export const fromNowString = (date: Date) => {
  return moment(date).fromNow();
};

export const dateFormatted = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatYear = (year: number) => {
  return i18next.t('year', { year });
};

export const formatFromMonth = (date: Date) => {
  return moment(date).format('ll');
};

export const dateDiffs = (date1: Date, date2: Date) => {
  return Math.floor((date1.getTime() - date2.getTime()) / 86400000);
};

export const getTimePeriod = (date1: Date, date2: Date) => {
  return `${moment(date1).format('YYYY-MM-DD')} &ndash; ${moment(date2).format('YYYY-MM-DD')}`;
};

export const getDateWithObjFormat = (date: Date): any => {
  if (!date) return null;
  
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  };
};
