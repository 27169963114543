import React, { Fragment } from 'react';
import { Select as DefaultSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import ArrowDown from 'src/assets/images/arrow-select-icon.svg';

import { PropsBase } from 'src/components/types';

export const { Option } = DefaultSelect;

interface SelectOptions extends PropsBase, SelectProps<string | number> {
  options?: {
    label: string;
    value: string | number;
  }[];
}

const Select = ({ onSelect, defaultValue, options, children, ...rest }: SelectOptions) => (
  <Fragment>
    <DefaultSelect
      suffixIcon={<img src={ArrowDown} />}
      defaultValue={defaultValue}
      bordered={false}
      onSelect={onSelect}
      {...rest}
    >
      {options &&
        options.map((q) => (
          <Option key={q.value} value={q.value}>
            {q.label}
          </Option>
        ))}
      {!options && children}
    </DefaultSelect>
  </Fragment>
);

export default Select;
