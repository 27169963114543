import React from 'react';

import Bordered from 'src/components/Branding/Border';

import './InfoRow.scss';

interface InfoRowProps {
  name: string;
  value: string | any;
}

const InfoRow = ({ name, value }: InfoRowProps) => (
  <Bordered position="bottom">
    <div className="info-raw">
      <span className="text text-sm text-secondary">{name}</span>
      <span className="text text-sm text-bold">{value}</span>
    </div>
  </Bordered>
);

export default InfoRow;
