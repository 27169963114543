export const SET_CAMPAIGN_DATA = 'SET_CAMPAIGN_DATA';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';

export interface CampaignsState {
  data: object | null;
}

export interface SetCampaignDataAction {
  type: typeof SET_CAMPAIGN_DATA;
  payload: object | null;
}

export interface AddCampaignAction {
  type: typeof ADD_CAMPAIGN;
  payload: object | null;
}

export interface EditCampaignAction {
  type: typeof EDIT_CAMPAIGN;
  payload: object | null;
}

export type CampaignsActionTypes = SetCampaignDataAction | AddCampaignAction | EditCampaignAction;
