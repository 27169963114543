export const createCookie = (name: string, value: string, expires: Date | number) => {
  let cookie = name + '=' + escape(value) + ';';

  if (expires) {
    // If it's a date
    if (expires instanceof Date) {
      // If it isn't a valid date
      if (isNaN(expires.getTime())) expires = new Date();
    } else expires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);

    cookie += 'expires=' + expires.toUTCString() + ';';
  }

  document.cookie = cookie;
};

export const getCookie = (name: string) => {
  const regexp = new RegExp('(?:^' + name + '|;s*' + name + ')=(.*?)(?:;|$)', 'g');
  const result = regexp.exec(document.cookie);
  return result === null ? null : result[1];
};

export const deleteCookie = (name: string) => {
  // If the cookie exists
  if (getCookie(name)) {
    createCookie(name, '', -1);
  }
};
