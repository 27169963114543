import { AuthState, AuthActionTypes, LOGIN, LOGOUT, SET_CREDENTIALS } from './types';

const initialState: AuthState = {
  authenticated: null,
  token: null,
  name: null,
  secondName: null,
  role: null,
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case SET_CREDENTIALS:
      return {
        ...state,
        role: action.payload.role,
        secondName: action.payload.secondName,
        name: action.payload.name,
      };
    case LOGIN:
      return {
        ...state,
        token: action.payload,
        authenticated: !!action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        authenticated: false,
        role: null,
        secondName: null,
        name: null,
      };
    default:
      return state;
  }
}
