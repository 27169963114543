import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Branding/Button';
import WarningModal from 'src/components/Branding/Modal/Warning';

import './DemoPrototype.scss';

const DemoPrototype = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Button className="mr-xs demo-btn" type="link" onClick={() => setModalOpen(true)}>
        {t('Demo Prototype')}
      </Button>
      <WarningModal
        visible={modalOpen}
        centered
        title={t('Demo Prototype')}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        <p className="text text-md text-left demo-text">{t('Demo Text')}</p>
        <p className="text text-md text-left demo-text">{t('Demo Sub Text')}</p>
        <p className="text text-md text-left demo-text">{t('Demo Sub2 Text')}</p>
        <p className="text text-md text-left demo-text">&nbsp;&nbsp;&nbsp;&nbsp;{t('Demo Sub3 Text')}</p>
        <p className="text text-md text-left demo-text">{t('Demo Sub4 Text')}</p>
        <p className="text text-md text-left demo-text">&nbsp;&nbsp;&nbsp;&nbsp;{t('Demo Sub5 Text')}<br/>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;{t('Demo Sub6 Text')}<br/>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;{t('Demo Sub7 Text')}</p>
        <p className="text text-md text-left demo-text">{t('Demo Sub8 Text')}</p>
        <p className="text text-md text-left demo-text">&nbsp;&nbsp;&nbsp;&nbsp;{t('Demo Sub9 Text')}<br/>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;{t('Demo Sub10 Text')}</p>
        <p className="text text-md text-left demo-text">{t('Demo Sub11 Text')}</p>
        <p className="text text-md text-left demo-text">&nbsp;&nbsp;&nbsp;&nbsp;{t('Demo Sub12 Text')}</p>
      </WarningModal>
    </Fragment>
  );
};

export default DemoPrototype;
