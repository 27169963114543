import { useRef, useLayoutEffect } from "react";

// default position to top
let scrollPositions = 0;

/**
 * Use this hook to remember scroll position before list rerender,
 * and restore to this position after the rerender complete.
 * Please make sure that the list element must set the overflow to auto or scroll,
 * otherwise it will not trigger onscroll event.
 * @returns the container reference, pass it to list element.
 */
const useContainerScroll = () => {
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (ref && ref.current) {
      scrollPositions = ref.current.scrollTop;
    }
  };

  // add event listener to track scroll position
  useLayoutEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener("scroll", handleScroll, true);
    }
    return () => {
      if (ref && ref.current) {
      ref.current.removeEventListener("scroll", handleScroll)
      }
    };
  }, [ref]);

  // restore scroll on initial render
  useLayoutEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTop = scrollPositions;
    }
  }, [ref]);

  return ref;
};

export default useContainerScroll;
