import mockApi from './api.mock';
import webApi from './api.client';

/**
 * If REACT_APP_MOCK_WEB_API is true mock api is used based on ./mock/mock.data.json.
 * Otherwise we api used.
 */
const api = process.env.REACT_APP_MOCK_WEB_API === 'true' ? mockApi : webApi;

/**
 * Caches new data that must be changed on the server.
 */
const optimisticUpdate = async (url: string, { updatedFields, currentFields, setLocalData }: any) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
  }
};

export default {
  api,
  get: (...args: [string, any?]): any => api('get', ...args),
  post: (...args: [string, any?]): any => api('post', ...args),
  put: (...args: [string, any?]): any => api('put', ...args),
  patch: (...args: [string, any?]): any => api('patch', ...args),
  delete: (...args: [string, any?]): any => api('delete', ...args),
  optimisticUpdate,
};
