import React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'src/store';
import Filters from 'src/pages/Customers/CustomersContracts/Filters';
import Sections, { SectionsProps } from './Sections';

export type CustomersContractsProps = {
  selectedLanguage?: string;
} & SectionsProps;

const CustomersContracts = ({ selectedLanguage, ...rest }: CustomersContractsProps) => (
  <div className="customers-contracts border border-right">
    <Filters selectedLanguage={selectedLanguage} />
    <Sections {...rest} />
  </div>
);

const mapStateToProps = (state: RootState) => ({
  selectedLanguage: state.lang.selectedLanguage,
});

export default connect(mapStateToProps, null)(CustomersContracts);
