import React from 'react';
import { ContainerSM } from 'src/components/Branding/Container';

import './CustomersContainer.scss';
import { PropsBase } from 'src/components/types';

const CustomersContainer = ({ className, ...rest }: PropsBase) => (
  <ContainerSM {...rest} className={`customers-container${className ? ` ${className}` : ''}`} />
);

export default CustomersContainer;
