import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Branding/Table';

import './Inventory.scss';

const Inventory = ({ data }: any) => {
  const { t } = useTranslation();

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data.inventory &&
        data.inventory.map((item: any, i: number) => ({
          key: i,
          brand: item.brand,
          model: item.model,
          power: item.power,
        }))
      );
    }
  }, [data]);

  const columns = [
    {
      title: t('Brand'),
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: t('Model'),
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: t('Power'),
      dataIndex: 'power',
      key: 'power',
    },
  ];

  return (
    <div className="InventoryContainer">
      <div className="flex space-between text-sm">
        <span className="text-secondary  text-uppercase">{t('Inventory')}</span>
        <span className="text-blue text-md">{t('Go to Inventory')}</span>
      </div>
      <Table hasPagination={false} columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default Inventory;
