import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Bordered from 'src/components/Branding/Border';
import CampaignsContainer from 'src/shared/components/Campaigns/CampaignsContainer';
import Filters from 'src/shared/components/Campaigns/Filters';
import Button from 'src/components/Branding/Button';

import './Header.scss';

const Header = () => {
  const { t } = useTranslation();

  return (
    <Bordered position="bottom">
      <CampaignsContainer className="campaigns__section__header">
        <p className="text-uppercase text-main text-bold text-xxs m-0">{t('Campaigns')}</p>
        <div className="filtersContainer">
          <Filters />
          <NavLink to="/campaigns/create">
            <Button className="btn createBtn btn-blue">
              <span className="text text-sm text-bold text-white text-uppercase">{t('Create New Campaign')}</span>
            </Button>
          </NavLink>
        </div>
      </CampaignsContainer>
    </Bordered>
  );
};
export default Header;
