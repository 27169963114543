import React from 'react';

import Bordered from 'src/components/Branding/Border';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined } from '@ant-design/icons';

import './Overview.scss';
import { dateFormatted } from 'src/shared/utils/date';
import { formatNumber, getCurrency } from 'src/shared/utils/formatters';

interface OveriewItemProps {
  value?: string | any;
  title?: string;
}

const OverviewItem = ({ value, title }: OveriewItemProps) => (
  <div className="itemContainer flex flex-column align-center space-between">
    <span className="text-xxs text-bold text-main">{value}</span>
    <span className="text-sm text-secondary">{title}</span>
  </div>
);

const Overview = ({ data }: any) => {
  const { t } = useTranslation();

  return (
    <div className="overviewContainer">
      <div className="flex space-between text-uppercase text-sm">
        <span className="text-secondary">{t('Overview')}</span>
        <span className="text-bold text-main">
          {t('Last 2 Years')} <CaretDownOutlined />
        </span>
      </div>
      <Bordered className="overviewContent">
        <OverviewItem value={data.customer_car && data.customer_car.length} title={t('Current Vehicles')} />
        <OverviewItem
          value={data.next_scheduled_contact_date && dateFormatted(data.next_scheduled_contact_date)}
          title={t('Next Scheduled Contact')}
        />
        <OverviewItem
          value={data.next_scheduled_service_date && dateFormatted(data.next_scheduled_service_date)}
          title={t('Next Scheduled Service')}
        />
        <OverviewItem value={data.campaign_cnt_test_drive_scheduled ?? ''} title={t('Current Loans')} />
        <OverviewItem
          value={data.overall_sales && getCurrency() + formatNumber(data.overall_sales)}
          title={t('Overall Sales')}
        />
      </Bordered>
    </div>
  );
};

export default Overview;
