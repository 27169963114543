import React from 'react';
import { Card as DefaultCard } from 'antd';
import { PropsBase } from 'src/components/types';
import Bordered from '../Border';

interface CardProps extends PropsBase {
  title?: string;
}

const Card = ({ title, className, children }: CardProps) => (
  <Bordered className={className}>
    <DefaultCard title={title}>{children}</DefaultCard>
  </Bordered>
);

export default Card;
