import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Padding from 'src/components/Branding/Spaceing/Padding';
import Table from 'src/components/Branding/Table';
import Loading from 'src/components/Branding/Loading';
import campaignService from 'src/shared/services/campaign.service';
import { getDate, getTimePeriod } from 'src/shared/utils/date';
import { formatNumber } from 'src/shared/utils/formatters';
import { getCampaignColumns } from 'src/components/Campaigns/config';
import Header from './Header';
import { useHistory } from 'react-router-dom';

import './Campaigns.scss';

interface CampaignsProps {
  selectedLang: string | null;
  subscribe?: Function;
}

/**
 * Represents campaigns page for financial sales consultant.
 */
const Campaigns = ({ selectedLang, subscribe }: CampaignsProps) => {
  const { t } = useTranslation();
  const [{ data }, fetchData] = campaignService.getCampaign();

  const history = useHistory();

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  const columns = getCampaignColumns(['campaignId', 'description', 'campaignPeriod', 'numberOfCustomers'], t);

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data.campaign &&
        data.campaign
          .filter((q: any) => q.status.findIndex((w: any) => w.status_id === 'active') !== -1)
          .map((q: any) => ({
            campaignId: q.campaign_id,
            description: q.description,
            campaignPeriod: getTimePeriod(
              getDate(q.campaign_period_nationwide_start_date)!,
              getDate(q.campaign_period_nationwide_end_date)!,
            ),
            numberOfCustomers: formatNumber(q.number_of_customers),
          }))
      );
    }
  }, [data, selectedLang, columns]);

  return (
    <>
      <Header />
      <Padding size="md" className="flex sales-consultant">
        {dataSource && (
          <Table
            dataSource={dataSource}
            columns={columns}
            hasPagination
            onRow={(record: any) => ({
              onClick: () => {
                history.push(`/campaigns/details?id=${encodeURIComponent(record.campaignId)}`);
              },
              onMouseEnter: (event: any) => {
                event.currentTarget.style.cursor = 'pointer';
              },
            })}
          />
        )}
        {!dataSource && <Loading />}
      </Padding>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
