import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Branding/Table';
import { dateFormatted, getDate } from 'src/shared/utils/date';

import './ActivityHistory.scss';

const ActivityHistory = ({ data }: any) => {
  const { t } = useTranslation();

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data.activity_history &&
        data.activity_history.map((item: any, i: number) => ({
          key: i,
          date: dateFormatted(getDate(item.activity_date)!),
          types: item.activity_type,
          details: item.details,
          isVisited: Boolean(item.isVisited),
        }))
      );
    }
  }, [data]);

  const columns = [
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('Types'),
      dataIndex: 'types',
      key: 'types',
    },
    {
      title: t('Details'),
      dataIndex: 'details',
      key: 'details',
    },
  ];

  return (
    <div className="ActivityHistoryContainer">
      <div className="flex space-between text-sm">
        <span className="text-secondary  text-uppercase">{t('Activity History')}</span>
        <span className="text-blue text-md">{t('Go to Activities')}</span>
      </div>
      <Table hasPagination={false} columns={columns} dataSource={dataSource} rowClassName="expandableRow" />
    </div>
  );
};

export default ActivityHistory;
