import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import moment from 'moment';
import Bordered from 'src/components/Branding/Border';
import CampaignsContainer from 'src/shared/components/Campaigns/CampaignsContainer';
import { CampaignHeaderProps } from 'src/components/Campaigns/types';
import Button from 'src/components/Branding/Button';
import DownloadIcon from 'src/assets/images/download-icon.svg';
import { getTimePeriod } from 'src/shared/utils/date';

const CampaignDetailsHeader = ({ title, current, total, campaignStartDate, campaignEndDate }: CampaignHeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Bordered position="bottom" className="m-0">
        <CampaignsContainer className="headerContainer">
          <div className="title">
            <NavLink to="/campaigns">
              <ArrowLeftOutlined className="arrowLeft flex align-center" />
            </NavLink>
            <p className="text-uppercase text-main text-bold text-xxs m-0">{title}</p>
            <span className="ellipse">&#9679;</span>
            <span
              className="text-main"
              dangerouslySetInnerHTML={{
                __html: getTimePeriod(moment(campaignStartDate).toDate()!, moment(campaignEndDate).toDate()!),
              }}
            />
            <span className="ellipse">&#9679;</span>
            <span className="flex text-md align-center">
              {current} {t('Customers_low')}
            </span>
          </div>
          <div className="btnsContain">
            <Button className="downloadBtn btn btn-white" disabled>
              <img src={DownloadIcon} />
              <span className="text text-sm text-bold text-main text-uppercase">{t('Download Contact List')}</span>
            </Button>
            <Button className="duplicateBtn btn btn-white" disabled>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Duplicate Campaign')}</span>
            </Button>
            <Button className="deleteBtn btn btn-white" disabled>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Delete')}</span>
            </Button>
          </div>
        </CampaignsContainer>
      </Bordered>
    </>
  );
};

export default CampaignDetailsHeader;
