import React from 'react';
import { Modal as DefaultModal } from 'antd';
import { ModalProps as DefaultModalProps } from 'antd/lib/modal';
import CloseIcon from 'src/assets/images/close-icon.svg';

import { PropsBase } from 'src/components/types';

import './Modal.scss';

export type ModalProps = DefaultModalProps & PropsBase;

const Modal = ({ children, className, closeIcon, ...rest }: ModalProps) => (
  <DefaultModal className={`custom-modal ${className ?? ''}`} closeIcon={<img src={CloseIcon} />} {...rest}>
    {children}
  </DefaultModal>
);

export default Modal;
