import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';

import Card from 'src/components/Branding/Card';
import Button from 'src/components/Branding/Button';
import { ContainerLG } from 'src/components/Branding/Container';
import { required, email } from 'src/shared/utils/validators';
import authService from 'src/shared/services/auth.service';
import { LOGIN } from 'src/store/auth/types';
import { storeAuthToken } from 'src/shared/utils/authToken';
import Input from 'src/components/Branding/Input';

import './Login.scss';

const layout = {
  labelCol: { span: 24 },
};

interface LoginProps {
  login: Function;
}

const Login = ({ login }: LoginProps) => {
  const [{ data, error, isCreating }, makeRequest] = authService.signIn();
  const { t } = useTranslation();

  const submit = async (values: Store) => {
    await makeRequest(values);
  };

  if (data) {
    storeAuthToken(data.token);
    login(data.token);
  }

  return (
    <div className="login">
      <div className="login-bg flex flex-row vertical-center center">
        <Card className="login__card">
          <ContainerLG>
            <div className="login__form-container">
              <p className="text text-uppercase text-bold text-xxs login-title">{t('LOGIN Title')}</p>
              <Form {...layout} className="login__form-container__form" onFinish={submit}>
                {error && <span className="text text-md text-danger">{t('Forms.Invalid Credentials')}</span>}
                <Form.Item name="user_email" label={t('Forms.Email')} rules={[required('Email'), email()]}>
                  <Input size="large" />
                </Form.Item>
                <Form.Item name="password" label={t('Forms.Password')} rules={[required('Password')]}>
                  <Input type="password" size="large" />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" block size="large" loading={isCreating} className="btn btn-blue">
                    <span className="text text-md text-white">{t('Login')}</span>
                  </Button>
                </Form.Item>
              </Form>
              <Button type="link" disabled className="p-0 h-fit border-none h-16">
                <a className="text-center text text-md text-blue text-hover">{t('Reset your password')}</a>
              </Button>
            </div>
          </ContainerLG>
        </Card>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  login: (payload: string) => dispatch({ type: LOGIN, payload }),
});

export default connect(null, mapDispatchToProps)(Login);
