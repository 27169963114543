import React from 'react';
import { useTranslation } from 'react-i18next';
import Bordered from 'src/components/Branding/Border';
import Tag from 'src/components/Branding/Tag';

import './TargetSegments.scss';

const TargetSegments = ({ data, selectedLang }: any) => {
  const { t } = useTranslation();

  return (
    <div className="targetSegmentContainer">
      <div className="flex space-between text-sm">
        <span className="text-secondary  text-uppercase">{t('Target Segments')}</span>
      </div>
      <Bordered className="targetSegmentContent text-md">
        <div className="contain text-secondary">
          {data &&
            data.target_segments_applied &&
            data.target_segments_applied.map((q: any, index: number) => (
              <>
                {q.target_segment_section.target_segment_item.length > 0 && (
                  <div className="keyValue">
                    <p className={selectedLang === 'en' ? 'keyInEng' : 'keyInJpn'}>
                      {q!.target_segment_section.target_segment_section_title}:
                    </p>
                    <div className="flex">
                      {q.target_segment_section.target_segment_item.map((item: any, index: number) => (
                        <Tag>
                          <span className="text-main text-sm">{item && item.target_segment_item_title}</span>
                        </Tag>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
      </Bordered>
    </div>
  );
};

export default TargetSegments;
