import { Rule } from 'antd/lib/form';

/**
 * These validators are used as form field validators
 * with predefined messages and rules to avoid code repeat.
 */

export const required = (fieldName: string): Rule => ({ required: true, message: `${fieldName} is required.` });

export const email = (): Rule => ({ type: 'email', message: 'Invalid email format.' });
