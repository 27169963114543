import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { PropsBase } from 'src/components/types';

export type AuthRouteProps = {
  auth: boolean | null;
  fallback: string;
} & PropsBase &
  RouteProps;

/**
 * Checks for user authentication for given route.
 * If user is not authenticated redirects to fallback url.
 */
const AuthRoute = ({ auth, fallback, children, ...rest }: AuthRouteProps) => (
  <>
    {auth && <Route {...rest}>{children}</Route>}
    {!auth && <Redirect to={fallback} />}
  </>
);

export default AuthRoute;
