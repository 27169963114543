import {
  CustomersState,
  CustomersActionTypes,
  SELECT_CUSTOMER,
  OPEN_PARTS_MODAL,
  CLOSE_PARTS_MODAL,
  SELECTED_CUSTOMER,
  SET_STARS,
  TOGGLE_STAR,
  CustomerStar,
  SELECT_CUSTOMERS_INDEX,
} from './types';
import { createCookie, deleteCookie } from 'src/shared/utils/cookies';

const initialState: CustomersState = {
  selectedCustomer: null,
  selectedCustomersIndex: null,
  isSelected: false,
  partsModalOpen: false,
};

export function customersReducer(state: CustomersState = initialState, action: CustomersActionTypes): CustomersState {
  switch (action.type) {
    case SELECT_CUSTOMER:
      if (Boolean(action.payload)) {
        const expires = new Date();
        expires.setDate(expires.getDate() + 3);
        createCookie(SELECTED_CUSTOMER, action.payload!, expires);
      } else {
        deleteCookie(SELECTED_CUSTOMER);
      }

      return {
        ...state,
        selectedCustomer: action.payload,
        isSelected: Boolean(action.payload),
      };
    case SELECT_CUSTOMERS_INDEX:
      const newState = state as any;
      return {
        ...newState,
        selectedCustomersIndex: action.payload,
      };
    case OPEN_PARTS_MODAL:
      return {
        ...state,
        partsModalOpen: true,
        selectedPart: action.payload.selectedPart,
        selectedBox: action.payload.selectedBox,
      };
    case CLOSE_PARTS_MODAL:
      return {
        ...state,
        partsModalOpen: false,
        selectedPart: undefined,
        selectedBox: undefined,
      };
    case SET_STARS:
      return {
        ...state,
        stars: [...action.payload.stars],
      };
    case TOGGLE_STAR:
      const old = state.stars;
      if (!old) {
        return state;
      }

      const current = old.find((q: CustomerStar) => q.number === action.payload.number);
      if (current) {
        current!.star = !current!.star;
      }

      return {
        ...state,
        stars: [...old],
      };
    default:
      return state;
  }
}
