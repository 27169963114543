import React from 'react';
import Tag from 'src/components/Branding/Tag';
import { useTranslation } from 'react-i18next';

import './Tags.scss';

const Tags = () => {
  const { t } = useTranslation();
  return (
    <div className="tagsContainer">
      <Tag closable>{t('Tokyo branch')}</Tag>
      <Tag closable>{t('Matsumoto Go')}</Tag>
      <Tag closable>{t('In Retention')}</Tag>
    </div>
  );
};

export default Tags;
