import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Input, { TextArea } from 'src/components/Branding/Input';
import { ContainerFULL } from 'src/components/Branding/Container';
import StepContainer from 'src/components/Campaigns/StepContainer';
import DatePicker from 'src/components/Branding/DatePicker';
import { dateFormatted, getDate } from 'src/shared/utils/date';
import DatePickerIcon from 'src/assets/images/datePicker-icon.svg';

import './Release.scss';

const Release = ({
  toBack,
  nextStep,
  campaignStartDate,
  endDate,
  followUpStartDate,
  setFollowUpStartDate,
  dueDate,
  setDueDate,
  memoToDealers,
  setMemoToDealers,
  memoToSalesTeam,
  setMemoToSalesTeam,
  selectedLang,
}: any) => {
  const { t } = useTranslation();

  const disableFollowUpStartDate = (current: any, campaignStartDate: any, campaignEndDate: any, dueDate: any) => {
    return (
      current &&
      (current < moment(campaignStartDate, 'YYYY-MM-DD') ||
        current < moment(campaignEndDate, 'YYYY-MM-DD') ||
        current > moment(dueDate, 'YYYY-MM-DD'))
    );
  };

  const disableFollowUpEndDate = (current: any, campaignStartDate: any, campaignEndDate: any, startDate: any) => {
    return (
      current &&
      (current < moment(campaignStartDate, 'YYYY-MM-DD') ||
        current < moment(campaignEndDate, 'YYYY-MM-DD') ||
        current < moment(startDate, 'YYYY-MM-DD'))
    );
  };

  return (
    <ConfigProvider locale={selectedLang === 'jp' ? jaJP : enUS}>
      <StepContainer hasBackButton back={toBack} nextStep={nextStep}>
        <ContainerFULL className="parametersContainer">
          <div className="sampleRow">
            <div className="sampleInput">
              <p className="text-main text-md m-0">{t('Follow-up Period')}</p>
              <ContainerFULL className="datePickerContainer">
                <div className="dateItem">
                  <p className="text-main text-sm m-0">{t('Start Date')}</p>
                  <DatePicker
                    defaultValue={followUpStartDate && moment(followUpStartDate)}
                    onChange={(e: any) => setFollowUpStartDate(e)}
                    disabledDate={(current: any) =>
                      disableFollowUpStartDate(current, campaignStartDate, endDate, dueDate)
                    }
                    className="datePicker"
                    placeholder=""
                    suffixIcon={<img src={DatePickerIcon}></img>}
                  />
                </div>
                <div className="dateItem">
                  <p className="text-main text-sm m-0">{t('Due Date')}</p>
                  <DatePicker
                    defaultValue={dueDate && moment(dueDate)}
                    onChange={(e: any) => setDueDate(e)}
                    disabledDate={(current: any) =>
                      disableFollowUpEndDate(current, campaignStartDate, endDate, followUpStartDate)
                    }
                    className="datePicker"
                    placeholder=""
                    suffixIcon={<img src={DatePickerIcon}></img>}
                  />
                </div>
              </ContainerFULL>
            </div>
            <div className="sampleInput">
              <p className="text-main text-md m-0">{t('Campaign Period (Nationwide)')}</p>
              <ContainerFULL className="datePickerContainer">
                <div className="dateItem">
                  <p className="text-main text-sm m-0">{t('Start Date')}</p>
                  <Input className="input" disabled value={dateFormatted(moment(campaignStartDate).toDate())} />
                </div>
                <div className="dateItem">
                  <p className="text-main text-sm m-0">{t('End Date')}</p>
                  <Input className="input" disabled value={dateFormatted(moment(endDate).toDate())} />
                </div>
              </ContainerFULL>
            </div>
          </div>
          <div className="textArea">
            <ContainerFULL className="memoContainer">
              <ContainerFULL className="dateItem">
                <p className="text-main text-md m-0">{t('Sales Manager Memo to Sales Team')}</p>
                <TextArea
                  className="textArea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  value={memoToSalesTeam}
                  onChange={(e: any) => setMemoToSalesTeam(e.target.value)}
                />
              </ContainerFULL>
              <ContainerFULL className="dateItem">
                <p className="text-main text-md m-0">{t('Memo to Dealers from Group Japan')}</p>
                <TextArea
                  disabled
                  className="textArea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  value={memoToDealers}
                  onChange={(e: any) => setMemoToDealers(e.target.value)}
                />
              </ContainerFULL>
            </ContainerFULL>
          </div>
        </ContainerFULL>
      </StepContainer>
    </ConfigProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Release);
