import { values } from 'lodash';
import { AxiosResponse } from 'axios';
import { HTTPMethod } from 'src/shared/types';
import mock from './mock';
import { getStoredI18nToken } from './i18n';

/**
 * Web api mock.
 */
const mockApi = (method: HTTPMethod, url: string, variables: any) => {
  const mockObj = getLangMock(getStoredI18nToken() ?? 'en');
  return Promise.resolve<AxiosResponse<any>>(
    (mockObj as any)[
      method
        .concat(url)
        .concat(values(variables).some((x) => x) && values(variables.params).some((x) => x) ? '?queried' : '')
    ],
  );
};

const getLangMock = (lang: string) => {
  switch (lang) {
    case 'jp':
      return mock().jp;

    default:
      return mock().en;
  }
};

export default mockApi;
