import React, { useRef, useState, MutableRefObject, useEffect, useCallback } from 'react';
import { PropsBase } from 'src/components/types';
import useOnOutsideClick from 'src/shared/hooks/onOutsideClick';

import './Popup.scss';

interface PopupProps extends PropsBase {
  owner: MutableRefObject<any>;
  closePopup: any;
}

const Popup = ({ children, owner, closePopup }: PopupProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const $containerRef = useRef(null);
  const $initilized = useRef(false);
  const callback = useCallback(() => setDropdownOpen(!isDropdownOpen), []);

  useOnOutsideClick($containerRef, isDropdownOpen, (e: Event) => {
    owner.current.removeEventListener('click', callback);
    setDropdownOpen(false);
    e.stopImmediatePropagation();
  });

  setTimeout(() => {
    if (owner.current) {
      $initilized.current = true;
      owner.current.addEventListener('click', callback);
      owner.current.style.cursor = 'pointer';
    }
  }, 0);

  closePopup.current = setDropdownOpen;

  return (
    <div className="popup-container" ref={$containerRef} data-visible={isDropdownOpen}>
      {children}
    </div>
  );
};

export default Popup;
