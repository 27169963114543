import React, { Fragment } from 'react';

import { PropsBase } from 'src/components/types';
import Padding from 'src/components/Branding/Spaceing/Padding';

import DetailsGrid from './DetailsGrid';
import Teleservice from './Teleservice';
import ProgressCard, { ProgressCardProps } from './ProgressCard';

import './VehicleDetails.scss';

export interface VehicleDetailsProps extends PropsBase {
  teleservices: Object;
  progressStats?: ProgressCardProps[];
}

const VehicleDetails = ({ progressStats, teleservices }: VehicleDetailsProps) => (
  <Fragment>
    <Padding position="bottom" size="md">
      <Teleservice teleservices={teleservices} />
    </Padding>

    <DetailsGrid>
      {progressStats && progressStats.map((stat) => <ProgressCard key={stat.title} {...stat} />)}
    </DetailsGrid>
  </Fragment>
);

export default VehicleDetails;
