import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';

import { PropsBase } from 'src/components/types';
import { RadioGroup, RadioButton } from 'src/components/Branding/Radio';

import { CustomerStar, TOGGLE_STAR } from 'src/store/customers/types';
import { RootState } from 'src/store';
import Tag from 'src/components/Branding/Tag';
import Bordered from 'src/components/Branding/Border';
import './Header.scss';

interface HeaderProps extends PropsBase {
  name: string;
  activePage: string;
  number: number;
  stars?: CustomerStar[];
  radioButtonClick?: Function;
  toggleStar?: Function;
}

const Header = ({ name, radioButtonClick, activePage }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="right-header">
      <div className="customer-details pr-sm">
        <div className="customer-details__name-container">
          <span className="text text-xxs text-bold text-uppercase mr-sm dealer-name">{name}</span>
          <Tag>{t('To Do')}</Tag>
          <Bordered className="tagExpand">
            <CaretDownOutlined />
          </Bordered>
        </div>
      </div>
      <div className="right-nav">
        <RadioGroup value={activePage}>
          <RadioButton onClick={radioButtonClick} value="overview">
            {t('Overview')}
          </RadioButton>
          <RadioButton disabled value="vehicles">
            {t('Vehicles')}
          </RadioButton>
          <RadioButton disabled value="activities">
            {t('Activities')}
          </RadioButton>
          <RadioButton onClick={radioButtonClick} value="financing">
            {t('Financing')}
          </RadioButton>
          <RadioButton disabled value="aftersales">
            {t('Aftersales')}
          </RadioButton>
          <RadioButton disabled value="inventory">
            {t('Inventory')}
          </RadioButton>
        </RadioGroup>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  stars: state.customers.stars,
});

const mapDispatchToProps = (dispatch: Function) => ({
  toggleStar: (number: string) => dispatch({ type: TOGGLE_STAR, payload: { number } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
