import React, { useState, useCallback, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import Bordered from 'src/components/Branding/Border';
import { ContainerXS } from 'src/components/Branding/Container';
import { dateFormatted } from 'src/shared/utils/date';
import ProgressBold from 'src/components/Branding/Progress/Bold';
import ProgressDetailed from 'src/components/Branding/Progress/Detailed';
import { MatchmakingStat } from '../../VehicleDetails/ProgressCard/Probabilities/ProbabilityStats';
import PartsModalDetails from './PartsModalDetails';

import './PartsModalTable.scss';

interface PartsModalTableProps {
  stats: MatchmakingStat[];
  selectedPart?: string;
}

const PartsModalTable = ({ stats, selectedPart }: PartsModalTableProps) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(100);
  const [collapsed, setCollapsed] = useState<boolean[]>([]);

  useEffect(() => {
    setCollapsed([]);
  }, [stats]);

  for (let index = 0; index < stats.length; index++) {
    collapsed.push(!Boolean(stats[index].name == selectedPart));
  }

  const toggle = useCallback(
    (index: number) => {
      collapsed[index] = !collapsed[index];
      setCollapsed([...collapsed]);
    },
    [stats],
  );

  return (
    <div className="parts-modal-grid">
      <ContainerXS className="grid title">
        <div></div>
        <span className="text text-sm text-uppercase text-secondary grid-item grid-start">{t('Part')}</span>
        <span className="text text-sm text-uppercase text-secondary grid-item grid-start">{t('Probability')}</span>
        <span className="text text-sm text-uppercase text-secondary grid-item grid-start">{t('Last Purchase')}</span>
        <span
          className="text text-sm text-uppercase text-secondary grid-item grid-start"
          ref={($purchaseCountRef) => {
            if ($purchaseCountRef) {
              setWidth($purchaseCountRef.clientWidth - 6.69);
            }
          }}
        >
          {t('Purchases Count')}
        </span>
        <div></div>
        <span className="text text-sm text-uppercase text-secondary grid-item grid-start">
          {t('Spendings in the last 24 months')}
        </span>
      </ContainerXS>
      {stats &&
        stats.map((q: MatchmakingStat, index: number) => (
          <Fragment key={index}>
            <Bordered position="top" onClick={() => toggle(index)}>
              <ContainerXS className={`grid border-bottom ${!collapsed[index] ? 'uncollapsed' : ''}`}>
                {!collapsed[index] && <CaretUpOutlined className="toggle-icon" />}
                {collapsed[index] && <CaretDownOutlined className="toggle-icon" />}
                <span className="text text-md grid-item grid-start">{q.name}</span>
                <span className="grid-item grid-start w-60">
                  <ProgressBold percent={q.probability * 100} />
                </span>
                <span className="text text-md grid-item grid-start">
                  {q.lastPurchase && dateFormatted(q.lastPurchase!)}
                </span>
                <span className="text text-md text-uppercase grid-item" style={{ marginLeft: width / 2 }}>
                  {Boolean(q.purchaseCount) && q.purchaseCount}
                </span>
                <div></div>
                <span className="text text-md grid-item grid-start">
                  <ProgressDetailed
                    average={q.avgSpendIn24Months}
                    max={q.maxSpendIn24Months}
                    amount={q.spendIn24Months}
                  />
                </span>
              </ContainerXS>
            </Bordered>
            {!collapsed[index] && <PartsModalDetails stat={q} />}
          </Fragment>
        ))}
    </div>
  );
};

export default PartsModalTable;
