import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Branding/Table';

import './Aftersales.scss';

const Aftersales = ({ data }: any) => {
  const { t } = useTranslation();

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data.aftersales &&
        data.aftersales.map((item: any, i: number) => ({
          key: i,
          part: item.part_title,
          category: item.part_category,
        }))
      );
    }
  }, [data]);

  const columns = [
    {
      title: t('Part'),
      dataIndex: 'part',
      key: 'part',
    },
    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
    },
  ];

  return (
    <div className="AftersalesContainer">
      <div className="flex space-between text-sm">
        <span className="text-secondary  text-uppercase">{t('Aftersales')}</span>
        <span className="text-blue text-md">{t('Go to Aftersales')}</span>
      </div>
      <Table hasPagination={false} columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default Aftersales;
