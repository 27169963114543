import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'src/store';
import Bordered from 'src/components/Branding/Border';
import Button from 'src/components/Branding/Button';
import Table from 'src/components/Branding/Table';
import StepContainer from 'src/components/Campaigns/StepContainer';
import Select, { Option } from 'src/components/Branding/Select';
import Filters from 'src/shared/components/Campaigns/Filters';
import { dateFormatted, getDate } from 'src/shared/utils/date';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import ArrowDown from 'src/assets/images/arrow-down-icon.svg';
import ArrowUp from 'src/assets/images/arrow-up-icon.svg';

import './ContactList.scss';

const ContactList = ({ toBack, nextStep, selectedLang, selectCustomers, selectedCustomers, data }: any) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);

  useEffect(() => {
    const selectedKeys: any = [];
    selectedCustomers &&
      selectedCustomers.forEach((q: any) => {
        const index = dataSource && q && dataSource.findIndex((w: any) => w.key === q.key);
        selectedKeys.push(index);
      });
    setSelectedRowKeys(selectedKeys);
  }, [selectedCustomers, selectedLang]);

  const columns = [
    { title: t('Customer ID'), dataIndex: 'customerId', key: 'customerId' },
    { title: t('Customer Name'), dataIndex: 'customerName', key: 'customerName' },
    { title: t('Dealer'), dataIndex: 'dealer', key: 'dealer' },
    { title: t('Outlet'), dataIndex: 'outlet', key: 'outlet' },
    { title: t('Exp Maturity Date'), dataIndex: 'expMaturityDay', key: 'expMaturityDay' },
  ];

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data &&
        data.outlet[0].customer.map((e: any, i: any) => ({
          key: i,
          customerId: e.dm_customer_number,
          customerName: e.customer_name,
          dealer: data.dealer_title,
          outlet: data.outlet[0].outlet_title,
          expMaturityDay: dateFormatted(getDate(e.expected_maturity_date)!),
          currentSCAssigned: e.current_sales_consultant_assigned,
          autoSelected: !!e.contact_customer_yn,
          description: [
            { option: t('Finance Contract ID'), value: e.finance_contract_id },
            e.customer_car && {
              option: t('Vehicle Model Name'),
              value: e.customer_car && e.customer_car[0].car_model_name,
            },
            { option: t('Contract Age in months'), value: e.contract_age_months },
            e.vin && { option: t('Vehicle ID VIN'), value: e.vin },
            e.customer_car && {
              option: t('Contract End Date'),
              value: dateFormatted(getDate(e.customer_car && e.customer_car[0].expected_contract_end_date)!),
            },
          ],
        }))
      );
    }
  }, [data, selectedRowKeys, selectedLang]);

  useEffect(() => {
    if (dataSource && !selectedCustomers) {
      const selectedKeys = dataSource.filter((q: any) => q.autoSelected).map((q: any) => q.key);
      selectedKeys.push(...selectedKeys);
      const res = new Set(selectedKeys);
      setSelectedRowKeys(Array.from(res) as any);
    }
  }, []);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onSelect: (record: any, selected: any, selectedRowsComplete: any) => {
        const selectedRowKeysUpdated = [...selectedRowKeys];

        if (selected) {
          selectedRowKeysUpdated.push(dataSource && dataSource.findIndex((q: any) => q.key === record.key));
        } else {
          const index = dataSource && dataSource.findIndex((q: any) => q.key === record.key);
          selectedRowKeysUpdated.splice(index, 1);
        }
        setSelectedRowKeys(selectedRowKeysUpdated);
        selectCustomers!(selectedRowsComplete);
      },
      onSelectAll: (selected: boolean, selectedRowsComplete: any) => {
        if (selected) {
          const selectedKeys = selectedRowsComplete.map((q: any) => q.key);
          setSelectedRowKeys(selectedKeys);
          selectCustomers!(selectedRowsComplete);
        } else {
          setSelectedRowKeys([]);
          selectCustomers!(selectedRowsComplete);
        }
      },
    }),
    [selectedRowKeys],
  );

  const ExpandedRow = ({ description }: any) => (
    <div className="expandedRowContainer">
      <div className="expandedRow">
        {description &&
          description.map((item: any) => {
            return (
              item && (
                <Bordered position="bottom" className="expandedRowItem">
                  <span className="text-sm">{item.option}</span>
                  <span className="text-sm text-bold">{item.value}</span>
                </Bordered>
              )
            );
          })}
      </div>
      <Button disabled className="viewBtn">
        {t('View Full Profile')}
      </Button>
    </div>
  );

  return (
    <StepContainer className="contactList" hasBackButton back={toBack} nextStep={nextStep}>
      <Bordered className="TitleContainer">
        <Filters />
        <div className="filters">
          <div className="btnsContainer">
            <Button disabled className={showAll ? 'activeClass' : 'inActiveClass'} onClick={() => setShowAll(true)}>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Show All')}</span>
            </Button>
            <Button disabled className={showAll ? 'inActiveClass' : 'activeClass'} onClick={() => setShowAll(false)}>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Show Only Active')}</span>
            </Button>
          </div>
          <Select disabled placeholder={t('Actions')} bordered>
            <Option value="2">{t('Select All')}</Option>
            <Option value="3">{t('Select None')}</Option>
          </Select>
        </div>
      </Bordered>
      <Bordered className="tableContain">
        <Table
          columns={columns}
          expandable={{
            expandRowByClick: true,
            expandIcon: ({ expanded, onExpand, record }: any) =>
              expanded ? (
                <img src={ArrowUp} onClick={(e) => onExpand(record, e)} className="expandIcon" />
              ) : (
                <img src={ArrowDown} onClick={(e) => onExpand(record, e)} className="expandIcon" />
              ),
            expandedRowRender: (record: any) => <ExpandedRow description={record.description}></ExpandedRow>,
            rowExpandable: (record: any) => record.name !== 'Not Expandable',
          }}
          rowSelection={rowSelection}
          dataSource={dataSource}
          hasPagination
        />
      </Bordered>
    </StepContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
