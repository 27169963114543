import React from 'react';
import { PropsBase } from 'src/components/types';

import './TeleserviceItem.scss';

interface TeleserviceItemProps extends PropsBase {
  title: string;
  icon: string;
  disabledIcon: string;
  disabled: boolean;
}

const TeleserviceItem = ({ title, icon, disabledIcon, disabled }: TeleserviceItemProps) => {
  return (
    <div className={`teleservice-item${disabled ? ' disabled' : ''}`}>
      <img src={disabled ? disabledIcon : icon} />
      <span className="text text-blue text-sm">{title}</span>
    </div>
  );
};

export default TeleserviceItem;
