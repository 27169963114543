import React from 'react';
import { Tooltip } from 'antd';

import './AvgIndicator.scss';

interface AvgIndicatorProps {
  percent: number;
}

const AvgIndicator = ({ percent }: AvgIndicatorProps) => (
  <Tooltip title={`${Math.round(percent)}%`}>
    <div
      className="avg-indicator"
      style={{
        left: `${percent}%`,
      }}
    ></div>
  </Tooltip>
);

export default AvgIndicator;
