import React from 'react';
import Loading from 'src/components/Branding/Loading';

import './withLoading.scss';

const withLoading = (Component: any) => {
  return ({ isLoading, ...rest }: { isLoading: boolean } & any) => (
    <div className="loading-hoc pos-rel">
      {isLoading && (
        <>
          <div className="loading-container"></div>
          <Loading />
        </>
      )}
      <Component className={(isLoading ? 'component-disabled ' : '').concat(rest.className ?? '')} {...rest} />
    </div>
  );
};

export default withLoading;
