import React, { Fragment } from 'react';
import PrograssCardProbabilitiesTitle from './Title';
import ProgressCardProbabilityStats, { ProgressCardProbabilityStatsProps } from './ProbabilityStats';

export type ProbabilityStatsProps = ProgressCardProbabilityStatsProps;

const ProbabilityStats = (props: ProbabilityStatsProps) => (
  <Fragment>
    <PrograssCardProbabilitiesTitle />
    <ProgressCardProbabilityStats {...props} />
  </Fragment>
);

export default ProbabilityStats;
