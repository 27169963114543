import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Bordered from 'src/components/Branding/Border';
import Tag from 'src/components/Branding/Tag';
import Button from 'src/components/Branding/Button';
import { ContainerFULL } from 'src/components/Branding/Container';
import Padding from 'src/components/Branding/Spaceing/Padding';
import StepContainer from 'src/components/Campaigns/StepContainer';
import TargetSegmentsModal from './TargetSegmentsModal';

import './TargetSegments.scss';

const TargetSegments = ({ nextStep, appliedSegments, availableSegments, setAppliedSegments, selectedLang }: any) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <StepContainer nextStep={nextStep}>
        <Button className="btn btn-blue editBtn" onClick={() => setModalOpen(true)}>
          <span className="text text-sm text-bold text-white text-uppercase">{t('Edit Target Segments')}</span>
        </Button>
        {modalOpen && (
          <TargetSegmentsModal
            modalOpen={modalOpen}
            setModalOpen={() => setModalOpen(false)}
            availableSegments={availableSegments}
            appliedSegments={appliedSegments}
            setAppliedSegments={setAppliedSegments}
            selectedLang={selectedLang}
          />
        )}
        {appliedSegments &&
        appliedSegments &&
        appliedSegments.find((item: any) => item.target_segment_section.target_segment_item.length > 0) ? (
          <Bordered className="TSMenu">
            {appliedSegments.map(
              (q: any, index: number) =>
                q &&
                q.target_segment_section.target_segment_item.length > 0 && (
                  <>
                    <Bordered key={index} position="bottom" className="TStitles">
                      <span className="text-main text-bold text-sm">
                        {q!.target_segment_section.target_segment_section_title}
                      </span>
                    </Bordered>
                    <Bordered position="bottom" className="TScontents">
                      {q.target_segment_section.target_segment_item.map((item: any, index: number) => (
                        <Tag key={index}>
                          <span className="text-main text-sm">{item && item.target_segment_item_title}</span>
                        </Tag>
                      ))}
                    </Bordered>
                  </>
                ),
            )}
          </Bordered>
        ) : (
          <Bordered>
            <ContainerFULL>
              <Padding className="flex center">{t('No target segments selected yet')}</Padding>
            </ContainerFULL>
          </Bordered>
        )}
      </StepContainer>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TargetSegments);
