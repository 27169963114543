import React from 'react';
import { useTranslation } from 'react-i18next';

import Padding from 'src/components/Branding/Spaceing/Padding';
import Note from 'src/components/Branding/Note';
import { PropsBase } from 'src/components/types';

import './Title.scss';

export interface ProgressCardTitleProps extends PropsBase {
  title: string;
  series: string;
}

const ProgressCardTitle = ({ title, series }: ProgressCardTitleProps) => {
  const { t } = useTranslation();

  return (
    <Padding position="bottom" size="xs" className="flex flex-row vertical-center pg-card">
      <span className="mr-sm text-uppercase text-secondary text-sm">{title}</span>
      <Note
        title={t('The orange bar represents average sales value for among all customers', { model: series })}
      ></Note>
    </Padding>
  );
};

export default ProgressCardTitle;
