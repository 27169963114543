import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';

import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Modal from 'src/components/Branding/Modal';
import Bordered from 'src/components/Branding/Border';
import Button from 'src/components/Branding/Button';
import Input from 'src/components/Branding/Input';
import Select, { Option } from 'src/components/Branding/Select';
import Table from 'src/components/Branding/Table';
import Collapse, { Panel } from 'src/components/Branding/Collapse';
import StarFilledIcon from 'src/assets/images/star-filled-icon.svg';
import StarOutlinedIcon from 'src/assets/images/star-outlined-icon.svg';
import ArrowDown from 'src/assets/images/arrow-down-icon.svg';
import ArrowUp from 'src/assets/images/arrow-up-icon.svg';

import './TargetSegmentsModal.scss';

interface ExpandedItemProps {
  text: string;
  count?: number;
  isChecked?: boolean;
  disabled?: boolean;
  selectedLang?: any;
  onStarClick?: () => void;
  onTitleClick?: () => void;
}

const ExpandedItem = ({ text, count, isChecked, disabled, onStarClick, onTitleClick }: ExpandedItemProps) => {
  return (
    <div className={disabled ? 'disabled' : 'enable'} onClick={onTitleClick}>
      <Bordered position="bottom" className="expandIcon">
        <div className="flex align-center">
          {!disabled && isChecked ? (
            <img src={StarFilledIcon} className="starFilled" onClick={onStarClick} />
          ) : (
            <img src={StarOutlinedIcon} className="starOutlined" onClick={onStarClick} />
          )}
          <span>{text}</span>
        </div>
        <span className="counts">{count}</span>
      </Bordered>
    </div>
  );
};

const TargetSegmentsModal = ({
  setModalOpen,
  modalOpen,
  availableSegments,
  setAppliedSegments,
  appliedSegments,
  selectedLang,
}: any) => {
  const { t } = useTranslation();

  const [showAll, setShowAll] = useState(true);
  const [selectedTitle, setSelectedTitle] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [defaultOrFavorites, setDefaultOrFavorites] = useState<any>([]);

  useEffect(() => {
    setSelectedRows((appliedSegments && [...appliedSegments]) || []);
  }, [appliedSegments]);

  useEffect(() => {
    availableSegments && availableSegments.map((item: any) => (item.section = 'default'));
    setDefaultOrFavorites((availableSegments && [...availableSegments]) || []);
  }, [availableSegments, selectedLang]);

  useEffect(() => {
    const selectedKeys: any = [];
    selectedRows &&
      selectedRows[selectedTitle] &&
      selectedRows[selectedTitle].target_segment_section.target_segment_item.forEach((q: any) => {
        const index = availableSegments[selectedTitle].target_segment_item?.findIndex(
          (w: any) => w.target_segment_item_title === q.target_segment_item_title,
        );

        selectedKeys.push(index);
      });

    setSelectedRowKeys(selectedKeys);
  }, [appliedSegments, availableSegments, selectedTitle, selectedRows, selectedLang]);

  const columns = useMemo(() => {
    switch (selectedTitle) {
      case 0:
        return [
          {
            title: t('Brand'),
            dataIndex: 'target_segment_item_detail_brand',
          },
          {
            title: t('Model'),
            dataIndex: 'target_segment_item_detail_model',
          },
          {
            title: t('Power'),
            dataIndex: 'target_segment_item_detail_power',
          },
        ];
      case 1:
        return [
          {
            title: t('Brand'),
            dataIndex: 'target_segment_item_title',
          },
        ];
      case 2:
        return [
          {
            title: t('Financial products'),
            dataIndex: 'target_segment_item_title',
          },
        ];
      case 3:
        return [
          {
            title: t('Contract segment'),
            dataIndex: 'target_segment_item_title',
          },
        ];
      case 4:
        return [
          {
            title: t('Dealer / Outlet'),
            dataIndex: 'target_segment_item_title',
          },
        ];
      default:
        break;
    }
  }, [selectedLang, selectedTitle]);

  const dataSource = useMemo(() => {
    if (availableSegments) {
      return (
        availableSegments[selectedTitle] &&
        availableSegments[selectedTitle].target_segment_item &&
        availableSegments[selectedTitle].target_segment_item.map((e: any, i: any) => ({
          key: i,
          ...e,
        }))
      );
    }
  }, [availableSegments, selectedTitle, selectedLang]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onSelect: (record: any, selected: any, selectedRowsComplete: any) => {
        const selectedRowsUpdated = JSON.parse(JSON.stringify(selectedRows));
        const selectedRowKeysUpdated = JSON.parse(JSON.stringify(selectedRowKeys));
        if (selectedRowKeys.indexOf(record.key) >= 0) {
          selectedRowKeysUpdated.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
          selectedRowKeysUpdated.push(record.key);
        }
        const row = selectedRowsUpdated.find(
          (q: any) =>
            q.target_segment_section?.target_segment_section_title ===
            availableSegments[selectedTitle].target_segment_section_title,
        );

        if (!row) {
          selectedRowsUpdated.push({
            target_segment_section: {
              target_segment_section_title: availableSegments[selectedTitle].target_segment_section_title,
              target_segment_item: selectedRowsComplete,
            },
          });
        } else {
          row.target_segment_section.target_segment_item = selectedRowsComplete;
        }
        setSelectedRowKeys(selectedRowKeysUpdated);
        setSelectedRows(selectedRowsUpdated);
      },
      onSelectAll: (selected: boolean, selectedRowsComplete: any) => {
        const selectedRowsUpdated = JSON.parse(JSON.stringify(selectedRows));
        let selectedRowKeysUpdated = [];
        const selectedSegment = availableSegments[selectedTitle];
        const row = selectedRowsUpdated.find(
          (q: any) =>
            q &&
            q.target_segment_section?.target_segment_section_title === selectedSegment.target_segment_section_title,
        );
        if (!row) {
          selectedRowsUpdated.push({
            target_segment_section: {
              target_segment_section_title: selectedSegment.target_segment_section_title,
              target_segment_item: selectedRowsComplete,
            },
          });
        } else {
          row.target_segment_section.target_segment_item = selectedRowsComplete;
        }

        if (selected) {
          selectedRowKeysUpdated = selectedRowsComplete.map((q: any) => q && q.key);
        }

        setSelectedRowKeys(selectedRowKeysUpdated);
        setSelectedRows(selectedRowsUpdated);
      },
    }),
    [selectedRowKeys, selectedLang],
  );

  const TableMemo = useCallback(
    () => <Table rowSelection={{ ...rowSelection }} columns={columns} dataSource={dataSource} hasPagination={false} />,
    [rowSelection, dataSource, selectedTitle],
  );

  const otherSegments = [
    {
      target_segment_section_title: t('In retention'),
    },
    {
      target_segment_section_title: t('Sales amount'),
    },
    {
      target_segment_section_title: t('Last visit'),
    },
    {
      target_segment_section_title: t('Last call'),
    },
  ];

  const apply = () => {
    setAppliedSegments(selectedRows);
    setModalOpen(false);
  };

  const onStarClick = (index: any) => {
    const segments = [...defaultOrFavorites];
    segments[index].section == 'default'
      ? (segments[index].section = 'favorites')
      : (segments[index].section = 'default');
    setDefaultOrFavorites(segments);
  };
  
  return (
    <div className="modalContain">
      <Modal
        centered
        visible={modalOpen}
        title={t('Edit Target Segments')}
        onOk={setModalOpen}
        onCancel={setModalOpen}
        className="editModal"
        footer={null}
      >
        <Bordered position="top" className="modalContent">
          <div className="sidebar">
            <div className="filterContain">
              <Input placeholder={t('Filter')} prefix={<SearchOutlined />} className="input" disabled />
            </div>
            <Collapse
              expandIcon={({ isActive }: any) => <img src={isActive ? ArrowUp : ArrowDown} />}
              expandIconPosition="right"
              defaultActiveKey={['1', '2', '3']}
            >
              <Panel header={t('Default')} key="1">
                {defaultOrFavorites &&
                  defaultOrFavorites.map(
                    (item: any, index: number) =>
                      item.section == 'default' && (
                        <ExpandedItem
                          onTitleClick={() => setSelectedTitle(index)}
                          onStarClick={() => onStarClick(index)}
                          text={item.target_segment_section_title}
                          count={item.target_segment_item && item.target_segment_item.length}
                        />
                      ),
                  )}
              </Panel>
              <Panel header={t('Favorites')} key="2">
                {defaultOrFavorites &&
                  defaultOrFavorites.map(
                    (item: any, index: number) =>
                      item.section == 'favorites' && (
                        <ExpandedItem
                          isChecked
                          onTitleClick={() => setSelectedTitle(index)}
                          onStarClick={() => onStarClick(index)}
                          text={item.target_segment_section_title}
                          count={item.target_segment_item && item.target_segment_item.length}
                        />
                      ),
                  )}
              </Panel>
              <Panel disabled header={t('Other')} key="3">
                {otherSegments &&
                  otherSegments.map((i: any) => (
                    <ExpandedItem
                      disabled
                      text={i.target_segment_section_title}
                      count={i.target_segment_item && i.target_segment_item.length}
                    />
                  ))}
              </Panel>
            </Collapse>
          </div>
          <Bordered position="left" className="mainContent">
            <Bordered className="TitleContainer">
              <Input placeholder={t('Item')} prefix={<SearchOutlined />} className="input" disabled />
              <div className="filters">
                <div className="btnsContainer">
                  <Button
                    disabled
                    className={showAll ? 'activeClass' : 'inActiveClass'}
                    onClick={() => setShowAll(true)}
                  >
                    <span className="text text-sm text-bold text-main text-uppercase">{t('Show All')}</span>
                  </Button>
                  <Button
                    disabled
                    className={showAll ? 'inActiveClass' : 'activeClass'}
                    onClick={() => setShowAll(false)}
                  >
                    <span className="text text-sm text-bold text-main text-uppercase">{t('Show Only Active')}</span>
                  </Button>
                </div>
                <Select disabled placeholder={t('Actions')} bordered>
                  <Option value="2">{t('Select All')}</Option>
                  <Option value="3">{t('Select None')}</Option>
                </Select>
              </div>
            </Bordered>
            {dataSource && <TableMemo />}
          </Bordered>
        </Bordered>
        <Bordered position="top" className="footer">
          <Button className="btn btn-blue" onClick={apply}>
            <span className="text text-sm text-bold text-white text-uppercase">{t('Apply')}</span>
          </Button>
          <Button className="btn btn-white cancelBtn" onClick={setModalOpen}>
            <span className="text text-sm text-bold text-main text-uppercase">{t('Cancel')}</span>
          </Button>
        </Bordered>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TargetSegmentsModal);
