/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PropsBase } from 'src/components/types';
import Bordered from 'src/components/Branding/Border';
import { ContainerXS } from 'src/components/Branding/Container';
import ProgressBold from 'src/components/Branding/Progress/Bold';
import Button from 'src/components/Branding/Button';
import Padding from 'src/components/Branding/Spaceing/Padding';
import { dateFormatted } from 'src/shared/utils/date';

import './ProbabilityStats.scss';
import { OPEN_PARTS_MODAL } from 'src/store/customers/types';
import { connect } from 'react-redux';

interface Sale {
  saleDate: Date;
  amount: number;
}

export interface MatchmakingStat {
  name: string;
  probability: number;
  lastPurchase?: Date;
  purchaseCount: number;
  spendIn24Months: number;
  avgSpendIn24Months: number;
  maxSpendIn24Months: number;
  sales: Sale[];
  averageDaysTillNextPurchase: number;
  purchaseBars: number;
  avgDistancePerDay: number;
  averageDistanceTillNextPurchase: number;
  distanceBars: number;
}

export interface ProgressCardProbabilityStatsProps extends PropsBase {
  openModal?: Function;
  title: string;
  matchmakingStats: MatchmakingStat[];
}

const ProgressCardProbabilityStats = ({ openModal, title, matchmakingStats }: ProgressCardProbabilityStatsProps) => {
  const { t } = useTranslation();
  const sorted = matchmakingStats.sort((a, b) => b.probability - a.probability);

  const hasViewMore = matchmakingStats.length > 3;
  let topStats = null;
  let arr = matchmakingStats;

  if (hasViewMore) {
    arr = sorted.slice(0, 3);
  }

  topStats = arr.map(({ name, lastPurchase, probability }) => (
    <Bordered key={name} position="bottom" onClick={() => openModal!(title, name)}>
      <ContainerXS className="probability-card-stats">
        <span className="text text-md first">{name}</span>
        <span className="text text-md">{Boolean(lastPurchase) && dateFormatted(lastPurchase!)}</span>
        <div className="probability-card-stats__progress">
          <ProgressBold percent={probability * 100} />
        </div>
      </ContainerXS>
    </Bordered>
  ));

  return (
    <div>
      {topStats}
      {hasViewMore && (
        <Button className="w-100 p-0 m-0 border-top h-100 view-more" onClick={() => openModal!(title)}>
          <Padding size="xs" className="text text-blue text-md">
            {t('View more items', { count: matchmakingStats.length - 3 })}
          </Padding>
        </Button>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  openModal: (selectedBox: string, selectedPart?: string) =>
    dispatch({
      type: OPEN_PARTS_MODAL,
      payload: {
        selectedBox,
        selectedPart,
      },
    }),
});

export default connect(null, mapDispatchToProps)(ProgressCardProbabilityStats);
