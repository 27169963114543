import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import {
  SELECT_CONTRACT,
  SELECTED_CONTRACT,
  SET_STARS,
  TOGGLE_STAR,
  SELECT_CONTRACTS_INDEX,
} from 'src/store/contracts/types';
import { RootState } from 'src/store';
import { getCookie } from 'src/shared/utils/cookies';

import Contract, { ContractProps } from './Contract';
import CustomersContainer from 'src/pages/Customers/CustomersContracts/CustomersContainer';
import useContainerScroll from 'src/shared/hooks/containerScroll';

export interface ContractsProps {
  contracts: ContractProps[];
  selectedContract?: string | null;
  selectedContractsIndex?: number | null;
  isSelected?: boolean;
  stars?: any[];
  selectContract?: Function;
  selectContractsIndex?: Function;
  setStars?: Function;
  toggleStar?: Function;
}

const Contracts = ({
  contracts,
  selectedContract,
  selectedContractsIndex,
  isSelected,
  selectContract,
  selectContractsIndex,
  setStars,
  stars,
  toggleStar,
}: ContractsProps) => {
  const ref = useContainerScroll();

  useEffect(() => {
    if (!isSelected) {
      const savedContract = getCookie(SELECTED_CONTRACT);
      if (savedContract) {
        selectContract!(savedContract);
      }
    }
  }, [isSelected]);

  useEffect(() => {
    if (stars) {
      return;
    }

    const starMaps =
      contracts &&
      contracts.map((q: ContractProps) => ({
        number: q.number,
        star: q.star,
      }));
    if (starMaps) setStars!(starMaps);
  }, [selectedContract]);

  return (
    <CustomersContainer containerRef={ref} className="customers-list__container">
      {contracts &&
        contracts.map((q: ContractProps, index: number) => (
          <Contract
            key={q.number}
            {...q}
            stars={stars}
            toggleStar={toggleStar}
            selected={selectedContract === q.number}
            selectItem={() => selectContractsIndex!(index)}
            onSelect={selectContract!}
          />
        ))}
    </CustomersContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedContract: state.contracts.selectedContract,
  selectedContractsIndex: state.contracts.selectedContractsIndex,
  isSelected: state.contracts.isSelected,
  stars: state.contracts.stars,
});

const mapDispatchToProps = (dispatch: Function) => ({
  selectContract: (number: string) => dispatch({ type: SELECT_CONTRACT, payload: number }),
  selectContractsIndex: (index: number) => dispatch({ type: SELECT_CONTRACTS_INDEX, payload: index }),
  setStars: (stars: any[]) => dispatch({ type: SET_STARS, payload: { stars } }),
  toggleStar: (number: string) => dispatch({ type: TOGGLE_STAR, payload: { number } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
