import { Radio } from 'antd';
import React from 'react';
import { PropsBase } from 'src/components/types';

interface RadioProps extends PropsBase {
  value: string;
}

export const RadioGroup = ({ children, className, ...rest }: RadioProps) => (
  <Radio.Group className={className} {...rest}>
    {children}
  </Radio.Group>
);

export { default as RadioButton } from './RadioButton';
export default RadioGroup;
