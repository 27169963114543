import { LangState, CHANGE_LANGUAGE, LangActionTypes, SUBSCRIBE_CHANGE_LANGUAGE } from './types';
import { changeLocale } from 'src/shared/utils/i18n';

const initialState: LangState = {
  selectedLanguage: null,
  isSelected: false,
  subscribers: [],
};

export function langReducer(state = initialState, action: LangActionTypes): LangState {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      changeLocale(action.payload);
      return {
        ...state,
        selectedLanguage: action.payload,
        isSelected: true,
      };
    case SUBSCRIBE_CHANGE_LANGUAGE:
      return {
        ...state,
        subscribers: [...state.subscribers, action.payload],
      };
    default:
      return state;
  }
}
