import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Branding/Table';
import Status from 'src/components/Campaigns/Status';

import './Status.scss';

const StatusTable = ({ data }: any) => {
  const { t } = useTranslation();

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data.dealer &&
        data.dealer.map((item: any) => ({
          key: '1',
          dealer: item.dealer_title,
          outlet: item.outlet[0].outlet_title,
          status: [
            {
              status_id: item.outlet[0].campaign_status_id,
              status_title: item.outlet[0].campaign_status_title,
            },
          ],
          custumersOnList: item.outlet[0].campaign_cnt_customers_on_list ?? 0,
          testDriveCompleted: item.outlet[0].campaign_cnt_test_drive_completed ?? 0,
          completed: item.outlet[0].campaign_cnt_completed ?? 0,
          calledNoAnswer: item.outlet[0].campaign_cnt_called_no_answer ?? 0,
          calledNotInterested: item.outlet[0].campaign_cnt_called_not_interested ?? 0,
          doTo: item.outlet[0].campaign_cnt_todo ?? 0,
        }))
      );
    }
  }, [data]);

  const columns = [
    {
      title: t('Dealer'),
      dataIndex: 'dealer',
      key: 'dealer',
    },
    {
      title: t('Outlet'),
      dataIndex: 'outlet',
      key: 'outlet',
    },
    {
      title: t('Status'),
      key: 'status',
      dataIndex: 'status',
      render: (status: string[]) => {
        return status.map((q: string) => <Status id={(q as any).status_id} title={(q as any).status_title} />);
      },
    },
    {
      title: t('Custumers on List'),
      dataIndex: 'custumersOnList',
      key: 'custumersOnList',
    },
    {
      title: t('Completed'),
      dataIndex: 'completed',
      key: 'completed',
    },
    {
      title: t('Called No Answer'),
      dataIndex: 'calledNoAnswer',
      key: 'calledNoAnswer',
    },
    {
      title: t('Called Not Interested'),
      dataIndex: 'calledNotInterested',
      key: 'calledNotInterested',
    },
    {
      title: t('To Do'),
      dataIndex: 'doTo',
      key: 'doTo',
    },
  ];

  return (
    <div className="statusResultPart">
      <div className="statusComponent">
        <div className="flex space-between text-uppercase text-sm">
          <span className="text-secondary">{t('Status')}</span>
        </div>
        <Table hasPagination={false} columns={columns} dataSource={dataSource} />
      </div>
    </div>
  );
};

export default StatusTable;
