export interface PropsBase {
  className?: string;
  children?: React.ReactNode | string;
  [x: string]: any;
}

export type Sizes = 'xs' | 'sm' | 'md' | 'lg';
export type Positions = 'left' | 'right' | 'top' | 'bottom';
export const UPPER = 70;
export const LOWER = 30;
