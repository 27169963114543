import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RootState } from 'src/store';
import { addCampaign, editCampaign } from 'src/store/campaigns/actions';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import { setCampaignData } from 'src/store/campaigns/actions';

import Bordered from 'src/components/Branding/Border';
import SampleStep from 'src/components/Campaigns/SampleStep';
import { getDate, getDateWithObjFormat } from 'src/shared/utils/date';
import campaignService from 'src/shared/services/campaign.service';
import CampaignHeader from './CampaignHeader';
import TargetSegments from './TargetSegments';
import CampaignParameters from './CampaignParameters';
import ContactList from './ContactList';
import FinishStep from './FinishStep';

import './Campaign.scss';

interface CampaignsProps {
  setCampaignData?: Function;
  selectedLang: any;
  campaignData: any;
  campaignAdd: Function;
  campaignEdit: Function;
  subscribe?: Function;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Campaign = ({
  subscribe,
  selectedLang,
  campaignData,
  campaignAdd,
  campaignEdit,
  setCampaignData,
}: CampaignsProps) => {
  const [{ data }, fetchData] = campaignService.getCampaign();

  const { t } = useTranslation();
  const query = useQuery();

  const [active, setActive] = useState(1);

  const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  const [appliedSegments, setAppliedSegments] = useState<any>();
  const [campaignId, setCampaignId] = useState<any>();
  const [campaignTitle, setCampaignTitle] = useState<any>();
  const [campaignStartDate, setCampaignStartDate] = useState<any>();
  const [campaignEndDate, setCampaignEndDate] = useState<any>();
  const [followUpStartDate, setFollowUpStartDate] = useState<any>();
  const [dueDate, setDueDate] = useState<any>();
  const [memoToDealers, setMemoToDealers] = useState<any>();
  const [selectedCustomers, setSelectedCustomers] = useState<any>();
  const [customersNumber, setCustomersNumber] = useState<any>();

  useEffect(() => {
    if (data && !campaignData) {
      setCampaignData!(data);
    }
  }, [data, campaignData]);

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  useEffect(() => {
    const id = query.get('id');
    if (campaignData && id) {
      setSelectedCampaign(campaignData.campaign.find((q: any) => q.campaign_id === id));
    }
  }, [campaignData]);

  const emptySegments = campaignData
    ? campaignData.target_segments_available.map((item: any) => {
        return {
          target_segment_section: {
            target_segment_section_title: item.target_segment_section_title,
            target_segment_item: [],
          },
        };
      })
    : null;

  useEffect(() => {
    emptySegments && setAppliedSegments([...emptySegments]);
  }, []);

  useEffect(() => {
    if (isSelectedCmpNotEmpty()) {
      setAppliedSegments(selectedCampaign.target_segments_applied);
      setCampaignId(selectedCampaign.campaign_id);
      setCampaignTitle(selectedCampaign.description);
      setCampaignStartDate(
        selectedCampaign.campaign_period_nationwide_start_date &&
          getDate(selectedCampaign.campaign_period_nationwide_start_date),
      );
      setCampaignEndDate(
        selectedCampaign.campaign_period_nationwide_end_date &&
          getDate(selectedCampaign.campaign_period_nationwide_end_date),
      );
      setFollowUpStartDate(
        selectedCampaign.followup_period_start_date && getDate(selectedCampaign.followup_period_start_date),
      );
      setDueDate(selectedCampaign.followup_period_end_date && getDate(selectedCampaign.followup_period_end_date));
      setMemoToDealers(selectedCampaign.memo_to_dealers_from_group_japan);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    let value = 0;
    if (isSelectedCmpNotEmpty()) {
      value = selectedCampaign.number_of_customers || 0;
      if (selectedCustomers) {
        value += selectedCustomers.length;
      } else if (selectedCampaign) {
        const customer = selectedCampaign.dealer
          ? selectedCampaign.dealer[1].outlet[0].customer
          : campaignData.campaign[3].dealer[1].outlet[0].customer;
        value += customer ? customer.filter((e: any) => !!e.contact_customer_yn).length : 0;
      }
    } else {
      if (selectedCustomers) {
        value += selectedCustomers.length;
      } else if (campaignData) {
        const customer = campaignData.campaign[3].dealer[1].outlet[0].customer;
        value += customer.filter((e: any) => !!e.contact_customer_yn).length;
      }
    }
    setCustomersNumber(value);
  }, [selectedCampaign, selectedCustomers]);

  const isSelectedCmpNotEmpty = () => {
    return selectedCampaign && Object.keys(selectedCampaign).length > 0;
  };

  const nextStep = () => {
    setActive(active + 1);
  };

  const toBack = () => {
    setActive(active - 1);
  };

  const applySegments = (e: any) => {
    const selectedCampaignUpdated = { ...selectedCampaign };
    selectedCampaignUpdated.target_segments_applied = e;
    if (isSelectedCmpNotEmpty()) {
      setSelectedCampaign(selectedCampaignUpdated);
    }
    setAppliedSegments(e);
  };

  const availableSegments = useMemo(() => {
    return campaignData && campaignData.target_segments_available;
  }, [campaignData, selectedLang]);

  const callToDate = (value: any): any => {
    return value && value.toDate ? value.toDate() : value;
  };

  const deliverCampaign = () => {
    const id = query.get('id');

    const campaignStartDateNew = callToDate(campaignStartDate);
    const campaignEndDateNew = callToDate(campaignEndDate);
    const followUpStartDateNew = callToDate(followUpStartDate);
    const dueDateNew = callToDate(dueDate);

    if (isSelectedCmpNotEmpty() && selectedCampaign.dealer) {
      selectedCampaign.dealer[1].outlet[0].customer.map((c: any) => {
        let customer = null;
        if (selectedCustomers) {
          customer = selectedCustomers.find((sc: any) => sc.customerId == c.dm_customer_number);
        }
        c.contact_customer_yn = customer ? 1 : 0;
      });
    }

    const newCampaign = {
      status: campaignData.campaign.find((q: any) => q.status[0].status_id == 'draft').status,
      ...selectedCampaign,
      formerId: id ?? '',
      campaign_id: campaignId || 'new campaign',
      description: campaignTitle,
      number_of_customers: customersNumber,
      campaign_period_nationwide_start_date: getDateWithObjFormat(campaignStartDateNew),
      campaign_period_nationwide_end_date: getDateWithObjFormat(campaignEndDateNew),
      followup_period_start_date: getDateWithObjFormat(followUpStartDateNew),
      followup_period_end_date: getDateWithObjFormat(dueDateNew),
      memo_to_dealers_from_group_japan: memoToDealers,
      target_segments_applied: appliedSegments,
    };
    id ? campaignEdit(newCampaign) : campaignAdd(newCampaign);
  };

  const ActiveStep = () => {
    switch (active) {
      case 1:
        return (
          <TargetSegments
            nextStep={nextStep}
            appliedSegments={appliedSegments}
            availableSegments={availableSegments}
            setAppliedSegments={applySegments}
            selectedLang={selectedLang}
          />
        );
      case 2:
        return (
          <CampaignParameters
            toBack={toBack}
            nextStep={() => nextStep()}
            campaignId={campaignId}
            setCampaignId={(e: any) => setCampaignId(e)}
            campaignTitle={campaignTitle}
            setCampaignTitle={(e: any) => setCampaignTitle(e)}
            campaignStartDate={campaignStartDate}
            setCampaignStartDate={(e: any) => setCampaignStartDate(e)}
            endDate={campaignEndDate}
            setEndDate={(e: any) => setCampaignEndDate(e)}
            followUpStartDate={followUpStartDate}
            setFollowUpStartDate={(e: any) => setFollowUpStartDate(e)}
            dueDate={dueDate}
            setDueDate={(e: any) => setDueDate(e)}
            memoToDealers={memoToDealers}
            selectedLang={selectedLang}
            setMemoToDealers={(e: any) => setMemoToDealers(e)}
          />
        );
      case 3:
        return (
          <ContactList
            toBack={toBack}
            nextStep={() => nextStep()}
            selectCustomers={setSelectedCustomers}
            selectedCustomers={selectedCustomers}
            selectedLang={selectedLang}
            data={campaignData && (selectedCampaign.dealer ? selectedCampaign : campaignData.campaign[3]).dealer[1]}
          />
        );
      case 4:
        return (
          <FinishStep
            appliedSegments={appliedSegments}
            campaignId={campaignId}
            selectedLang={selectedLang}
            campaignTitle={campaignTitle}
            campaignStartDate={campaignStartDate}
            endDate={campaignEndDate}
            followUpStartDate={followUpStartDate}
            dueDate={dueDate}
            memoToDealers={memoToDealers}
            current={customersNumber}
            total={
              campaignData &&
              campaignData.campaign.reduce((sum: number, current: any) => sum + current.number_of_customers, 0)
            }
            deliverCampaign={deliverCampaign}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <CampaignHeader
        title={selectedCampaign.description ?? 'New Campaign'}
        current={customersNumber}
        total={
          campaignData &&
          campaignData.campaign.reduce((sum: number, current: any) => sum + current.number_of_customers, 0)
        }
        campaignStartDate={campaignStartDate}
        campaignEndDate={campaignEndDate}
      />
      <Bordered position="bottom" className="stepsContainer">
        <SampleStep number={1} text={t('Target Segments')} onClick={() => setActive(1)} active={active == 1} />
        <div className="dash" />
        <SampleStep number={2} text={t('Campaign Parameters')} onClick={() => setActive(2)} active={active == 2} />
        <div className="dash" />
        <div className="countContainer">
          <div className="count">{customersNumber}</div>
          <SampleStep
            numberStyle="thirdStepNumber"
            number={3}
            text={t('Contact List')}
            onClick={() => setActive(3)}
            active={active == 3}
          />
        </div>
        <div className="dash" />
        <SampleStep
          numberStyle="fourthStepNumber"
          number={4}
          text={t('Finish')}
          onClick={() => setActive(4)}
          active={active == 4}
        />
      </Bordered>
      <div className="content-overflow">{ActiveStep()}</div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
  campaignData: state.campaign.data,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
  campaignAdd: (data: object) => dispatch(addCampaign(data)),
  campaignEdit: (data: object) => dispatch(editCampaign(data)),
  setCampaignData: (data: object) => dispatch(setCampaignData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
