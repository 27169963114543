import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerFULL } from 'src/components/Branding/Container';
import GreyCircle from 'src/components/Branding/GreyCircle';
import Padding from 'src/components/Branding/Spaceing/Padding';

import UserIcon from './Assets/user-icon-blue.svg';
import './NoCustomerSelected.scss';

const NoCustomerSelected = () => {
  const { t } = useTranslation();

  return (
    <ContainerFULL className="flex flex-row center vertical-center no-customer-selected">
      <div className="flex flex-column vertical-center center">
        <Padding position="bottom" size="md" className="flex flex-row center">
          <GreyCircle>
            <img src={UserIcon} className="placeholder-icon" />
          </GreyCircle>
        </Padding>
        <p className="text text-lg text-center text-bold">{t('No customer selected')}</p>
        <p className="text text-sm text-secondary text-center">
          {t('Please select any customer from the list on the left')}
        </p>
      </div>
    </ContainerFULL>
  );
};

export default NoCustomerSelected;
