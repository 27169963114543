import React, { useMemo } from 'react';
import { PropsBase } from 'src/components/types';

export type BorderPosition = 'left' | 'right' | 'top' | 'bottom';

interface BorderProps extends PropsBase {
  rounded?: boolean;
  position?: BorderPosition;
}

const Bordered = ({ children, rounded, position, className, ...rest }: BorderProps) => {
  const classNameActual = useMemo(() => {
    let classNameActual = 'border-default';
    if (rounded) {
      classNameActual = classNameActual.concat(' border-rounded');
    }
    if (position) {
      classNameActual = classNameActual.concat(` border-${position}`);
    }

    return classNameActual;
  }, [rounded, position]);

  return (
    <div className={`${classNameActual} ${className ?? ''}`} {...rest}>
      {children}
    </div>
  );
};

export default Bordered;
