import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'src/store';
import Modal from 'src/components/Branding/Modal';
import { CLOSE_PARTS_MODAL } from 'src/store/customers/types';
import { ContainerMD } from 'src/components/Branding/Container';
import Bordered from 'src/components/Branding/Border';
import { MatchmakingStat } from '../VehicleDetails/ProgressCard/Probabilities/ProbabilityStats';
import Padding from 'src/components/Branding/Spaceing/Padding';
import { UPPER } from 'src/components/types';

import PartsModalTable from './PartsModalTable';
import PartsModalTitle from './PartsModalTitle';
import { ProgressCardProps } from '../VehicleDetails/ProgressCard';

import './PartsModal.scss';

interface PartsModalProps {
  stats: ProgressCardProps[];
  isOpen?: boolean;
  closeModal?: Function;
  selectedBox?: string;
  selectedPart?: string;
}

const PartsModal = ({ stats, isOpen, closeModal, selectedBox, selectedPart }: PartsModalProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const selectedBoxIndex = stats.findIndex((q) => q.title === selectedBox);
    setSelected(selectedBoxIndex === -1 ? 0 : selectedBoxIndex);
  }, [selectedBox]);

  const closeModalWrapper = useCallback(() => {
    closeModal!();
    setSelected(0);
  }, []);

  return (
    <Modal
      destroyOnClose
      className="parts-modal"
      visible={isOpen}
      title={<PartsModalTitle selected={selected} setSelected={setSelected} titles={stats.map((q) => q.title)} />}
      onOk={() => closeModalWrapper()}
      onCancel={() => closeModalWrapper()}
      footer={null}
    >
      <ContainerMD>
        <Padding position="bottom" size="md">
          <p className="mr-sm text-uppercase text-secondary text-sm">{t('Recommended')}</p>
          <Bordered rounded>
            <PartsModalTable
              selectedPart={selectedPart}
              stats={stats[selected].matchmakingStats.filter((q: MatchmakingStat) => q.probability * 100 >= UPPER)}
            />
          </Bordered>
        </Padding>

        <p className="mr-sm text-uppercase text-secondary text-sm">{t('Other')}</p>
        <Bordered rounded>
          <PartsModalTable
            selectedPart={selectedPart}
            stats={stats[selected].matchmakingStats.filter((q: MatchmakingStat) => q.probability * 100 <= UPPER)}
          />
        </Bordered>
      </ContainerMD>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: state.customers.partsModalOpen,
  selectedBox: state.customers.selectedBox,
  selectedPart: state.customers.selectedPart,
});

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => dispatch({ type: CLOSE_PARTS_MODAL }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartsModal);
