import React from 'react';
import { Collapse as DefaultCollapse } from 'antd';

import { PropsBase } from 'src/components/types';

export const { Panel } = DefaultCollapse;

const Collapse = ({ children, ...rest }: PropsBase) => <DefaultCollapse {...rest}>{children}</DefaultCollapse>;

export default Collapse;
