import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  SELECT_CUSTOMER,
  SELECTED_CUSTOMER,
  SET_STARS,
  TOGGLE_STAR,
  SELECT_CUSTOMERS_INDEX,
} from 'src/store/customers/types';
import { RootState } from 'src/store';
import { getCookie } from 'src/shared/utils/cookies';
import Customer, { CustomerProps } from './Customer';
import CustomersContainer from '../../CustomersContainer';
import useContainerScroll from 'src/shared/hooks/containerScroll';

import './Customers.scss';

export interface CustomersProps {
  customers: CustomerProps[];
  selectedCustomer?: string | null;
  selectedCustomersIndex?: number | null;
  isSelected?: boolean;
  stars?: any[];
  selectCustomer?: Function;
  selectCustomersIndex?: Function;
  setStars?: Function;
  toggleStar?: Function;
}

const Customers = ({
  customers,
  selectedCustomer,
  selectedCustomersIndex,
  isSelected,
  selectCustomer,
  selectCustomersIndex,
  setStars,
  stars,
  toggleStar,
}: CustomersProps) => {
  const ref = useContainerScroll();


  useEffect(() => {
    if (!isSelected) {
      const savedCustomer = getCookie(SELECTED_CUSTOMER);
      if (savedCustomer) {
        selectCustomer!(savedCustomer);
      }
    }
  }, [isSelected]);

  useEffect(() => {
    if (stars) {
      return;
    }

    const starMaps = customers.map((q: CustomerProps) => ({
      number: q.number,
      star: q.star,
    }));

    setStars!(starMaps);
  }, [selectedCustomer]);

  return (
    <CustomersContainer containerRef={ref} className="customers-list__container">
      {customers &&
        customers.map((q: CustomerProps, index: number) => {
          return (
            <Customer
              key={`${q.number}_${index}`}
              {...q}
              stars={stars}
              toggleStar={toggleStar}
              selected={selectedCustomer === q.number}
              selectItem={() => selectCustomersIndex!(index)}
              onSelect={selectCustomer!}
            />
          );
        })}
    </CustomersContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedCustomer: state.customers.selectedCustomer,
  selectedCustomersIndex: state.customers.selectedCustomersIndex,
  isSelected: state.customers.isSelected,
  stars: state.customers.stars,
});

const mapDispatchToProps = (dispatch: Function) => ({
  selectCustomer: (number: string) => dispatch({ type: SELECT_CUSTOMER, payload: number }),
  selectCustomersIndex: (index: number) => dispatch({ type: SELECT_CUSTOMERS_INDEX, payload: index }),
  setStars: (stars: any[]) => dispatch({ type: SET_STARS, payload: { stars } }),
  toggleStar: (number: string) => dispatch({ type: TOGGLE_STAR, payload: { number } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
