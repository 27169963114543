import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Loading from 'src/components/Branding/Loading';
import { getDate, addDaysFromNow, dateFormatted } from 'src/shared/utils/date';
import { RootState } from 'src/store';
import { SELECT_CONTRACT } from 'src/store/contracts/types';
import { SUBSCRIBE_CHANGE_LANGUAGE, LanguageSubscription } from 'src/store/lang/types';
import CampaignDetailsHeader from './CampaignDetailsHeader';
import withLoading from 'src/shared/hocs/withLoading';
import campaignService from 'src/shared/services/campaign.service';
import Bordered from 'src/components/Branding/Border';
import { ContainerFULL } from 'src/components/Branding/Container';
import CustomersContracts from './CustomersContracts';
import NoContractSelected from './CustomersContracts/NoContractSelected';
import Sections from './Sections';
import Overview from './Overview';
import { PropsBase } from 'src/components/types';

import './CampaignDetails.scss';

interface CampaignDetailsProps {
  selectedContract: string | null;
  selectedLang: string | null;
  isSelected?: boolean;
  subscribe?: Function;
  selectContract?: Function;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CampaignDetails = ({
  selectedContract,
  selectedLang,
  isSelected,
  subscribe,
  selectContract,
}: CampaignDetailsProps) => {
  const { t } = useTranslation();
  const [{ data, isLoading }, fetchData] = campaignService.getCampaign();
  const query = useQuery();

  const [activePage, setActivePage] = useState('contact list');

  const [selectedLanguage, setSelectedLanguage] = useState(selectedLang);
  const [selectedCampaign, setSelectedCampaign] = useState({});

  useEffect(() => {
    setSelectedLanguage(selectedLang);
  }, [selectedLang]);

  useEffect(() => {
    const id = query.get('id');
    if (data && id) {
      setSelectedCampaign((data as any).campaign.find((q: any) => q.campaign_id === id));
    }
  }, [data]);

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  const outlet = (selectedCampaign as any).dealer && (selectedCampaign as any).dealer[1].outlet[0];
  const outletData = useMemo(() => outlet?.customer.find((q: any) => q.dm_customer_number === selectedContract), [
    selectedContract,
    outlet?.outlet_title,
  ]);

  useEffect(() => {
    if (data && outletData === undefined) {
      selectContract!();
    }
  }, [selectedContract]);

  const calculateTotalTradeIn = useCallback((q: any) => {
    let total = 0;

    if (!q.customer_car) return 0;

    for (const car of q.customer_car) {
      if (car.tradein) {
        total += car.tradein;
      }
    }
    return total;
  }, []);

  const contractsData = useMemo(
    () => ({
      contracts: outlet?.customer.map((q: any) => ({
        name: q.customer_name,
        number: q.dm_customer_number,
        star: Boolean(q.customer_star_yn),
        lastCall: addDaysFromNow(-q.days_since_last_call),
        lastVisit: addDaysFromNow(-q.days_since_last_visit),
        totalContracts: q.total_contracts_cnt,
        contract: q.contract,
        financeRemainingBalance: q.finance_remaining_balance,
        tradeIn: calculateTotalTradeIn(q),
        financeEndOfTerm: q.finance_end_of_term ? dateFormatted(getDate(q.finance_end_of_term)!) : 'N/A',
      })),
      current: outlet?.customer.length,
      total: outlet?.customer.length + 10,
    }),
    [outlet?.outlet_title],
  );

  if (!data) {
    return <Loading />;
  }
  const Component = withLoading(({ className }: PropsBase) => (
    <div className="detailsContainer">
      <CampaignDetailsHeader
        title={(selectedCampaign as any) ? (selectedCampaign as any).description : ''}
        current={selectedCampaign ? (selectedCampaign as any).number_of_customers || 0 : 0}
        total={data && data.campaign.reduce((sum: number, current: any) => sum + current.number_of_customers, 0)}
        campaignStartDate={(selectedCampaign as any) && (selectedCampaign as any).campaign_period_nationwide_start_date}
        campaignEndDate={(selectedCampaign as any) && (selectedCampaign as any).campaign_period_nationwide_end_date}
      />
      <Bordered position="bottom" className="menuBorder">
        <ContainerFULL className="menuSteps text-secondary text-sm">
          <div
            onClick={() => setActivePage('contact list')}
            className={activePage === 'contact list' ? 'activePage' : ''}
          >
            {t('Contact List')}
          </div>
          <div onClick={() => setActivePage('overview')} className={activePage === 'overview' ? 'activePage' : ''}>
            {t('Overview')}
          </div>
        </ContainerFULL>
      </Bordered>
      {activePage === 'contact list' ? (
        <div className={(className ? `${className} ` : '').concat('customers')}>
          {contractsData && (
            <CustomersContracts
              contracts={contractsData?.contracts}
              current={contractsData?.current}
              total={contractsData?.total}
            />
          )}
          {isSelected && outletData && (
            <Sections
              dealer={(selectedCampaign as any).dealer[1]}
              data={outletData}
              selectedLang={selectedLanguage}
              isSelected={isSelected}
            />
          )}
          {!isSelected && <NoContractSelected />}
        </div>
      ) : (
        <Overview
          data={selectedCampaign}
          dealer={(selectedCampaign as any).dealer[1]}
          selectedLang={selectedLanguage!}
        />
      )}
    </div>
  ));

  return <Component isLoading={isLoading} />;
};

const mapStateToProps = (state: RootState) => ({
  selectedContract: state.contracts.selectedContract,
  isSelected: state.contracts.isSelected,
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  selectContract: () => dispatch({ type: SELECT_CONTRACT }),
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
