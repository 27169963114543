import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Table from 'src/components/Branding/Table';
import StepContainer from 'src/components/Campaigns/StepContainer';
import Bordered from 'src/components/Branding/Border';
import Button from 'src/components/Branding/Button';
import Select, { Option } from 'src/components/Branding/Select';
import Filters from 'src/shared/components/Campaigns/Filters';

import './ReassignOrphans.scss';

const ReassignOrphans = ({
  toBack,
  nextStep,
  selectedLang,
  data,
  selectCustomers,
  selectedCustomers,
  salesConsultants,
}: any) => {
  const { t } = useTranslation();

  const [showAll, setshowAll] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);

  useEffect(() => {
    const selectedKeys: any = [];
    selectedCustomers &&
      selectedCustomers.forEach((q: any) => {
        const index = dataSource && q && dataSource.findIndex((w: any) => w.key === q.key);

        selectedKeys.push(index);
      });

    setSelectedRowKeys(selectedKeys);
  }, [selectedCustomers, selectedLang]);

  const columns = [
    { title: t('Customer ID'), dataIndex: 'customerId', key: 'customerId' },
    { title: t('Customer Name'), dataIndex: 'customerName', key: 'customerName' },
    { title: t('Dealer'), dataIndex: 'dealer', key: 'dealer' },
    { title: t('Outlet'), dataIndex: 'outlet', key: 'outlet' },
    { title: t('Current SC Assigned'), dataIndex: 'currentSCAssigned', key: 'currentSCAssigned' },
    {
      title: t('Select New SC'),
      dataIndex: 'selectNewSC',
      key: 'selectNewSC',
      render: () => {
        return (
          <Select className="selectNewSC" placeholder={t('Select')} bordered>
            {salesConsultants.map((item: any, index: number) => (
              <Option value={index}>{item.sales_consultant_name}</Option>
            ))}
          </Select>
        );
      },
    },
  ];

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data &&
        data[1].outlet[0].customer.map((e: any, i: any) => ({
          key: i,
          customerId: e.dm_customer_number,
          customerName: e.customer_name,
          dealer: data[1].dealer_title,
          outlet: data[1].outlet[0].outlet_title,
          currentSCAssigned: e.current_sales_consultant_assigned,
          autoSelected: !!e.contact_customer_yn,
        }))
      );
    }
  }, [selectedLang, data, selectedRowKeys]);

  useEffect(() => {
    if (!selectedCustomers && dataSource) {
      const selectedKeys = dataSource.filter((q: any) => q.autoSelected).map((q: any) => q.key);
      selectedKeys.push(...selectedKeys);
      const res = new Set(selectedKeys);
      setSelectedRowKeys(Array.from(res) as any);
    }
  }, []);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onSelect: (record: any, selected: any, selectedRowsComplete: any) => {
        const selectedRowKeysUpdated = [...selectedRowKeys];

        if (selected) {
          selectedRowKeysUpdated.push(dataSource && dataSource.findIndex((q: any) => q.key === record.key));
        } else {
          const index = dataSource && dataSource.findIndex((q: any) => q.key === record.key);
          selectedRowKeysUpdated.splice(index, 1);
        }
        setSelectedRowKeys(selectedRowKeysUpdated);
        selectCustomers!(selectedRowsComplete);
      },
      onSelectAll: (selected: boolean, selectedRowsComplete: any) => {
        if (selected) {
          const selectedKeys = selectedRowsComplete.map((q: any) => q.key);
          setSelectedRowKeys(selectedKeys);
          selectCustomers!(selectedRowsComplete);
        } else {
          setSelectedRowKeys([]);
          selectCustomers!(selectedRowsComplete);
        }
      },
    }),
    [selectedRowKeys],
  );

  return (
    <StepContainer hasBackButton back={toBack} nextStep={nextStep}>
      <Bordered className="TitleContainer">
        <Filters />
        <div className="filters">
          <div className="btnsContainer">
            <Button disabled className={showAll ? 'activeClass' : 'inActiveClass'} onClick={() => setshowAll(true)}>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Show All')}</span>
            </Button>
            <Button disabled className={showAll ? 'inActiveClass' : 'activeClass'} onClick={() => setshowAll(false)}>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Show Only Active')}</span>
            </Button>
          </div>
          <Select disabled placeholder={t('Actions')} bordered>
            <Option value="2">{t('Select All')}</Option>
            <Option value="3">{t('Select None')}</Option>
          </Select>
        </div>
      </Bordered>
      <Bordered className="tableContain">
        <Table columns={columns} rowSelection={rowSelection} dataSource={dataSource} hasPagination />
      </Bordered>
    </StepContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReassignOrphans);
