import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'src/store';
import Bordered from 'src/components/Branding/Border';
import Table from 'src/components/Branding/Table';
import Status from 'src/components/Campaigns/Status';
import { getDate, formatFromMonth } from 'src/shared/utils/date';
import ProgressBold from 'src/components/Branding/Progress/Bold';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import ArrowDown from 'src/assets/images/arrow-down-icon.svg';
import ArrowUp from 'src/assets/images/arrow-up-icon.svg';
import { formatNumber, getCurrency } from 'src/shared/utils/formatters';

import './index.scss';

interface FinancingProps {
  selectedLanguage: any;
  financingPageData: any;
}

const Financing = ({ selectedLanguage, financingPageData }: FinancingProps) => {
  const { t } = useTranslation();

  const columns = [
    { title: t('Vehicle'), dataIndex: 'vehicle', key: 'vehicle' },
    { title: t('Age'), dataIndex: 'age', key: 'age' },
    { title: t('Rem Balance'), dataIndex: 'balance', key: 'balance' },
    { title: t('Trade-In Ref Price'), dataIndex: 'tradeInPrice', key: 'tradeInPrice' },
    { title: t('Retention'), dataIndex: 'retention', key: 'retention' },
    { title: t('Maturity Date'), dataIndex: 'maturityDate', key: 'maturityDate' },
    {
      title: t('Status'),
      key: 'status',
      dataIndex: 'status',
      render: (status: string[]) => {
        return status.map((q: string) => <Status id={(q as any).status_id} title={(q as any).status_title} />);
      },
    },
  ];

  const dataSource = useMemo(() => {
    if (financingPageData) {
      return financingPageData.map((e: any, i: any) => ({
        key: i,
        vehicle: e.car_model_name,
        age: e.car_age_months + ' ' + t('Months'),
        balance: e.remaining_balance ? (
          getCurrency() + formatNumber(e.remaining_balance)
        ) : (
          <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
        ),
        tradeInPrice: e.tradein ? (
          getCurrency() + formatNumber(e.tradein)
        ) : (
          <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
        ),
        retention: Math.round(e.retention_probability * 100) + '%',
        maturityDate: formatFromMonth(getDate(e.expected_contract_end_date)!),
        status: [{ status_id: e.contract_status[0].status_id, status_title: e.contract_status[0].status_title }],
        description: [
          { title: t('Vehicle'), option: t('Vin'), value: e.vin, key: 'vin' },
          {
            option: t('Vehicle Age'),
            value: formatFromMonth(getDate(e.vehicle_age_date)!),
            key: 'vehicle age',
          },
          {
            option: t('Model'),
            value: e.car_model_name,
            key: 'model',
          },
        ],
        descriptionDetails: [
          {
            title: t('Finance Details'),
            option: t('Contract Number'),
            value: e.contract_number,
            key: 'contract model',
          },
          {
            option: t('Maturity Date'),
            value: formatFromMonth(getDate(e.expected_contract_end_date)!),
            key: 'maturity date',
          },
          {
            option: t('Remaining Balance'),
            value: e.remaining_balance ? (
              getCurrency() + formatNumber(e.remaining_balance)
            ) : (
              <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
            ),
            key: 'remaining balance',
          },
          {
            option: t('Trade-In Ref Price'),
            value: e.tradein ? (
              getCurrency() + formatNumber(e.tradein)
            ) : (
              <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
            ),
            key: 'tradein',
          },
          {
            option: t('Monthly Payment'),
            value: e.monthly_payment ? (
              getCurrency() + formatNumber(e.monthly_payment)
            ) : (
              <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
            ),
            key: 'monthly payment',
          },
          {
            option: t('Bonus Amount'),
            value: getCurrency() + formatNumber(e.contract_bonus_amount),
            key: 'bonus amount',
          },
          {
            option: t('Equity'),
            value: e.contract_equity ? (
              getCurrency() + formatNumber(e.contract_equity)
            ) : (
              <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
            ),
            key: 'equity',
          },
        ],
        descriptionMileage: [
          {
            title: t('Mileage'),
            option: t('Mileage Predicted Overage'),
            value: formatNumber(e.mileage_predicted_overage),
            key: 'mileage',
          },
          {
            option: t('Last Visit Mileage'),
            value: formatNumber(e.last_visit_mileage),
            key: 'last visit mileage',
          },
          {
            option: t('Current Est Mileage'),
            value: formatNumber(e.current_est_mileage),
            key: 'current mileage',
          },
          {
            option: t('Contracted Mileage'),
            value: formatNumber(e.contracted_mileage),
            key: 'contracted mileage',
          },
          {
            option: t('Average Annual Mileage'),
            value: formatNumber(e.average_annual_mileage),
            key: 'average mileage',
          },
        ],
        descriptionRetention: [
          {
            title: t('Retention'),
            option: t('Estimated Retention Rate'),
            value: Math.round(e.retention_probability * 100),
            key: 'retention',
          },
        ],
      }));
    }
  }, [financingPageData, selectedLanguage]);

  const expandedRow = (description: any) => {
    return (
      <div className="expandedRowContainerFinance">
        <div className="expandedRowFinance">
          {description &&
            description.map((item: any) => {
              return (
                <div>
                  <span className="text">{item.title}</span>
                  <Bordered position="bottom" className="expandedRowItem">
                    <span className="text-sm-opacity">{item.option}</span>
                    {item.key === 'mileage' ? (
                      <span
                        className={parseInt(item.value) < 0 ? 'text-sm-mileage-negative' : 'text-sm-mileage-positive'}
                      >
                        {item.value}
                      </span>
                    ) : (
                      <span className="text-sm">{item.value}</span>
                    )}
                  </Bordered>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const expandedRowRetention = (retention: any) => {
    return (
      <div className="expandedRowContainerFinance">
        <div className="expandedRowFinance">
          {retention &&
            retention.map((item: any) => {
              return (
                <div>
                  <span className="text">{item.title}</span>
                  <Bordered position="bottom" className="expandedRowItem retention">
                    <span className="text-sm-opacity">{item.option}</span>
                    <div className="progressBar">
                      <ProgressBold className="progressPercentBar" percent={item.value} percentGrapher={true} />
                    </div>
                  </Bordered>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="tableContainer">
      <span className="tableTitle">{t('Contracts')}</span>
      <Bordered className="tableContain">
        <Table
          columns={columns}
          expandable={{
            expandRowByClick: true,
            expandIcon: ({ expanded, onExpand, record }: any) => (
              <img
                src={expanded ? ArrowUp : ArrowDown}
                onClick={(key) => onExpand(record, key)}
                className="expandIcon"
              />
            ),
            expandedRowRender: (record: any) => (
              <div className="expandRowContainer">
                {expandedRow(record.description)}
                {expandedRow(record.descriptionDetails)}
                {expandedRow(record.descriptionMileage)}
                {expandedRowRetention(record.descriptionRetention)}
              </div>
            ),
            rowExpandable: (record: any) => record.name !== 'Not Expandable',
          }}
          dataSource={dataSource}
          hasPagination={true}
        />
      </Bordered>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Financing);
