import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';

import { setCampaignData } from 'src/store/campaigns/actions';
import Bordered from 'src/components/Branding/Border';
import SampleStep from 'src/components/Campaigns/SampleStep';
import campaignService from 'src/shared/services/campaign.service';
import CampaignHeader from './CampaignHeader';
import RemoveDuplicates from './RemoveDuplicates';
import ReassignOrphans from './ReassignOrphans';
import RemoveSelfRegistrations from './RemoveSelfRegistrations';
import { editCampaign } from 'src/store/campaigns/actions';
import { getDate, getDateWithObjFormat } from 'src/shared/utils/date';
import ContactList from './ContactList';
import Release from './Release';
import Finish from './Finish';

import './Campaign.scss';

interface CampaignsProps {
  setCampaignData?: Function;
  selectedLang: any;
  campaignData: any;
  campaignEdit: Function;
  subscribe?: Function;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Campaign = ({ selectedLang, campaignData, campaignEdit, setCampaignData, subscribe }: CampaignsProps) => {
  const [{ data }, fetchData] = campaignService.getCampaign();
  
  const { t } = useTranslation();
  const query = useQuery();

  const [active, setActive] = useState(1);
  const [selectedCampaign, setSelectedCampaign] = useState<any>({});

  const [campaignStartDate, setCampaignStartDate] = useState();
  const [campaignEndDate, setCampaignEndDate] = useState();
  const [followUpStartDate, setFollowUpStartDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [memoToDealers, setMemoToDealers] = useState();
  const [memoToSalesTeam, setMemoToSalesTeam] = useState();

  const [selectedCustomers, setSelectedCustomers] = useState<any>();
  const [customersNumber, setCustomersNumber] = useState<number>();

  const [fTime, setFTime] = useState(true);

  useEffect(() => {
    if (data && !campaignData) {
      setCampaignData!(data);
    }
  }, [data]);

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  useEffect(() => {
    const id = query.get('id');
    if (data && id) {
      setSelectedCampaign((data as any).campaign.find((q: any) => q.campaign_id === id));
    }
  }, [data]);

  useEffect(() => {
    let selectedCstmrs: any = [];
    if (selectedCampaign.dealer && fTime) {
      selectedCampaign.dealer[1].outlet[0].customer.map((item: any, index: number) => {
        if (item.contact_customer_yn) {
          selectedCstmrs.push({ key: index, customerId: item.dm_customer_number });
        }
      });
      setFTime(false);
      setSelectedCustomers(selectedCstmrs);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaign !== undefined) {
      setCampaignStartDate(getDate(selectedCampaign.campaign_period_nationwide_start_date) as any);
      setCampaignEndDate(getDate(selectedCampaign.campaign_period_nationwide_end_date) as any);
      setFollowUpStartDate(getDate(selectedCampaign.followup_period_start_date) as any);
      setDueDate(getDate(selectedCampaign.followup_period_end_date) as any);
      setMemoToDealers(selectedCampaign.memo_to_dealers_from_group_japan);
      setMemoToSalesTeam(selectedCampaign.memo_to_sales_team);
    }
  }, [selectedCampaign, selectedLang]);

  useEffect(() => {
    let value = 0;
    if (isSelectedCmpNotEmpty()) {
      value = selectedCampaign.number_of_customers || 0;
      if (selectedCustomers) {
        value += selectedCustomers.length;
      } else if (campaignData) {
        const customer = selectedCampaign.dealer[1].outlet[0].customer;
        value += customer.filter((e: any) => !!e.contact_customer_yn).length;
      }
    } else {
      if (selectedCustomers) {
        value += selectedCustomers.length;
      } else if (campaignData) {
        const customer = campaignData.campaign[0].dealer[1].outlet[0].customer;
        value += customer.filter((e: any) => !!e.contact_customer_yn).length;
      }
    }
    setCustomersNumber(value);
  }, [selectedCampaign, selectedCustomers]);

  const isSelectedCmpNotEmpty = () => {
    return selectedCampaign && Object.keys(selectedCampaign).length > 0;
  };

  const nextStep = () => {
    setActive(active + 1);
  };

  const toBack = () => {
    setActive(active - 1);
  };

  const callToDate = (value: any): any => {
    return value && value.toDate ? value.toDate() : value;
  };

  const deliverCampaign = () => {
    const id = query.get('id');

    const campaignStartDateNew = callToDate(campaignStartDate);
    const campaignEndDateNew = callToDate(campaignEndDate);
    const followUpStartDateNew = callToDate(followUpStartDate);
    const dueDateNew = callToDate(dueDate);

    if (isSelectedCmpNotEmpty()) {
      selectedCampaign.dealer[1].outlet[0].customer.map((c: any) => {
        let customer = null;
        if (selectedCustomers) {
          customer = selectedCustomers.find((sc: any) => sc.customerId == c.dm_customer_number);
        }
        c.contact_customer_yn = customer ? 1 : 0;
      });
    }

    const newCampaign = {
      ...selectedCampaign,
      formerId: id,
      number_of_customers: customersNumber,
      campaign_period_nationwide_start_date: getDateWithObjFormat(campaignStartDateNew),
      campaign_period_nationwide_end_date: getDateWithObjFormat(campaignEndDateNew),
      followup_period_start_date: getDateWithObjFormat(followUpStartDateNew),
      followup_period_end_date: getDateWithObjFormat(dueDateNew),
      memo_to_dealers_from_group_japan: memoToDealers,
      memo_to_sales_team: setMemoToSalesTeam,
    };
    campaignEdit(newCampaign);
  };

  const ActiveStep = () => {
    switch (active) {
      case 1:
        return (
          <RemoveDuplicates
            nextStep={nextStep}
            data={selectedCampaign.dealer}
            selectCustomers={setSelectedCustomers}
            selectedCustomers={selectedCustomers}
          />
        );
      case 2:
        return (
          <RemoveSelfRegistrations
            toBack={toBack}
            nextStep={nextStep}
            data={selectedCampaign.dealer}
            selectCustomers={setSelectedCustomers}
            selectedCustomers={selectedCustomers}
          />
        );
      case 3:
        return (
          <ReassignOrphans
            toBack={toBack}
            nextStep={nextStep}
            data={selectedCampaign.dealer}
            selectCustomers={setSelectedCustomers}
            selectedCustomers={selectedCustomers}
            salesConsultants={campaignData.sales_consultant}
          />
        );
      case 4:
        return (
          <ContactList
            toBack={toBack}
            nextStep={nextStep}
            data={selectedCampaign.dealer}
            selectCustomers={setSelectedCustomers}
            selectedCustomers={selectedCustomers}
          />
        );
      case 5:
        return (
          <Release
            toBack={toBack}
            nextStep={nextStep}
            followUpStartDate={followUpStartDate}
            setFollowUpStartDate={setFollowUpStartDate}
            dueDate={dueDate}
            setDueDate={setDueDate}
            campaignStartDate={campaignStartDate}
            endDate={campaignEndDate}
            memoToDealers={memoToDealers}
            memoToSalesTeam={memoToSalesTeam}
            setMemoToSalesTeam={setMemoToSalesTeam}
          />
        );
      case 6:
        return (
          <Finish
            selectedCampaign={selectedCampaign}
            campaignId={selectedCampaign && selectedCampaign.campaign_id}
            campaignTitle={selectedCampaign && selectedCampaign.description}
            campaignStartDate={campaignStartDate}
            endDate={campaignEndDate}
            followUpStartDate={followUpStartDate}
            dueDate={dueDate}
            memoToDealers={memoToDealers}
            memoToSalesTeam={memoToSalesTeam}
            current={customersNumber}
            total={
              campaignData &&
              campaignData.campaign.reduce((sum: number, current: any) => sum + current.number_of_customers, 0)
            }
            deliverCampaign={deliverCampaign}
          />
        );
    }
  };

  return (
    <>
      <CampaignHeader title={selectedCampaign ? (selectedCampaign as any).description : 'New Campaign'} />
      <Bordered position="bottom" className="stepsContainer">
        <SampleStep number={1} text={t('Remove Duplicates')} onClick={() => setActive(1)} active={active == 1} />
        <div className="dash" />
        <SampleStep
          number={2}
          text={t('Remove Self-Registrations')}
          onClick={() => setActive(2)}
          active={active == 2}
        />
        <div className="dash" />
        <SampleStep number={3} text={t('Reassign Orphans')} onClick={() => setActive(3)} active={active == 3} />
        <div className="dash" />
        <div className="countContainer">
          <div className="count">{customersNumber}</div>
          <SampleStep number={4} text={t('Contact List')} onClick={() => setActive(4)} active={active == 4} />
        </div>
        <div className="dash" />
        <SampleStep number={5} text={t('Release')} onClick={() => setActive(5)} active={active == 5} />
        <div className="dash" />
        <SampleStep number={6} text={t('Finish')} onClick={() => setActive(6)} active={active == 6} />
      </Bordered>
      {ActiveStep()}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
  campaignData: state.campaign.data,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
  campaignEdit: (data: object) => dispatch(editCampaign(data)),
  setCampaignData: (data: object) => dispatch(setCampaignData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
