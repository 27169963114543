import React, { useMemo } from 'react';
import { PropsBase } from 'src/components/types';
import Bordered from 'src/components/Branding/Border';
import { ContainerXS } from 'src/components/Branding/Container';

import './Box.scss';

const Box = ({ children, className }: PropsBase) => {
  const classNameActual = useMemo(() => className ?? '', [className]);

  return (
    <Bordered className="w-fit box">
      <ContainerXS className={`h-100 w-100 ${classNameActual}`}>{children}</ContainerXS>
    </Bordered>
  );
};

export default Box;
