import { SET_CAMPAIGN_DATA, CampaignsActionTypes, ADD_CAMPAIGN,EDIT_CAMPAIGN } from './types';

export const setCampaignData = (data: object | null): CampaignsActionTypes => ({
  type: SET_CAMPAIGN_DATA,
  payload: data,
});

export const addCampaign = (data: object | null): CampaignsActionTypes => ({
  type: ADD_CAMPAIGN,
  payload: data,
});

export const editCampaign = (data: object | null): CampaignsActionTypes => ({
  type: EDIT_CAMPAIGN,
  payload: data,
});
