import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Bordered from 'src/components/Branding/Border';
import Button from 'src/components/Branding/Button';
import { PropsBase } from 'src/components/types';

import './StepContainer.scss';

interface StepContainerProps extends PropsBase {
  hasBackButton?: boolean;
  back?: Function;
  nextStep: Function;
}

const StepContainer = ({ hasBackButton, children, back, nextStep, className }: StepContainerProps) => {
  const { t } = useTranslation();

  return (
    <Bordered className={`${className} fullContainer`}>
      <Bordered position="bottom" className="TSContainer">
        {children}
      </Bordered>
      <div className="footerContainer">
        {hasBackButton && (
          <Button className="btn btn-white backBtn" onClick={() => back!()}>
            <span className="text text-sm text-bold text-main text-uppercase">{t('Back')}</span>
          </Button>
        )}
        <Button className="btn btn-blue continueBtn" onClick={() => nextStep!()}>
          <span className="text text-sm text-bold text-white text-uppercase">{t('Continue')}</span>
        </Button>
      </div>
    </Bordered>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer);
