import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { authReducer } from './auth/reducers';
import { customersReducer } from './customers/reducers';
import { contractsReducer } from './contracts/reducers';
import { langReducer } from './lang/reducers';
import { campaignReducer } from './campaigns/reducers';

export const rootReducer = combineReducers({
  auth: authReducer,
  contracts: contractsReducer,
  customers: customersReducer,
  lang: langReducer,
  campaign: campaignReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export default store;
