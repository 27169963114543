import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Branding/Table';
import { dateFormatted } from 'src/shared/utils/date';
import { formatNumber, getCurrency } from 'src/shared/utils/formatters';
import Teleservice from './Teleservices';

import './Vehicles.scss';

const Vehicles = ({ data }: any) => {
  const { t } = useTranslation();

  const dataSource = useMemo(() => {
    if (data) {
      return (
        data.customer_car &&
        data.customer_car.map((item: any, i: number) => ({
          key: i,
          vehicle: item.car_model_name,
          teleservice: <Teleservice teleservices={item.teleservices} />,
          payment: item.payment_type,
          isContract: Boolean(item.isContract),
          age: item.car_age_months,
          remBalance: item.remaining_balance ? (
            getCurrency() + formatNumber(item.remaining_balance)
          ) : (
            <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
          ),
          tradeInRefPrice: item.tradein ? (
            getCurrency() + formatNumber(item.tradein)
          ) : (
            <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
          ),
          financeEndOfTerm: dateFormatted(data.finance_end_of_term) ?? (
            <div dangerouslySetInnerHTML={{ __html: '&ndash;&ndash;' }} />
          ),
        }))
      );
    }
  }, [data]);

  const columns = [
    {
      title: t('Vehicle'),
      dataIndex: 'vehicle',
      key: 'vehicle',
    },
    {
      title: t('Teleservice'),
      dataIndex: 'teleservice',
      key: 'teleservice',
    },
    {
      title: t('Payment'),
      dataIndex: 'payment',
      key: 'payment',
    },
    {
      title: t('Age'),
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: t('Rem Balance'),
      dataIndex: 'remBalance',
      key: 'remBalance',
    },
    {
      title: t('Trade-In Ref Price'),
      dataIndex: 'tradeInRefPrice',
      key: 'tradeInRefPrice',
    },

    {
      title: t('Finance End Of Term'),
      dataIndex: 'financeEndOfTerm',
      key: 'financeEndOfTerm',
    },
  ];

  return (
    <div className="VehicleContainer">
      <div className="flex space-between text-sm">
        <span className="text-secondary  text-uppercase">{t('Vehicles')}</span>
        <span className="text-blue text-md">{t('Go to Vehicles')}</span>
      </div>
      <Table
        rowClassName={(record: any) => {
          return record.isContract && 'paymentContract';
        }}
        hasPagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default Vehicles;
