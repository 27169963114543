import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment, { LocaleSpecification } from 'moment';
import 'moment/locale/ja';
import 'moment/locale/en-gb';

import en from './resources/translations.en.json';
import jp from './resources/translations.jp.json';

/**
 * Helper methods for localization in local storage.
 */
export const I18N_TOKEN_KEY = 'i18n-current';

export const getStoredI18nToken = () => localStorage.getItem(I18N_TOKEN_KEY);

const mapLocaleToMoment: any = {
  en: 'en-gb',
  jp: 'ja',
};

moment.locale('en-gb', <LocaleSpecification>{
  longDateFormat: {
    LL: 'MMM DD, YYYY',
  },
});

export const storeI18nToken = (token: string) => {
  localStorage.setItem(I18N_TOKEN_KEY, token);
};

export const changeLocale = async (token: string) => {
  await moment.locale(mapLocaleToMoment[token]);
  await i18n.changeLanguage(token);
};

const DEFAULT__LANG = 'en';

const storedToken = getStoredI18nToken() ?? DEFAULT__LANG;

// I18n initialization
i18n.use(initReactI18next).init({
  resources: {
    en,
    jp,
  },
  lng: storedToken,
  fallbackLng: DEFAULT__LANG,
});

storeI18nToken(storedToken);
