import React from 'react';

import ts2LSRActive from './Assets/ts-2lsr-active.png';
import tsACallActive from './Assets/ts-a-call-active.png';
import tsAscActive from './Assets/ts-asc-active.png';
import tsBCallActive from './Assets/ts-b-call-active.png';
import tsMscActive from './Assets/ts-msc-active.png';
import tsSpmActive from './Assets/ts-spm-active.png';
import tsTsbgActive from './Assets/ts-tsbg-active.png';
import tsTsrActive from './Assets/ts-tsr-active.png';

interface TeleserviceProps {
  teleservices: any;
}

const Teleservice = ({ teleservices }: TeleserviceProps) => {
  const iconsMemoized = [
    { title: 'asc', icon: tsAscActive },
    { title: 'msc', icon: tsMscActive },
    { title: 'tsbg', icon: tsTsbgActive },
    { title: 'spm', icon: tsSpmActive },
    { title: 'a_call', icon: tsACallActive },
    { title: 'b_call', icon: tsBCallActive },
    { title: 'tsr', icon: tsTsrActive },
    { title: '2slr', icon: ts2LSRActive },
  ];

  return (
    <>
      {teleservices &&
        Object.keys(teleservices).map((key: string) => {
          if (teleservices[key] == 1) {
            const foundedItem = iconsMemoized.find((i: any) => i.title == key);
            return <img src={(foundedItem! as any).icon} />;
          }
        })}
    </>
  );
};

export default Teleservice;
