import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { RootState } from 'src/store';
import { SELECT_CONTRACT } from 'src/store/contracts/types';
import { SUBSCRIBE_CHANGE_LANGUAGE, LanguageSubscription } from 'src/store/lang/types';
import Loading from 'src/components/Branding/Loading';
import withLoading from 'src/shared/hocs/withLoading';
import campaignService from 'src/shared/services/campaign.service';
import CampaignDetailsHeader from './CampaignDetailsHeader';
import Overview from './Overview';

import { PropsBase } from 'src/components/types';

interface CampaignDetailsProps {
  selectedContract: string | null;
  selectedLang: string | null;
  isSelected?: boolean;
  subscribe?: Function;
  selectContract?: Function;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CampaignDetails = ({ subscribe, selectedLang }: CampaignDetailsProps) => {
  const { t } = useTranslation();
  const [{ data, isLoading }, fetchData] = campaignService.getCampaign();
  const query = useQuery();

  const [selectedCampaign, setSelectedCampaign] = useState({});

  useEffect(() => {
    const id = query.get('id');
    if (data && id) {
      setSelectedCampaign((data as any).campaign.find((q: any) => q.campaign_id === id));
    }
  }, [data]);

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  if (!data) {
    return <Loading />;
  }

  const Component = withLoading(({ className }: PropsBase) => (
    <div className="detailsContainer">
      <CampaignDetailsHeader
        title={(selectedCampaign as any) ? (selectedCampaign as any).description : ''}
        current={selectedCampaign ? (selectedCampaign as any).number_of_customers || 0 : 0}
        total={data && data.campaign.reduce((sum: number, current: any) => sum + current.number_of_customers, 0)}
        campaignStartDate={(selectedCampaign as any) && (selectedCampaign as any).campaign_period_nationwide_start_date}
        campaignEndDate={(selectedCampaign as any) && (selectedCampaign as any).campaign_period_nationwide_end_date}
      />
      {selectedCampaign && <Overview data={selectedCampaign} selectedLang={selectedLang} />}
    </div>
  ));

  return <Component isLoading={isLoading} />;
};

const mapStateToProps = (state: RootState) => ({
  selectedContract: state.contracts.selectedContract,
  isSelected: state.contracts.isSelected,
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  selectContract: () => dispatch({ type: SELECT_CONTRACT }),
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
