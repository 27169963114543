import React, { useMemo } from 'react';

import Progress from '..';
import { LOWER, UPPER } from 'src/components/types';

import './Bold.scss';

interface ProgressBoldProps {
  percent: number;
  percentGrapher?: any;
  className?: any;
}

const ProgressBold = ({ percent, percentGrapher, className }: ProgressBoldProps) => {
  const lvl = useMemo(() => {
    if (percent <= LOWER) {
      return 'low';
    }

    if (percent <= UPPER) {
      return 'medium';
    }

    return '';
  }, [percent]);

  if (percentGrapher) {
    return (
      <>
        <Progress percent={percent} className={`progress-bar-bold ${lvl} ${className}`} />
        <span>{percent + '%'}</span>
      </>
    );
  }
  return <Progress percent={percent} className={`progress-bar-bold ${lvl} ${className}`} />;
};

export default ProgressBold;
