import React from 'react';
import { useTranslation } from 'react-i18next';
import Bordered from 'src/components/Branding/Border';
import { getDate, getTimePeriod } from 'src/shared/utils/date';

import './CampaignDetails.scss';

const CampaignDetails = ({ data }: any) => {
  const { t } = useTranslation();

  return (
    <div className="campaignDetailsContainer">
      <div className="flex space-between text-sm">
        <span className="text-secondary  text-uppercase">{t('Campaign Details')}</span>
      </div>

      <Bordered className="campaignDetailsContent text-md">
        <div>
          <span className="key text-secondary">{t('Follow-up Period')}:</span>
          <span className="text-main">
            {data.default_followup_period_start_date && (
              <p
                dangerouslySetInnerHTML={{
                  __html: getTimePeriod(
                    getDate(data.default_followup_period_start_date)!,
                    getDate(data.default_followup_period_end_date)!,
                  ),
                }}
              />
            )}
          </span>
        </div>
        <div>
          <span className="key text-secondary">{t('Memo to Sales Team')}:</span>
          <span className="text-main">
            <p
              className="text-main"
              dangerouslySetInnerHTML={{
                __html: data.memo_to_sales_team,
              }}
            />
          </span>
        </div>
        <div>
          <span className="key text-secondary">{t('Number of Customers')}:</span>
          <span className="text-main">{data.number_of_customers}</span>
        </div>
      </Bordered>
    </div>
  );
};

export default CampaignDetails;
