import React from 'react';

import { PropsBase } from 'src/components/types';

import './GreyCircle.scss';

const GreyCircle = ({ children, className }: PropsBase) => (
  <div className={`grey-circle ${className ?? ''}`}>{children}</div>
);

export default GreyCircle;
