import React from 'react';
import { TFunction } from 'i18next';
import Status from './Status';

/**
 * Campaigns table configuration.
 * All the columns are included in this array.
 */
const tableColumns = {
  campaignId: {
    title: 'Campaign Id',
    dataIndex: 'campaignId',
    key: 'campaignId',
  },
  description: {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  numberOfCustomers: {
    title: 'No of customers',
    dataIndex: 'numberOfCustomers',
    key: 'numberOfCustomers',
  },
  campaignPeriod: {
    title: 'Campaign Period',
    dataIndex: 'campaignPeriod',
    key: 'campaignPeriod',
    render: (period: string) => {
      return <div dangerouslySetInnerHTML={{ __html: period }} />;
    },
  },
  status: {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status: string[]) => {
      return (
        status &&
        status.map((q: string) => <Status id={q && (q as any).status_id} title={q && (q as any).status_title} />)
      );
    },
  },
};

/**
 * Campaign column names. Type created to avoid misspelling during column fetch.
 */
export type CAMPAIGN_COLUMNS = 'campaignId' | 'description' | 'numberOfCustomers' | 'campaignPeriod' | 'status';

/**
 * Utility to fetch specific columns from tableColumns by keys.
 * @param keys Keys to fetch.
 * @param translator Translator used to translate column title.
 */
export const getCampaignColumns = (keys: CAMPAIGN_COLUMNS[], translator: TFunction) => {
  const columns = tableColumns as any;

  return keys.map((q: CAMPAIGN_COLUMNS) => ({
    title: translator(columns[q].title),
    key: columns[q].key,
    dataIndex: columns[q].dataIndex,
    render: columns[q].render,
  }));
};
