import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StarFilled, StarOutlined } from '@ant-design/icons';

import { ContainerSM } from 'src/components/Branding/Container';
import Padding from 'src/components/Branding/Spaceing/Padding';
import { fromNowString, dateFormatted } from 'src/shared/utils/date';
import { currencyShort } from 'src/shared/utils/formatters';
import { CustomerStar } from 'src/store/customers/types';

import InfoRow from './InfoRow';

import './Customer.scss';

export interface CustomerProps {
  selected: boolean;
  name: string;
  number: string;
  star: boolean;
  lastCall: Date;
  lastVisit: Date;
  totalVehicles: number;
  totalRevenue: number;
  visits: number;
  nextShaken: Date;
  onSelect: Function;
  stars?: CustomerStar[];
  toggleStar?: Function;
  selectItem? : any
}

const Customer = ({
  selected,
  name,
  number,
  lastCall,
  lastVisit,
  totalVehicles,
  totalRevenue,
  visits,
  nextShaken,
  onSelect,
  stars,
  toggleStar,
  selectItem,
}: CustomerProps) => {
  const { t } = useTranslation();

  const checked = useMemo(() => stars?.find((q: any) => q.number === number)?.star, [stars]);
  
  const onCardClick = () => {
    onSelect(number);
    selectItem();
  };

  return (
    <div className="customers__customer" onClick={onCardClick}>
      {selected && <div className="customers__customer--active-indicator"></div>}
      <ContainerSM>
        <div className="customers__customer-star">
          {checked ? (
            <StarFilled className="star" onClick={() => toggleStar!(number)} />
          ) : (
            <StarOutlined className="star" onClick={() => toggleStar!(number)} />
          )}
        </div>
        <div className="customers__customer__info">
          <Padding position="bottom" size="sm" className="customers__customer__info-main">
            <p className="text text-lg text-uppercase mb-xs text-bold">{name}</p>
            <span className="text text-md">{number}</span>
          </Padding>

          <div className="customers__customer__info__rows">
            <InfoRow name={t('Last Call')} value={fromNowString(lastCall)} />
            <InfoRow name={t('Last Visit')} value={fromNowString(lastVisit)} />
            <InfoRow name={t('Total Vehicles')} value={totalVehicles} />
            <InfoRow name={t('Total Revenue (Last 2 Years)')} value={currencyShort(totalRevenue)} />
            <InfoRow name={t('Visits')} value={visits} />
            <InfoRow name={t('Next Shaken')} value={dateFormatted(nextShaken)} />
          </div>
        </div>
      </ContainerSM>
    </div>
  );
};

export default Customer;
