import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Branding/Table';

import './Results.scss';

const Results = ({ data }: any) => {
  const { t } = useTranslation();

  const dataSource = useMemo(
    () => [
      {
        key: '1',
        testDriveScheduled: data.campaign_cnt_test_drive_scheduled ?? 0,
        testDriveCompleted: data.campaign_cnt_test_drive_completed ?? 0,
        appraisalOffered: data.campaign_cnt_appraisal_offered_not_interested ?? 0,
        appraisalScheduled: data.campaign_cnt_appraisa_scheduled ?? 0,
        quoteDelivered: data.campaign_cnt_quote_delivered ?? 0,
        purchaseLikelihood: data.campaign_cnt_purchase_likelihood ?? 0,
        vehicleOrdered: data.campaign_cnt_vehicle_ordered ?? 0,
        vehicleRegistered: data.campaign_cnt_vehicle_registered ?? 0,
        vehicleDelivered: data.campaign_cnt_vehicle_delivered ?? 0,
      },
    ],
    [data],
  );

  const columns = [
    {
      title: t('Test Drive Scheduled'),
      dataIndex: 'testDriveScheduled',
      key: 'testDriveScheduled',
    },
    {
      title: t('Test Drive Completed'),
      dataIndex: 'testDriveCompleted',
      key: 'testDriveCompleted',
    },
    {
      title: t('Appraisal Offered, Not Interested'),
      dataIndex: 'appraisalOffered',
      key: 'appraisalOffered',
    },
    {
      title: t('Appraisal Scheduled'),
      dataIndex: 'appraisalScheduled',
      key: 'appraisalScheduled',
    },
    {
      title: t('Quote Delivered'),
      dataIndex: 'quoteDelivered',
      key: 'quoteDelivered',
    },
    {
      title: t('Purchase Likelihood'),
      dataIndex: 'purchaseLikelihood',
      key: 'address',
    },

    {
      title: t('Vehicle Ordered'),
      dataIndex: 'vehicleOrdered',
      key: 'vehicleOrdered',
    },
    {
      title: t('Vehicle Registered'),
      dataIndex: 'vehicleRegistered',
      key: 'vehicleRegistered',
    },
    {
      title: t('Vehicle Delivered'),
      dataIndex: 'vehicleDelivered',
      key: 'vehicleDelivered',
    },
  ];

  return (
    <div className="resultsContainer">
      <div className="flex space-between text-uppercase text-sm">
        <span className="text-secondary">{t('Results')}</span>
      </div>
      <Table hasPagination={false} columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default Results;
