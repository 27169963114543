import { CampaignsState, CampaignsActionTypes, SET_CAMPAIGN_DATA, ADD_CAMPAIGN, EDIT_CAMPAIGN } from './types';

const initialState: CampaignsState = {
  data: null,
};

export function campaignReducer(state: CampaignsState = initialState, action: CampaignsActionTypes): CampaignsState {
  switch (action.type) {
    case SET_CAMPAIGN_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_CAMPAIGN:
      const newState = state as any;
      return {
        ...newState,
        data: {
          ...newState.data,
          campaign: [action.payload, ...newState.data.campaign],
        },
      };
    case EDIT_CAMPAIGN:
      const editedState = state && (state as any);
      const i = editedState.data.campaign.findIndex(
        (item: any) => item.campaign_id == (action.payload as any).formerId,
      );
      editedState.data.campaign[i] = action.payload;
      return {
        ...editedState,
      };
    default:
      return state;
  }
}
