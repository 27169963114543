import React, { useMemo } from 'react';
import { PropsBase } from 'src/components/types';

type ContainerProps = PropsBase;

const ContainerHoc = (size: string, { containerRef, ...rest }: ContainerProps) => {
  const classNameActual = useMemo(() => rest.className ?? '', [rest.className]);

  return (
    <div ref={containerRef} className={`container-${size} ${classNameActual}`} id={rest.id ? rest.id : null}>
      {rest.children}
    </div>
  );
};

export const ContainerFULL = (props: ContainerProps) => ContainerHoc('full', props);

export const ContainerXS = (props: ContainerProps) => ContainerHoc('xs', props);

export const ContainerSM = (props: ContainerProps) => ContainerHoc('sm', props);

export const ContainerMD = (props: ContainerProps) => ContainerHoc('md', props);

export const ContainerLG = (props: ContainerProps) => ContainerHoc('lg', props);
