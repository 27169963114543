import React, { Fragment } from 'react';
import Details from 'src/pages/Customers/CustomersContracts/Sections/Details';
import Contracts, { ContractsProps } from './Contracts';
import Header from './Header';
import Tags from './Tags';

export type SectionsProps = {
  current: number;
  total: number;
} & ContractsProps;

const Sections = ({ current, total, ...rest }: SectionsProps) => (
  <Fragment>
    <Header />
    <Details current={current} total={total} />
    <Tags />
    <Contracts {...rest} />
  </Fragment>
);

export default Sections;
