import React from 'react';

import { ContainerXS } from 'src/components/Branding/Container';
import Bordered from 'src/components/Branding/Border';

import ts2LSR from './Assets/ts-2lsr.png';
import tsACall from './Assets/ts-a-call.png';
import tsAsc from './Assets/ts-asc.png';
import tsBCall from './Assets/ts-b-call.png';
import tsMsc from './Assets/ts-msc.png';
import tsSpm from './Assets/ts-spm.png';
import tsTsbg from './Assets/ts-tsbg.png';
import tsTsr from './Assets/ts-tsr.png';
import ts2LSRActive from './Assets/ts-2lsr-active.png';
import tsACallActive from './Assets/ts-a-call-active.png';
import tsAscActive from './Assets/ts-asc-active.png';
import tsBCallActive from './Assets/ts-b-call-active.png';
import tsMscActive from './Assets/ts-msc-active.png';
import tsSpmActive from './Assets/ts-spm-active.png';
import tsTsbgActive from './Assets/ts-tsbg-active.png';
import tsTsrActive from './Assets/ts-tsr-active.png';

import TeleserviceItem from './TeleserviceItem/TeleserviceItem';

interface TeleserviceProps {
  teleservices: any;
}

const Teleservice = ({ teleservices }: TeleserviceProps) => {
  const iconsMemoized = [
    { title: 'ASC', icon: tsAscActive, disabledIcon: tsAsc, disabled: !Boolean(teleservices.asc) },
    { title: 'MSC', icon: tsMscActive, disabledIcon: tsMsc, disabled: !Boolean(teleservices.msc) },
    { title: 'TSBG', icon: tsTsbgActive, disabledIcon: tsTsbg, disabled: !Boolean(teleservices.tsbg) },
    { title: 'SPM', icon: tsSpmActive, disabledIcon: tsSpm, disabled: !Boolean(teleservices.spm) },
    { title: 'A-Call', icon: tsACallActive, disabledIcon: tsACall, disabled: !Boolean(teleservices.a_call) },
    { title: 'B-Call', icon: tsBCallActive, disabledIcon: tsBCall, disabled: !Boolean(teleservices.b_call) },
    { title: 'TSR', icon: tsTsrActive, disabledIcon: tsTsr, disabled: !Boolean(teleservices.tsr) },
    { title: '2LSR', icon: ts2LSRActive, disabledIcon: ts2LSR, disabled: !Boolean(teleservices['2slr']) },
  ];

  return (
    <Bordered>
      <ContainerXS className="flex flex-row space-evenly">
        {iconsMemoized && iconsMemoized.map((q, index) => <TeleserviceItem {...q} key={index} />)}
      </ContainerXS>
    </Bordered>
  );
};

export default Teleservice;
