import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerXS } from 'src/components/Branding/Container';
import Bordered from 'src/components/Branding/Border';

import './Title.scss';

const PrograssCardProbabilitiesTitle = () => {
  const { t } = useTranslation();

  return (
    <Bordered position="bottom">
      <ContainerXS className="progress-card-title">
        <span className="text text-secondary text-uppercase text-left first">{t('Part')}</span>
        <span className="text text-secondary text-uppercase">{t('Last Purchase')}</span>
        <span className="text text-secondary text-uppercase">{t('Probability')}</span>
      </ContainerXS>
    </Bordered>
  );
};

export default PrograssCardProbabilitiesTitle;
