import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Button from 'src/components/Branding/Button';
import Bordered from 'src/components/Branding/Border';

import { ContainerFULL } from 'src/components/Branding/Container';
import Tag from 'src/components/Branding/Tag';
import { getTimePeriod } from 'src/shared/utils/date';

const FinishStep = ({
  selectedCampaign,
  campaignId,
  campaignTitle,
  campaignStartDate,
  endDate,
  followUpStartDate,
  dueDate,
  memoToDealers,
  memoToSalesTeam,
  current,
  total,
  deliverCampaign,
}: any) => {
  const { t } = useTranslation();

  return (
    <Bordered className="finishContain">
      <Bordered position="bottom" className="TSContainer">
        <ContainerFULL>
          <p className="text-uppercase titles">{t('Campaign Details')}</p>
          <Bordered className="itemContent campaign-details">
            <div className="contain text-secondary">
              <div className="keyValue">
                <p className="key">{t('Campaign ID')}:</p> <p className="text-main">{campaignId}</p>
              </div>
              <div className="keyValue">
                <p className="key">{t('Campaign Title')}:</p>
                <p className="text-main">{campaignTitle}</p>
              </div>
              <div className="keyValue">
                <p className="key">{t('Customers_low')}:</p>
                <p className="text-main">{t('Of Customers', { current, total })}</p>
              </div>
              <div className="keyValue">
                <p className="key">{t('Campaign Period (Nationwide)')}:</p>
                <p
                  className="text-main"
                  dangerouslySetInnerHTML={{
                    __html: getTimePeriod(moment(campaignStartDate).toDate()!, moment(endDate).toDate()!!),
                  }}
                />
              </div>
              <div className="keyValue">
                <p className="key">{t('Default Follow-up Period')}:</p>
                <p
                  className="text-main"
                  dangerouslySetInnerHTML={{
                    __html: getTimePeriod(moment(followUpStartDate).toDate()!, moment(dueDate).toDate()!!),
                  }}
                />
              </div>
              <div className="keyValue">
                <p className="key">{t('Memo to Dealers from Group Japan')}</p>
                <p
                  className="text-main"
                  dangerouslySetInnerHTML={{
                    __html: memoToDealers,
                  }}
                />
              </div>
              <div className="keyValue">
                <p className="key">{t('Sales Manager Memo to Sales Team')}</p>
                <p
                  className="text-main"
                  dangerouslySetInnerHTML={{
                    __html: memoToSalesTeam,
                  }}
                />
              </div>
            </div>
          </Bordered>
        </ContainerFULL>
        <ContainerFULL>
          <p className="text-uppercase titles">{t('Target Segments')}</p>
          <Bordered className="itemContent">
            <div className="contain text-secondary">
              {selectedCampaign &&
                selectedCampaign.target_segments_applied &&
                selectedCampaign.target_segments_applied.map((q: any, index: number) => (
                  <>
                    <div className="keyValue">
                      <p className="key">{q!.target_segment_section.target_segment_section_title}:</p>
                      <div className="flex">
                        {q.target_segment_section.target_segment_item.map((item: any, index: number) => (
                          <Tag>
                            <span className="text-main text-sm">{item && item.target_segment_item_title}</span>
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </Bordered>
        </ContainerFULL>
      </Bordered>
      <div className="footerContainer">
        <NavLink to="/campaigns">
          <Button className="Btn btn btn-blue text text-sm text-uppercase">{t('Go To Campaigns')}</Button>
        </NavLink>
        <NavLink to="/campaigns">
          <Button className="deliverBtn btn btn-primary text text-sm text-uppercase" onClick={deliverCampaign}>
            {t('Deliver')}
          </Button>
        </NavLink>
      </div>
    </Bordered>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishStep);
