import React from 'react';
import { Radio } from 'antd';
import { PropsBase } from 'src/components/types';

import './RadioButton.scss';

interface RadioButtonProps extends PropsBase {
  value: string;
  disabled?: boolean;
  onClick?: any;
}

const RadioButton = ({ children, className, value, disabled, onClick, ...rest }: RadioButtonProps) => (
  <Radio.Button className={className} value={value} disabled={disabled} onClick={() => onClick(value)} {...rest}>
    {children}
  </Radio.Button>
);

export default RadioButton;
