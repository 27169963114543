import React from 'react';
import { Input as DefaultInput } from 'antd';

import { PropsBase } from 'src/components/types';

export const { TextArea } = DefaultInput;

const Input = ({ children, ...rest }: PropsBase) => <DefaultInput {...rest}>{children}</DefaultInput>;

export default Input;
