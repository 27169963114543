export const SELECT_CONTRACT = 'SELECT_CONTRACT';
export const SET_STARS = 'CONTRACTS_SET_STARS';
export const TOGGLE_STAR = 'TOGGLE_STAR';
export const SELECTED_CONTRACT = 'bm_selectedContract';
export const SELECT_CONTRACTS_INDEX = 'SELECT_CONTRACTS_INDEX';

export interface ContractsState {
  selectedContract: string | null;
  isSelected: boolean;
  stars?: ContractStar[];
  selectedContractsIndex?: number | null;
}

export interface ContractStar {
  number: string;
  star: boolean;
}

interface SelectContractAction {
  type: typeof SELECT_CONTRACT;
  payload: string | null;
}

interface SelectContractsIndexAction {
  type: typeof SELECT_CONTRACTS_INDEX;
  payload: number | null;
}

interface SetStarsAction {
  type: typeof SET_STARS;
  payload: {
    stars: ContractStar[];
  };
}

interface ToggleStarAction {
  type: typeof TOGGLE_STAR;
  payload: {
    number: string;
  };
}

export type ContractsActionTypes =
  | SelectContractAction
  | SelectContractsIndexAction
  | SetStarsAction
  | ToggleStarAction;
