import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerSM } from 'src/components/Branding/Container';
import ProgressDetailed from 'src/components/Branding/Progress/Detailed';

import './Spent.scss';

export interface SpentStat {
  year: string;
  amount: number;
  average: number;
  max: number;
}

export interface ProgressCardSpentProps {
  stats: SpentStat[];
}

const ProgressCardSpent = ({ stats }: ProgressCardSpentProps) => {
  const { t } = useTranslation();

  return (
    <ContainerSM>
      {stats &&
        stats.map((stat) => (
          <div key={stat.year} className="progress-card-spent">
            <span className="text text-sm">{t('year', { year: stat.year })}</span>

            <ProgressDetailed amount={stat.amount} average={stat.average} max={stat.max} />
          </div>
        ))}
    </ContainerSM>
  );
};

export default ProgressCardSpent;
