import React, { useState, Fragment, useEffect } from 'react';

import { PropsBase } from 'src/components/types';
import RadioGroup, { RadioButton } from 'src/components/Branding/Radio';
import Padding from 'src/components/Branding/Spaceing/Padding';

import PartsModal from './PartsModal';
import VehicleDetails, { VehicleDetailsProps } from './VehicleDetails';
import BmwIcon from './Assets/bmw-icon.png';

import './Aftersales.scss';

interface AftersalesProps extends PropsBase {
  models: string[];
  stats: VehicleDetailsProps[];
}

const Aftersales = ({ models, stats }: AftersalesProps) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(0);
  }, [models]);

  return (
    <Fragment>
      <RadioGroup value={models[selected]} className="models">
        {models &&
          models.map((q, index) => (
            <RadioButton key={q} value={q} onClick={() => setSelected(index)}>
              <div className="rad-b flex flex-row vertical-center">
                <img src={BmwIcon} className="mr-xs" />
                <span className="h-fit">{q}</span>
              </div>
            </RadioButton>
          ))}
      </RadioGroup>

      {stats && (
        <Fragment>
          <Padding position="top" size="md">
            {models && (
              <VehicleDetails
                progressStats={stats.find((w) => w.model === models[selected])?.progressStats}
                teleservices={stats.find((w) => w.model === models[selected])?.teleservices || {}}
              />
            )}
          </Padding>

          {stats[selected] && <PartsModal stats={stats[selected].progressStats!} />}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Aftersales;
