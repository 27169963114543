import React from 'react';

import { ContainerMD } from 'src/components/Branding/Container';
import CampaignActivity from '../../SalesConsultantManager/Overview/CampaignActivity';
import CampaignDetails from '../../SalesConsultantManager/Overview/CampaignDetails';
import TargetSegments from '../../SalesConsultantManager/Overview/TargetSegments';
import Results from './Results';
import StatusTable from './Status';

import './Overview.scss';

const Overview = ({ data, selectedLang }: any) => {
  return (
    <ContainerMD className="sections-layout__sections content-overflow">
      <CampaignDetails data={data} />
      <TargetSegments data={data} selectedLang={selectedLang} />
      <StatusTable data={data} />
      <Results data={data} />
      <CampaignActivity />
    </ContainerMD>
  );
};

export default Overview;
