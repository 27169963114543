import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import RadioGroup, { RadioButton } from 'src/components/Branding/Radio';

import './PartsModalTitle.scss';

interface PartsModalTitleProps {
  titles: string[];
  selected: number;
  setSelected: Function;
}

const PartsModalTitle = ({ titles, selected, setSelected }: PartsModalTitleProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <span className="text text-uppercase text-xxs text-bold pos-abs">{t('PARTS')}</span>

      <RadioGroup value={titles[selected]} className="parts-modal-radio-group">
        {titles &&
          titles.map((q, index) => (
            <RadioButton key={q} value={q} onClick={() => setSelected(index)}>
              <span className="text-uppercase">{q}</span>
            </RadioButton>
          ))}
      </RadioGroup>
    </Fragment>
  );
};

export default PartsModalTitle;
