import React, { ReactNode, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Bordered from 'src/components/Branding/Border';
import { ContainerXS } from 'src/components/Branding/Container';
import { RootState } from 'src/store';

import './SinceLastPurchaseCard.scss';

interface SinceLastPurchaseCardProps {
  info: string;
  avgInfo: string;
  title: string;
  activeIcon: ReactNode;
  disabledIcon: ReactNode;
  countActive: number;
  selectedLanguage?: string;
}

const SinceLastPurchaseCard = ({
  info,
  avgInfo,
  title,
  activeIcon,
  disabledIcon,
  countActive,
  selectedLanguage,
}: SinceLastPurchaseCardProps) => {
  const { t } = useTranslation();
  const icons = [];

  for (let index = 0; index < countActive; index++) {
    icons.push(activeIcon);
  }

  for (let index = 0; index < 3 - countActive; index++) {
    icons.push(disabledIcon);
  }

  return (
    <Bordered rounded>
      <ContainerXS className="flex flex-column vertical-center center">
        <div className="flex flex-row center mb-12">{icons}</div>
        <p className="text-center mb-4">
          {selectedLanguage === 'jp' && (
            <Fragment>
              <span className="text text-sm">{title}</span>
              <span className="text text-sm text-bold">{info}</span>
            </Fragment>
          )}
          {selectedLanguage !== 'jp' && (
            <Fragment>
              <span className="text text-sm text-bold">{info}</span>
              <span className="text text-sm">{title}</span>
            </Fragment>
          )}
        </p>
        <span className="d-block text text-sm text-center text-secondary">{t('Average', { info: avgInfo })}</span>
      </ContainerXS>
    </Bordered>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLanguage: state.lang.selectedLanguage,
});

export default connect(mapStateToProps, null)(SinceLastPurchaseCard);
