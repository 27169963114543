import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Branding/Table';
import './Status.scss';

const Status = ({ data }: any) => {
  const { t } = useTranslation();

  const dataSource = useMemo(
    () => [
      {
        key: '1',
        custumersOnList: data.campaign_cnt_customers_on_list ?? 0,
        testDriveCompleted: data.campaign_cnt_test_drive_completed ?? 0,
        completed: data.campaign_cnt_completed ?? 0,
        calledNoAnswer: data.campaign_cnt_called_no_answer ?? 0,
        calledNotInterested: data.campaign_cnt_called_not_interested ?? 0,
        doTo: data.campaign_cnt_todo ?? 0,
      },
    ],
    [data],
  );

  const columns = [
    {
      title: t('Custumers on List'),
      dataIndex: 'custumersOnList',
      key: 'custumersOnList',
    },
    {
      title: t('Completed'),
      dataIndex: 'completed',
      key: 'completed',
    },
    {
      title: t('Called No Answer'),
      dataIndex: 'calledNoAnswer',
      key: 'calledNoAnswer',
    },
    {
      title: t('Called Not Interested'),
      dataIndex: 'calledNotInterested',
      key: 'calledNotInterested',
    },
    {
      title: t('To Do'),
      dataIndex: 'doTo',
      key: 'doTo',
    },
  ];

  return (
    <div className="statusContainer">
      <div className="flex space-between text-uppercase text-sm">
        <span className="text-secondary">{t('Status')}</span>
      </div>
      <Table hasPagination={false} columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default Status;
