import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router';
import { Switch } from 'react-router-dom';

import 'antd/dist/antd.css';

import Login from 'src/pages/Login';
import Layout from 'src/components/Layout';
import AuthRoute from 'src/components/AuthRoute';
import { RootState } from 'src/store';
import AnonymousRoute from 'src/components/AuthRoute/AnonymousRoute';
import { LOGIN } from 'src/store/auth/types';
import { getStoredAuthToken } from 'src/shared/utils/authToken';
import { CHANGE_LANGUAGE } from 'src/store/lang/types';
import history from 'src/shared/utils/browserHistory';
import { getStoredI18nToken } from 'src/shared/utils/i18n';

import './App.scss';

const LOGIN_URL = '/sign-in';
const AUTH_REDIRECT = '/';

interface AppProps {
  auth: boolean | null;
  isLangSelected: boolean;
  login: Function;
  changeLanguage: Function;
}

const App = ({ auth, isLangSelected, login, changeLanguage }: AppProps) => {
  useEffect(() => {
    const token = getStoredAuthToken();
    login(token);

    if (!isLangSelected) {
      const currentLang = getStoredI18nToken();
      changeLanguage(currentLang);
    }
  }, [isLangSelected]);

  return (
    <>
      {auth !== null && (
        <Router history={history}>
          <Switch>
            <AnonymousRoute auth={auth} fallback={AUTH_REDIRECT} path={LOGIN_URL}>
              <Login />
            </AnonymousRoute>
            <AuthRoute auth={auth} fallback={LOGIN_URL} path={AUTH_REDIRECT}>
              <Layout />
            </AuthRoute>
          </Switch>
        </Router>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth.authenticated,
  isLangSelected: state.lang.isSelected,
});

const mapDispatchToProps = (dispatch: Function) => ({
  login: (token: string) => dispatch({ type: LOGIN, payload: token }),
  changeLanguage: (lang: string) => dispatch({ type: CHANGE_LANGUAGE, payload: lang }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
