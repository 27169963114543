import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Padding from 'src/components/Branding/Spaceing/Padding';
import Table from 'src/components/Branding/Table';
import Loading from 'src/components/Branding/Loading';
import campaignService from 'src/shared/services/campaign.service';
import { getDate, getTimePeriod } from 'src/shared/utils/date';
import { formatNumber } from 'src/shared/utils/formatters';
import { getCampaignColumns } from 'src/components/Campaigns/config';
import { setCampaignData } from 'src/store/campaigns/actions';
import { useHistory } from 'react-router-dom';
import Header from './Header';

import './Campaigns.scss';

interface CampaignsProps {
  selectedLang: string | null;
  campaignData: any;
  subscribe?: Function;
  setCampaignData?: Function;
}

/**
 * Represents campaigns page for financial sales manager.
 */
const Campaigns = ({ selectedLang, subscribe, campaignData, setCampaignData }: CampaignsProps) => {
  const { t } = useTranslation();
  const [{ data }, fetchData] = campaignService.getCampaign();
  const history = useHistory();

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  useEffect(() => {
    if (data && !campaignData) {
      const newCampaigns = data.campaign.filter(
        (q: any) =>
          q.status && q.status.findIndex((w: any) => w.status_id === 'active' || w.status_id === 'delivered') !== -1,
      );
      setCampaignData!({ ...data, campaign: newCampaigns });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setCampaignData!(data);
    }
  }, [selectedLang]);

  const columns = getCampaignColumns(['campaignId', 'description', 'numberOfCustomers', 'campaignPeriod', 'status'], t);

  const dataSource = useMemo(() => {
    if (campaignData) {
      return (
        campaignData.campaign &&
        campaignData.campaign
          .filter(
            (q: any) =>
              q.status &&
              q.status.findIndex((w: any) => w.status_id === 'active' || w.status_id === 'delivered') !== -1,
          )
          .map((q: any) => ({
            campaignId: q.campaign_id,
            description: q.description,
            numberOfCustomers: formatNumber(q.number_of_customers),
            campaignPeriod: getTimePeriod(
              getDate(q.campaign_period_nationwide_start_date)!,
              getDate(q.campaign_period_nationwide_end_date)!,
            ),
            status: q.status,
          }))
      );
    }
  }, [campaignData, selectedLang]);

  return (
    <>
      <Header />
      <Padding size="md" className="flex">
        {dataSource && (
          <Table
            dataSource={dataSource}
            columns={columns}
            hasPagination
            onRow={(record: any) => ({
              onClick: () => {
                if (record.status.find((q: any) => q.status_id === 'delivered')) {
                  history.push(`/campaigns/update?id=${encodeURIComponent(record.campaignId)}`);
                } else if (record.status.find((q: any) => q.status_id === 'active')) {
                  history.push(`/campaigns/details?id=${encodeURIComponent(record.campaignId)}`);
                }
              },
              onMouseEnter: (event: any) => {
                event.currentTarget.style.cursor = 'pointer';
              },
            })}
          />
        )}
        {!dataSource && <Loading />}
      </Padding>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
  campaignData: state.campaign.data,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
  setCampaignData: (data: object) => dispatch(setCampaignData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
