import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';

import StepContainer from 'src/components/Campaigns/StepContainer';
import Bordered from 'src/components/Branding/Border';
import Button from 'src/components/Branding/Button';
import Table from 'src/components/Branding/Table';
import Select, { Option } from 'src/components/Branding/Select';
import Checkbox from 'src/components/Branding/Checkbox';
import Filters from 'src/shared/components/Campaigns/Filters';
import ArrowDown from 'src/assets/images/arrow-down-icon.svg';
import ArrowUp from 'src/assets/images/arrow-up-icon.svg';

import './RemoveTable.scss';

const ExpandedRow = ({ data, selectCustomers, selectedCustomers, allCustomers, selectedLang }: any) => {
  const [checkedRows, setCheckedRows] = useState<any>([]);

  useEffect(() => {
    const selectedKeys: any = [];
    selectedCustomers &&
      data &&
      selectedCustomers.forEach((q: any) => {
        const index = q && data.findIndex((w: any) => w.customerId === q.customerId);
        selectedKeys.push(index);
      });
    setCheckedRows(selectedKeys);
  }, [selectedCustomers]);

  const onSelectRow = (index: number, element: any) => {
    const i = checkedRows && checkedRows.indexOf(index);
    if (i == -1) {
      checkedRows.push(index);

      let newCustomer: any = {};
      allCustomers &&
        allCustomers.map((e: any, i: any) => {
          if (e.dm_customer_number == element.customerId) {
            newCustomer = { key: i, customerId: element.customerId };
          }
        });
      selectedCustomers && selectedCustomers.push(newCustomer);
    } else {
      checkedRows && checkedRows.splice(i, 1);

      const currentCustomerIndex =
        selectedCustomers && selectedCustomers.findIndex((q: any) => q.customerId === element.customerId);
      selectedCustomers && selectedCustomers.splice(currentCustomerIndex, 1);
    }
    selectCustomers!(selectedCustomers);
    setCheckedRows([...checkedRows]);
  };

  return (
    <div className="ExpandedRow">
      {data.map((element: any, index: number) => {
        return (
          <div className="flex" key={index}>
            <Checkbox
              key={index}
              className="checkBox"
              checked={checkedRows.indexOf(index) > -1}
              onChange={() => onSelectRow(index, element)}
            />
            <table>
              <tr className={checkedRows.indexOf(index) > -1 ? 'selectedRow' : 'row'}>
                <td className="customerId">{element.customerId}</td>
                {selectedLang === 'jp' && <td className={selectedLang === 'en' ? '' : 'jpCustId'}></td>}
                <td className="customerName">{element.customerName}</td>
                {selectedLang === 'jp' && <td className={selectedLang === 'en' ? '' : 'jpCustName'}></td>}
                <td className="dealer">{element.dealer}</td>
                {selectedLang === 'jp' && <td className={selectedLang === 'en' ? '' : 'jpCustDealer'}></td>}
                <td className="outlet">{element.outlet}</td>
              </tr>
            </table>
          </div>
        );
      })}
    </div>
  );
};

const RemoveTable = ({
  toBack,
  nextStep,
  hasBackButton,
  data,
  columns,
  rowSelection,
  selectCustomers,
  selectedCustomers,
  selectedLang,
  allCustomers,
}: any) => {
  const { t } = useTranslation();
  const [showAll, setshowAll] = useState(true);

  return (
    <StepContainer hasBackButton={hasBackButton} back={toBack} nextStep={nextStep} className="remove">
      <Bordered className="TitleContainer">
        <Filters />
        <div className="filters">
          <div className="btnsContainer">
            <Button disabled className={showAll ? 'activeClass' : 'inActiveClass'} onClick={() => setshowAll(true)}>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Show All')}</span>
            </Button>
            <Button disabled className={showAll ? 'inActiveClass' : 'activeClass'} onClick={() => setshowAll(false)}>
              <span className="text text-sm text-bold text-main text-uppercase">{t('Show Only Active')}</span>
            </Button>
          </div>
          <Select disabled placeholder={t('Actions')} bordered>
            <Option value="2">{t('Select All')}</Option>
            <Option value="3">{t('Select None')}</Option>
          </Select>
        </div>
      </Bordered>
      <Bordered className="tableContain">
        <Table
          columns={columns}
          expandable={{
            expandRowByClick: true,
            expandIcon: ({ expanded, onExpand, record }: any) =>
              expanded ? (
                <img src={ArrowUp} onClick={(e) => onExpand(record, e)} className="expandIcon" />
              ) : (
                <img src={ArrowDown} onClick={(e) => onExpand(record, e)} className="expandIcon" />
              ),
            expandedRowRender: (record: any) => (
              <ExpandedRow
                data={record.description}
                selectedLang={selectedLang}
                selectCustomers={selectCustomers}
                selectedCustomers={selectedCustomers}
                allCustomers={allCustomers}
              />
            ),
            rowExpandable: (record: any) => record.name !== 'Not Expandable',
          }}
          rowSelection={{ ...rowSelection }}
          dataSource={data}
          hasPagination
        />
      </Bordered>
    </StepContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveTable);
