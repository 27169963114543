import useApi from 'src/shared/hooks/api';

class AuthService {
  public me() {
    return useApi.get('/api/user/me/');
  }

  public updateMe() {
    return useApi.put('/api/user/me/');
  }

  public signIn() {
    return useApi.post('/api/user/token/');
  }
}

export default new AuthService();
