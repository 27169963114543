import React from 'react';
import { useTranslation } from 'react-i18next';

import Bordered from 'src/components/Branding/Border';
import { CampaignHeaderProps } from 'src/components/Campaigns/types';
import CampaignsContainer from 'src/shared/components/Campaigns/CampaignsContainer';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Status from 'src/components/Campaigns/Status';
import { NavLink } from 'react-router-dom';

import './CampaignHeader.scss';

const CampaignHeader = ({ title }: CampaignHeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Bordered position="bottom" className="m-0">
        <CampaignsContainer className="headerContainer">
          <div className="title">
            <NavLink to="/campaigns">
              <ArrowLeftOutlined className="arrowLeft flex align-center" />
            </NavLink>
            <p className="text-uppercase text-main text-bold text-xxs m-0">{title}</p>
            <Status id="delivered" title="delivered" />
          </div>
        </CampaignsContainer>
      </Bordered>
    </>
  );
};
export default CampaignHeader;
