import React, { useMemo, useRef, useState } from 'react';
import { Table as DefaultTable } from 'antd';
import { useTranslation } from 'react-i18next';

import { PropsBase } from 'src/components/types';
import Bordered from 'src/components/Branding/Border';
import Select, { Option } from 'src/components/Branding/Select';
import useOnOutsideClick from 'src/shared/hooks/onOutsideClick';

import './Table.scss';

interface TableProps extends PropsBase {
  dataSource?: any[];
  columns?: any[];
  expandable?: any;
  rowSelection?: any;
  hasPagination?: any;
}

const pageSizes = [10, 25, 50];

const Table = ({ dataSource, columns, expandable, rowSelection, hasPagination, ...rest }: PropsBase & TableProps) => {
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [page, setPage] = useState(1);
  const [selectOpen, setSelectOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<number>>([]);

  const $selectRef = useRef(null);
  const { t } = useTranslation();

  useOnOutsideClick($selectRef, selectOpen, () => {
    setSelectOpen(false);
  });

  const getCurrentTotalPages = () => {
    return {
      current: dataSource && page * pageSize <= dataSource?.length ? page * pageSize : dataSource?.length,
      total: dataSource?.length,
    };
  };

  const TableMemo = useMemo(() => {
    return (
      <>
        <DefaultTable
          rowClassName={(record: any, index: number) => {
            return expandedRowKeys.indexOf(index) !== -1 ? 'rowExpand' : '';
          }}
          pagination={hasPagination ? { pageSize: pageSize, onChange: (page) => setPage(page) } : false}
          dataSource={dataSource}
          columns={columns}
          expandable={{
            onExpandedRowsChange: (e: any) => {
              setExpandedRowKeys(e);
            },
            ...expandable,
          }}
          rowSelection={rowSelection}
          className={`table cols-${columns?.length! - 1}`}
          {...rest}
        />
      </>
    );
  }, [dataSource, page, pageSize, expandedRowKeys]);

  return (
    <Bordered className="TableContainer">
      {TableMemo}
      {hasPagination && (
        <div className="pagination">
          <span className="currentPage text text-main text-md">{t('Show on page')}</span>
          <div onClick={() => setSelectOpen(!selectOpen)} ref={$selectRef}>
            <Select
              open={selectOpen}
              onSelect={(e: any) => setPageSize(e)}
              placeholder={t(`${pageSize}`)}
              bordered
              className="showOnPageContainer"
              getPopupContainer={() => document.getElementById('filter-popup-container') || document.body}
            >
              {pageSizes.map((q, index) => (
                <Option key={index} value={q}>
                  {q}
                </Option>
              ))}
            </Select>
          </div>
          <span className="showingCount text text-main text-md">{t('Showing of', getCurrentTotalPages())}</span>
        </div>
      )}
    </Bordered>
  );
};

export default Table;
