import axios, { AxiosResponse, AxiosError } from 'axios';
import { HTTPMethod } from 'src/shared/types';
import history from './browserHistory';
import { objectToQueryString } from './url';
import { getStoredAuthToken, removeStoredAuthToken } from './authToken';
import { getStoredI18nToken } from './i18n';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const defaults = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001',
  headers: () => ({
    'Content-Type': 'application/json',
    Authorization: getStoredAuthToken() ? `Token ${getStoredAuthToken()}` : undefined,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: 'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const applyLocale = (params: any) => ({
  ...params,
  lang: getStoredI18nToken(),
});

/**
 * Web api client.
 */
const clientApi = (method: HTTPMethod, url: string, variables: any) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(),
      data: method !== 'get' && method !== 'delete' ? variables : undefined,
      paramsSerializer: objectToQueryString,
      params: method === 'get' ? applyLocale(variables) : applyLocale(undefined),
    }).then(
      (response: AxiosResponse) => {
        resolve(response.data);
      },
      (error: AxiosError) => {
        if (error.response) {
          if (error.response.status === 401) {
            removeStoredAuthToken();
            history.push('/sign-in');
          } else {
            reject(error.response.data);
          }
        } else {
          reject(defaults.error);
        }
      },
    );
  });

export default clientApi;
