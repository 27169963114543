export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export interface SetCredentialsPayload {
  role: string;
  name: string;
  secondName: string;
}

export interface AuthState {
  token: string | null;
  authenticated: boolean | null;
  name: string | null;
  secondName: string | null;
  role: string | null;
}

interface SetCredentialsAction {
  type: typeof SET_CREDENTIALS;
  payload: SetCredentialsPayload;
}

interface LoginAction {
  type: typeof LOGIN;
  payload: string;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

export type AuthActionTypes = LoginAction | LogoutAction | SetCredentialsAction;
