import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/store';
import { LanguageSubscription, SUBSCRIBE_CHANGE_LANGUAGE } from 'src/store/lang/types';
import Padding from 'src/components/Branding/Spaceing/Padding';
import Table from 'src/components/Branding/Table';
import Loading from 'src/components/Branding/Loading';
import campaignService from 'src/shared/services/campaign.service';
import { getDate, getTimePeriod } from 'src/shared/utils/date';
import { formatNumber } from 'src/shared/utils/formatters';
import { getCampaignColumns } from 'src/components/Campaigns/config';
import { setCampaignData } from 'src/store/campaigns/actions';
import Header from './Header';

import './Campaigns.scss';

interface CampaignsProps {
  selectedLang: string | null;
  campaignData: any;
  subscribe?: Function;
  setCampaignData?: Function;
}

/**
 * Represents campaigns page for financial marketing manager.
 */
const Campaigns = ({ selectedLang, subscribe, campaignData, setCampaignData }: CampaignsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedLng, setSelectLang] = useState(selectedLang);

  const [{ data }, fetchData] = campaignService.getCampaign();

  useEffect(() => {
    subscribe!({ onStart: fetchData } as LanguageSubscription);
  }, []);

  useEffect(() => {
    if (data && !campaignData) {
      setCampaignData!(data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedLng !== selectedLang) {
      setCampaignData!(data);
      setSelectLang(selectedLang);
    }
  }, [selectedLang]);

  const columns = getCampaignColumns(['campaignId', 'description', 'numberOfCustomers', 'campaignPeriod', 'status'], t);

  const dataSource = useMemo(() => {
    if (campaignData) {
      return (
        campaignData.campaign &&
        campaignData.campaign.map((q: any) => ({
          campaignId: q.campaign_id,
          description: q.description,
          numberOfCustomers: formatNumber(q.number_of_customers),
          campaignPeriod:
            q.campaign_period_nationwide_start_date &&
            q.campaign_period_nationwide_end_date &&
            getTimePeriod(
              getDate(q.campaign_period_nationwide_start_date)!,
              getDate(q.campaign_period_nationwide_end_date)!,
            ),
          status: q.status,
        }))
      );
    }
  }, [campaignData, selectedLang]);
  return (
    <>
      <Header />
      <Padding size="md" className="flex">
        {dataSource && (
          <Table
            dataSource={dataSource}
            columns={columns}
            hasPagination
            onRow={(record: any) => ({
              onClick: () => {
                if (record.status.find((q: any) => q.status_id === 'draft')) {
                  history.push(`/campaigns/edit?id=${encodeURIComponent(record.campaignId)}`);
                } else {
                  history.push(`/campaigns/details?id=${encodeURIComponent(record.campaignId)}`);
                }
              },
              onMouseEnter: (event: any) => {
                event.currentTarget.style.cursor = 'pointer';
              },
            })}
          />
        )}
        {!dataSource && <Loading />}
      </Padding>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLang: state.lang.selectedLanguage,
  campaignData: state.campaign.data,
});

const mapDispatchToProps = (dispatch: Function) => ({
  subscribe: (subscription: LanguageSubscription) =>
    dispatch({ type: SUBSCRIBE_CHANGE_LANGUAGE, payload: subscription }),
  setCampaignData: (data: object) => dispatch(setCampaignData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
